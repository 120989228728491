export default {
  common: {
    locales: 'fr-FR', // used by js : Intl.NumberFormat
    languageCodeUC: 'FR',
    languageLabel: 'Fran&ccedil;ais',
    timezone: {
      errorAssessing: 'Une erreur est survenue lors de l\'attribution de votre fuseau horaire'
    },
    supportedLanguages: {
      en_US: 'Anglais (US)',
      fr_FR: 'Français'
    },
    featureToggle: {
      notFound: 'Fonctionnalité {{feature}} indisponible',
      almostExpired: 'Fonctionnalité {{feature}} disponible encore {{days}} jours',
      lastDay: 'Dernier jour de disponibilité pour la fonctionnalité {{feature}}',
      expired: 'Fonctionnalité {{feature}} expirée'
    },
    notification: {
      invoiceFinalized: 'Facture {{number}} finalisée',
      creditNoteFinalized: 'Avoir {{number}} finalisée'
    },
    agGrid: {
      // Set Filter
      selectAll: '(Tout sélectionner)',
      selectAllSearchResults: '(Select All Search Results)',
      searchOoo: 'Search...',
      blanks: '(Blanks)',
      noMatches: 'No matches',

      // Number Filter & Text Filter
      filterOoo: 'Filtrer...',
      equals: 'Égal',
      notEqual: 'Différent',
      blank: 'Blank',
      notBlank: 'Not blank',
      empty: 'Choose One',

      // Number Filter
      lessThan: 'Plus petit que',
      greaterThan: 'Plus grand que',
      lessThanOrEqual: 'Plus petit ou égal',
      greaterThanOrEqual: 'Plus grand ou égal',
      inRange: 'Dans la plage',
      inRangeStart: 'from',
      inRangeEnd: 'to',

      // Text Filter
      contains: 'Contient',
      notContains: 'Ne contient pas',
      startsWith: 'Commence par',
      endsWith: 'Finit par',

      // Date Filter
      dateFormatOoo: 'dd-mm-yyyy',

      // Filter Conditions
      andCondition: 'ET',
      orCondition: 'OU',

      // Filter Buttons
      applyFilter: 'Apply',
      resetFilter: 'Reset',
      clearFilter: 'Clear',
      cancelFilter: 'Cancel',

      // Filter Titles
      textFilter: 'Text Filter',
      numberFilter: 'Number Filter',
      dateFilter: 'Date Filter',
      setFilter: 'Set Filter',

      // Side Bar
      columns: 'Colonnes',
      filters: 'Filtres',

      // columns tool panel
      pivotMode: 'Pivot Mode',
      groups: 'Row Groups',
      rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
      values: 'Values',
      valueColumnsEmptyMessage: 'Drag here to aggregate',
      pivots: 'Column Labels',
      pivotColumnsEmptyMessage: 'Drag here to set column labels',

      // Header of the Default Group Column
      group: 'Group',

      // Row Drag
      rowDragRow: 'rang',
      rowDragRows: 'rangs',

      // Other
      loadingOoo: 'Chargement...',
      loadingError: 'ERR',
      noRowsToShow: 'Aucune ligne à afficher',
      enabled: 'Enabled',

      // Menu
      pinColumn: 'Pin Column',
      pinLeft: 'Pin Left',
      pinRight: 'Pin Right',
      noPin: 'No Pin',
      valueAggregation: 'Value Aggregation',
      noAggregation: 'None',
      autosizeThiscolumn: 'Autosize This Column',
      autosizeAllColumns: 'Autosize All Columns',
      groupBy: 'Group by',
      ungroupBy: 'Un-Group by',
      addToValues: 'Add ${variable} to values',
      removeFromValues: 'Remove ${variable} from values',
      addToLabels: 'Add ${variable} to labels',
      removeFromLabels: 'Remove ${variable} from labels',
      resetColumns: 'Reset Columns',
      expandAll: 'Expand All',
      collapseAll: 'Close All',
      copy: 'Copy',
      ctrlC: 'Ctrl+C',
      copyWithHeaders: 'Copy With Headers',
      copyWithGroupHeaders: 'Copy with Group Headers',
      paste: 'Paste',
      ctrlV: 'Ctrl+V',
      export: 'Export',
      csvExport: 'CSV Export',
      excelExport: 'Excel Export',

      // Enterprise Menu Aggregation and Status Bar
      sum: 'Sum',
      first: 'First',
      last: 'Last',
      min: 'Min',
      max: 'Max',
      none: 'None',
      count: 'Count',
      avg: 'Average',
      filteredRows: 'Filtered',
      selectedRows: 'Selected',
      totalRows: 'Total Rows',
      totalAndFilteredRows: 'Rows',
      more: 'Plus',
      to: 'de',
      of: 'de',
      page: 'Page',
      nextPage: 'Page suivante',
      lastPage: 'Dernière page',
      firstPage: 'Première page',
      previousPage: 'Page précédente',

      // Pivoting
      pivotColumnGroupTotals: 'Total',

      // Enterprise Menu (Charts)
      pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
      pivotChart: 'Pivot Chart',
      chartRange: 'Chart Range',

      columnChart: 'Column',
      groupedColumn: 'Grouped',
      stackedColumn: 'Stacked',
      normalizedColumn: '100% Stacked',

      barChart: 'Bar',
      groupedBar: 'Grouped',
      stackedBar: 'Stacked',
      normalizedBar: '100% Stacked',

      pieChart: 'Pie',
      pie: 'Pie',
      doughnut: 'Doughnut',

      line: 'Line',

      xyChart: 'X Y (Scatter)',
      scatter: 'Scatter',
      bubble: 'Bubble',

      areaChart: 'Area',
      area: 'Area',
      stackedArea: 'Stacked',
      normalizedArea: '100% Stacked',

      histogramChart: 'Histogram',
      histogramFrequency: 'Frequency',

      combinationChart: 'Combination',
      columnLineCombo: 'Column & Line',
      AreaColumnCombo: 'Area & Column',

      // Charts
      pivotChartTitle: 'Pivot Chart',
      rangeChartTitle: 'Range Chart',
      settings: 'Settings',
      data: 'Data',
      format: 'Format',
      categories: 'Categories',
      defaultCategory: '(None)',
      series: 'Series',
      xyValues: 'X Y Values',
      paired: 'Paired Mode',
      axis: 'Axis',
      navigator: 'Navigator',
      color: 'Color',
      thickness: 'Thickness',
      xType: 'X Type',
      automatic: 'Automatic',
      category: 'Category',
      number: 'Number',
      time: 'Time',
      autoRotate: 'Auto Rotate',
      xRotation: 'X Rotation',
      yRotation: 'Y Rotation',
      ticks: 'Ticks',
      width: 'Width',
      height: 'Height',
      length: 'Length',
      padding: 'Padding',
      spacing: 'Spacing',
      chart: 'Chart',
      title: 'Title',
      titlePlaceholder: 'Chart title - double click to edit',
      background: 'Background',
      font: 'Font',
      top: 'Top',
      right: 'Right',
      bottom: 'Bottom',
      left: 'Left',
      labels: 'Labels',
      size: 'Size',
      minSize: 'Minimum Size',
      maxSize: 'Maximum Size',
      legend: 'Legend',
      position: 'Position',
      markerSize: 'Marker Size',
      markerStroke: 'Marker Stroke',
      markerPadding: 'Marker Padding',
      itemSpacing: 'Item Spacing',
      itemPaddingX: 'Item Padding X',
      itemPaddingY: 'Item Padding Y',
      layoutHorizontalSpacing: 'Horizontal Spacing',
      layoutVerticalSpacing: 'Vertical Spacing',
      strokeWidth: 'Stroke Width',
      lineDash: 'Line Dash',
      offset: 'Offset',
      offsets: 'Offsets',
      tooltips: 'Tooltips',
      callout: 'Callout',
      markers: 'Markers',
      shadow: 'Shadow',
      blur: 'Blur',
      xOffset: 'X Offset',
      yOffset: 'Y Offset',
      lineWidth: 'Line Width',
      normal: 'Normal',
      bold: 'Bold',
      italic: 'Italic',
      boldItalic: 'Bold Italic',
      predefined: 'Predefined',
      fillOpacity: 'Fill Opacity',
      strokeOpacity: 'Line Opacity',
      histogramBinCount: 'Bin count',
      columnGroup: 'Column',
      barGroup: 'Bar',
      pieGroup: 'Pie',
      lineGroup: 'Line',
      scatterGroup: 'X Y (Scatter)',
      areaGroup: 'Area',
      histogramGroup: 'Histogram',
      combinationGroup: 'Combination',
      groupedColumnTooltip: 'Grouped',
      stackedColumnTooltip: 'Stacked',
      normalizedColumnTooltip: '100% Stacked',
      groupedBarTooltip: 'Grouped',
      stackedBarTooltip: 'Stacked',
      normalizedBarTooltip: '100% Stacked',
      pieTooltip: 'Pie',
      doughnutTooltip: 'Doughnut',
      lineTooltip: 'Line',
      groupedAreaTooltip: 'Area',
      stackedAreaTooltip: 'Stacked',
      normalizedAreaTooltip: '100% Stacked',
      scatterTooltip: 'Scatter',
      bubbleTooltip: 'Bubble',
      histogramTooltip: 'Histogram',
      columnLineComboTooltip: 'Column & Line',
      areaColumnComboTooltip: 'Area & Column',
      customComboTooltip: 'Custom Combination',
      noDataToChart: 'No data available to be charted.',
      pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
      chartSettingsToolbarTooltip: 'Menu',
      chartLinkToolbarTooltip: 'Linked to Grid',
      chartUnlinkToolbarTooltip: 'Unlinked from Grid',
      chartDownloadToolbarTooltip: 'Download Chart',
      seriesChartType: 'Series Chart Type',
      seriesType: 'Series Type',
      secondaryAxis: 'Secondary Axis',

      // ARIA
      ariaChecked: 'checked',
      ariaColumn: 'Column',
      ariaColumnGroup: 'Column Group',
      ariaColumnList: 'Column List',
      ariaColumnSelectAll: 'Toggle Select All Columns',
      ariaDateFilterInput: 'Date Filter Input',
      ariaDefaultListName: 'List',
      ariaFilterColumnsInput: 'Filter Columns Input',
      ariaFilterFromValue: 'Filter from value',
      ariaFilterInput: 'Filter Input',
      ariaFilterList: 'Filter List',
      ariaFilterToValue: 'Filter to value',
      ariaFilterValue: 'Filter Value',
      ariaFilteringOperator: 'Filtering Operator',
      ariaHidden: 'hidden',
      ariaIndeterminate: 'indeterminate',
      ariaInputEditor: 'Input Editor',
      ariaMenuColumn: 'Press CTRL ENTER to open column menu.',
      ariaRowDeselect: 'Press SPACE to deselect this row',
      ariaRowSelectAll: 'Press Space to toggle all rows selection',
      ariaRowToggleSelection: 'Press Space to toggle row selection',
      ariaRowSelect: 'Press SPACE to select this row',
      ariaSearch: 'Search',
      ariaSortableColumn: 'Press ENTER to sort',
      ariaToggleVisibility: 'Press SPACE to toggle visibility',
      ariaUnchecked: 'unchecked',
      ariaVisible: 'visible',
      ariaSearchFilterValues: 'Search filter values',

      // ARIA Labels for Drop Zones

      ariaRowGroupDropZonePanelLabel: 'Row Groups',
      ariaValuesDropZonePanelLabel: 'Values',
      ariaPivotDropZonePanelLabel: 'Column Labels',
      ariaDropZoneColumnComponentDescription: 'Press DELETE to remove',
      ariaDropZoneColumnValueItemDescription:
        'Press ENTER to change the aggregation type',
      ariaDropZoneColumnGroupItemDescription: 'Press ENTER to sort',
      // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeperator}{column name}
      ariaDropZoneColumnComponentAggFuncSeperator: ' of ',
      ariaDropZoneColumnComponentSortAscending: 'ascending',
      ariaDropZoneColumnComponentSortDescending: 'descending',

      // ARIA Labels for Dialogs
      ariaLabelColumnMenu: 'Column Menu',
      ariaLabelCellEditor: 'Cell Editor',
      ariaLabelDialog: 'Dialog',
      ariaLabelSelectField: 'Select Field',
      ariaLabelTooltip: 'Tooltip',
      ariaLabelContextMenu: 'Context Menu',
      ariaLabelSubMenu: 'SubMenu',
      ariaLabelAggregationFunction: 'Aggregation Function',

      // Number Format (Status Bar, Pagination Panel)
      thousandSeparator: ',',
      decimalSeparator: '.'
    },
    timezonesList: {
      europeParis: 'Europe/Paris',
      europeBerlin: 'Europe/Berlin',
      europeLondon: 'Europe/London',
      europeBudapest: 'Europe/Budapest',
      europeWarsaw: 'Europe/Warsaw',
      utc: 'UTC',
      etcGMT12: 'Etc/GMT+12',
      etcGMT11: 'Etc/GMT+11',
      pacificSamoa: 'Pacific/Samoa',
      pacificHonolulu: 'Pacific/Honolulu',
      pacificMarquesas: 'Pacific/Marquesas',
      usAlaska: 'US/Alaska',
      americaTijuana: 'America/Tijuana',
      americaLosAngeles: 'America/Los_Angeles',
      americaChihuahua: 'America/Chihuahua',
      americaPhoenix: 'America/Phoenix',
      americaDenver: 'America/Denver',
      americaChicago: 'America/Chicago',
      americaGuatemala: 'America/Guatemala',
      americaMexicoCity: 'America/Mexico_City',
      americaRegina: 'America/Regina',
      americaBogota: 'America/Bogota',
      americaNew_York: 'America/New_York',
      americaIndianapolis: 'America/Indianapolis',
      americaCaracas: 'America/Caracas',
      americaHalifax: 'America/Halifax',
      americaAsuncion: 'America/Asuncion',
      americaCuiaba: 'America/Cuiaba',
      americaSantiago: 'America/Santiago',
      americaLa_Paz: 'America/La_Paz',
      americaSt_Johns: 'America/St_Johns',
      americaBuenos_Aires: 'America/Buenos_Aires',
      americaSao_Paulo: 'America/Sao_Paulo',
      americaCayenne: 'America/Cayenne',
      americaMontevideo: 'America/Montevideo',
      americaGodthab: 'America/Godthab',
      etcGMT2: 'Etc/GMT+2',
      atlanticAzores: 'Atlantic/Azores',
      atlanticCapeVerde: 'Atlantic/Cape_Verde',
      africaCasablanca: 'Africa/Casablanca',
      atlanticReykjavik: 'Atlantic/Reykjavik',
      africaLagos: 'Africa/Lagos',
      africaJohannesburg: 'Africa/Johannesburg',
      asiaDamascus: 'Asia/Damascus',
      europeKiev: 'Europe/Kiev',
      africaWindhoek: 'Africa/Windhoek',
      europeMinsk: 'Europe/Minsk',
      europeIstanbul: 'Europe/Istanbul',
      asiaAmman: 'Asia/Amman',
      asiaBeirut: 'Asia/Beirut',
      asiaJerusalem: 'Asia/Jerusalem',
      asiaCairo: 'Asia/Cairo',
      africaCairo: 'Africa/Cairo',
      asiaRiyadh: 'Asia/Riyadh',
      europeMoscow: 'Europe/Moscow',
      asiaBaghdad: 'Asia/Baghdad',
      africaNairobi: 'Africa/Nairobi',
      asiaTehran: 'Asia/Tehran',
      indianMauritius: 'Indian/Mauritius',
      asiaTbilisi: 'Asia/Tbilisi',
      asiaBaku: 'Asia/Baku',
      asiaYerevan: 'Asia/Yerevan',
      asiaDubai: 'Asia/Dubai',
      asiaKabul: 'Asia/Kabul',
      asiaYekaterinburg: 'Asia/Yekaterinburg',
      asiaKarachi: 'Asia/Karachi',
      asiaTashkent: 'Asia/Tashkent',
      asiaCalcutta: 'Asia/Calcutta',
      asiaColombo: 'Asia/Colombo',
      asiaKathmandu: 'Asia/Kathmandu',
      asiaDhaka: 'Asia/Dhaka',
      asiaAlmaty: 'Asia/Almaty',
      asiaNovosibirsk: 'Asia/Novosibirsk',
      asiaRangoon: 'Asia/Rangoon',
      asiaKrasnoyarsk: 'Asia/Krasnoyarsk',
      asiaBangkok: 'Asia/Bangkok',
      asiaUlaanbaatar: 'Asia/Ulaanbaatar',
      australiaPerth: 'Australia/Perth',
      asiaTaipei: 'Asia/Taipei',
      asiaSingapore: 'Asie/Singapour',
      asiaShanghai: 'Asie/Shanghai',
      asiaIrkutsk: 'Asia/Irkutsk',
      asiaPyongyang: 'Asia/Pyongyang',
      australiaEucla: 'Australia/Eucla',
      asiaSeoul: 'Asia/Seoul',
      asiaTokyo: 'Asia/Tokyo',
      asiaYakutsk: 'Asia/Yakutsk',
      australiaDarwin: 'Australia/Darwin',
      australiaAdelaide: 'Australia/Adelaide',
      australiaHobart: 'Australia/Hobart',
      asiaVladivostok: 'Asia/Vladivostok',
      pacificPort_Moresby: 'Pacific/Port_Moresby',
      australiaBrisbane: 'Australia/Brisbane',
      australiaSydney: 'Australia/Sydney',
      australiaLHI: 'Australia/LHI',
      asiaMagadan: 'Asia/Magadan',
      pacificFiji: 'Pacific/Fiji',
      asiaKamchatka: 'Asia/Kamchatka',
      pacificAuckland: 'Pacific/Auckland',
      etcGMTMinus12: 'Etc/GMT-12',
      pacificChatham: 'Pacific/Chatham',
      pacificEnderbury: 'Pacific/Enderbury',
      pacificKiritimati: 'Pacific/Kiritimati',
      indianReunion: 'Indian/Reunion',
      indianMayotte: 'Indian/Mayotte',
      americaGuadeloupe: 'America/Guadeloupe',
      americaMartinique: 'America/Martinique',
      pacificGambier: 'Pacific/Gambier',
      pacificMidway: 'Pacific/Midway',
      pacificWallis: 'Pacific/Wallis',
      pacificTahiti: 'Pacific/Tahiti',
      pacificNoumea: 'Pacific/Noumea',
      antarticaTroll: 'Antarctica/Troll'
    },
    loading: 'Chargement...',
    invoice: 'Facture',
    workspace: 'Espace de Travail',
    bankAccount: 'Compte Bancaire',
    countriesList: {
      fr: '<span class="mr-4 iti__flag iti__fr"></span> <span>France</span>',
      be: '<span class="mr-4 iti__flag iti__be"></span> <span>Belgique</span>',
      de: '<span class="mr-4 iti__flag iti__de"></span> <span>Allemagne</span>',
      es: '<span class="mr-4 iti__flag iti__es"></span> <span>Espagne</span>',
      ch: '<span class="mr-4 iti__flag iti__ch"></span> <span>Suisse</span>',
      it: '<span class="mr-4 iti__flag iti__it"></span> <span>Italie</span>',
      at: '<span class="mr-4 iti__flag iti__at"></span> <span>Autriche</span>',
      ie: '<span class="mr-4 iti__flag iti__ie"></span> <span>Irlande</span>',
      gb: '<span class="mr-4 iti__flag iti__gb"></span> <span>Royaume Uni</span>',
      af: '<span class="mr-4 iti__flag iti__af"></span> <span>Afghanistan</span>',
      za: '<span class="mr-4 iti__flag iti__za"></span> <span>Afrique du Sud</span>',
      al: '<span class="mr-4 iti__flag iti__al"></span> <span>Albanie</span>',
      dz: '<span class="mr-4 iti__flag iti__dz"></span> <span>Algérie</span>',
      ad: '<span class="mr-4 iti__flag iti__ad"></span> <span>Andorre</span>',
      ao: '<span class="mr-4 iti__flag iti__ao"></span> <span>Angola</span>',
      ai: '<span class="mr-4 iti__flag iti__ai"></span> <span>Anguilla</span>',
      aq: '<span class="mr-4 iti__flag iti__aq"></span> <span>Antarctique</span>',
      ag: '<span class="mr-4 iti__flag iti__ag"></span> <span>Antigua-et-Barbuda</span>',
      sa: '<span class="mr-4 iti__flag iti__sa"></span> <span>Arabie Saoudite</span>',
      ar: '<span class="mr-4 iti__flag iti__ar"></span> <span>Argentine</span>',
      am: '<span class="mr-4 iti__flag iti__am"></span> <span>Arménie</span>',
      aw: '<span class="mr-4 iti__flag iti__aw"></span> <span>Aruba</span>',
      au: '<span class="mr-4 iti__flag iti__au"></span> <span>Australie</span>',
      az: '<span class="mr-4 iti__flag iti__az"></span> <span>Azerbaïdjan</span>',
      bs: '<span class="mr-4 iti__flag iti__bs"></span> <span>Bahamas</span>',
      bh: '<span class="mr-4 iti__flag iti__bh"></span> <span>Bahreïn</span>',
      bd: '<span class="mr-4 iti__flag iti__bd"></span> <span>Bangladesh</span>',
      bb: '<span class="mr-4 iti__flag iti__bb"></span> <span>Barbade</span>',
      bz: '<span class="mr-4 iti__flag iti__bz"></span> <span>Belize</span>',
      bj: '<span class="mr-4 iti__flag iti__bj"></span> <span>Bénin</span>',
      bm: '<span class="mr-4 iti__flag iti__bm"></span> <span>Bermudes</span>',
      bt: '<span class="mr-4 iti__flag iti__bt"></span> <span>Bhoutan</span>',
      bo: '<span class="mr-4 iti__flag iti__bo"></span> <span>Bolivie</span>',
      ba: '<span class="mr-4 iti__flag iti__ba"></span> <span>Bosnie-Herzégovine</span>',
      bw: '<span class="mr-4 iti__flag iti__bw"></span> <span>Botswana</span>',
      br: '<span class="mr-4 iti__flag iti__br"></span> <span>Brésil</span>',
      bn: '<span class="mr-4 iti__flag iti__bn"></span> <span>Brunei Darussalam</span>',
      bg: '<span class="mr-4 iti__flag iti__bg"></span> <span>Bulgarie</span>',
      bf: '<span class="mr-4 iti__flag iti__bf"></span> <span>Burkina Faso</span>',
      bi: '<span class="mr-4 iti__flag iti__bi"></span> <span>Burundi</span>',
      by: '<span class="mr-4 iti__flag iti__by"></span> <span>Bélarus</span>',
      kh: '<span class="mr-4 iti__flag iti__kh"></span> <span>Cambodge</span>',
      cm: '<span class="mr-4 iti__flag iti__cm"></span> <span>Cameroun</span>',
      ca: '<span class="mr-4 iti__flag iti__ca"></span> <span>Canada</span>',
      cv: '<span class="mr-4 iti__flag iti__cv"></span> <span>Cabo Verde</span>',
      cl: '<span class="mr-4 iti__flag iti__cl"></span> <span>Chili</span>',
      cn: '<span class="mr-4 iti__flag iti__cn"></span> <span>Chine</span>',
      cy: '<span class="mr-4 iti__flag iti__cy"></span> <span>Chypre</span>',
      co: '<span class="mr-4 iti__flag iti__co"></span> <span>Colombie</span>',
      km: '<span class="mr-4 iti__flag iti__km"></span> <span>Comores</span>',
      cg: '<span class="mr-4 iti__flag iti__cg"></span> <span>Congo - Brazzaville</span>',
      kp: '<span class="mr-4 iti__flag iti__kp"></span> <span>Corée du Nord</span>',
      kr: '<span class="mr-4 iti__flag iti__kr"></span> <span>Corée du Sud</span>',
      cr: '<span class="mr-4 iti__flag iti__cr"></span> <span>Costa Rica</span>',
      hr: '<span class="mr-4 iti__flag iti__hr"></span> <span>Croatie</span>',
      cu: '<span class="mr-4 iti__flag iti__cu"></span> <span>Cuba</span>',
      cw: '<span class="mr-4 iti__flag iti__cw"></span> <span>Curaçao</span>',
      ci: '<span class="mr-4 iti__flag iti__ci"></span> <span>Côte d\'Ivoire</span>',
      dk: '<span class="mr-4 iti__flag iti__dk"></span> <span>Danemark</span>',
      dj: '<span class="mr-4 iti__flag iti__dj"></span> <span>Djibouti</span>',
      dm: '<span class="mr-4 iti__flag iti__dm"></span> <span>Dominique</span>',
      ae: '<span class="mr-4 iti__flag iti__ae"></span> <span>Émirats Arabes Unis</span>',
      ee: '<span class="mr-4 iti__flag iti__ee"></span> <span>Estonie</span>',
      fj: '<span class="mr-4 iti__flag iti__fj"></span> <span>Fidji</span>',
      fi: '<span class="mr-4 iti__flag iti__fi"></span> <span>Finlande</span>',
      ga: '<span class="mr-4 iti__flag iti__ga"></span> <span>Gabon</span>',
      gm: '<span class="mr-4 iti__flag iti__gm"></span> <span>Gambie</span>',
      ge: '<span class="mr-4 iti__flag iti__ge"></span> <span>Géorgie</span>',
      gh: '<span class="mr-4 iti__flag iti__gh"></span> <span>Ghana</span>',
      gi: '<span class="mr-4 iti__flag iti__gi"></span> <span>Gibraltar</span>',
      gd: '<span class="mr-4 iti__flag iti__gd"></span> <span>Grenade</span>',
      gl: '<span class="mr-4 iti__flag iti__gl"></span> <span>Groenland</span>',
      gr: '<span class="mr-4 iti__flag iti__gr"></span> <span>Grèce</span>',
      gp: '<span class="mr-4 iti__flag iti__gp"></span> <span>Guadeloupe</span>',
      gu: '<span class="mr-4 iti__flag iti__gu"></span> <span>Guam</span>',
      gt: '<span class="mr-4 iti__flag iti__gt"></span> <span>Guatemala</span>',
      gg: '<span class="mr-4 iti__flag iti__gg"></span> <span>Guernesey</span>',
      gn: '<span class="mr-4 iti__flag iti__gn"></span> <span>Guinée</span>',
      gq: '<span class="mr-4 iti__flag iti__gq"></span> <span>Guinée Équatoriale</span>',
      gw: '<span class="mr-4 iti__flag iti__gw"></span> <span>Guinée-Bissau</span>',
      gy: '<span class="mr-4 iti__flag iti__gy"></span> <span>Guyane</span>',
      gf: '<span class="mr-4 iti__flag iti__gf"></span> <span>Guyane Française</span>',
      gs: '<span class="mr-4 iti__flag iti__gs"></span> <span>Géorgie du Sud-et-les Îles Sandwich du Sud</span>',
      ht: '<span class="mr-4 iti__flag iti__ht"></span> <span>Haïti</span>',
      hn: '<span class="mr-4 iti__flag iti__hn"></span> <span>Honduras</span>',
      hk: '<span class="mr-4 iti__flag iti__hk"></span> <span>Hong Kong</span>',
      hu: '<span class="mr-4 iti__flag iti__hu"></span> <span>Hongrie</span>',
      in: '<span class="mr-4 iti__flag iti__in"></span> <span>Inde</span>',
      id: '<span class="mr-4 iti__flag iti__id"></span> <span>Indonésie</span>',
      iq: '<span class="mr-4 iti__flag iti__iq"></span> <span>Irak</span>',
      ir: '<span class="mr-4 iti__flag iti__ir"></span> <span>Iran</span>',
      is: '<span class="mr-4 iti__flag iti__is"></span> <span>Islande</span>',
      il: '<span class="mr-4 iti__flag iti__il"></span> <span>Israël</span>',
      jm: '<span class="mr-4 iti__flag iti__jm"></span> <span>Jamaïque</span>',
      jp: '<span class="mr-4 iti__flag iti__jp"></span> <span>Japon</span>',
      je: '<span class="mr-4 iti__flag iti__je"></span> <span>Jersey</span>',
      jo: '<span class="mr-4 iti__flag iti__jo"></span> <span>Jordanie</span>',
      kz: '<span class="mr-4 iti__flag iti__kz"></span> <span>Kazakhstan</span>',
      ke: '<span class="mr-4 iti__flag iti__ke"></span> <span>Kenya</span>',
      kg: '<span class="mr-4 iti__flag iti__kg"></span> <span>Kirghizistan</span>',
      ki: '<span class="mr-4 iti__flag iti__ki"></span> <span>Kiribati</span>',
      xk: '<span class="mr-4 iti__flag iti__xk"></span> <span>Kosovo</span>',
      kw: '<span class="mr-4 iti__flag iti__kw"></span> <span>Koweït</span>',
      la: '<span class="mr-4 iti__flag iti__la"></span> <span>Laos</span>',
      fk: '<span class="mr-4 iti__flag iti__fk"></span> <span>Les îles Falkland</span>',
      fo: '<span class="mr-4 iti__flag iti__fo"></span> <span>Les îles Féroé</span>',
      ls: '<span class="mr-4 iti__flag iti__ls"></span> <span>Lesotho</span>',
      lv: '<span class="mr-4 iti__flag iti__lv"></span> <span>Lettonie</span>',
      lb: '<span class="mr-4 iti__flag iti__lb"></span> <span>Liban</span>',
      ly: '<span class="mr-4 iti__flag iti__ly"></span> <span>Libye</span>',
      lr: '<span class="mr-4 iti__flag iti__lr"></span> <span>Liberia</span>',
      li: '<span class="mr-4 iti__flag iti__li"></span> <span>Liechtenstein</span>',
      lt: '<span class="mr-4 iti__flag iti__lt"></span> <span>Lituanie</span>',
      lu: '<span class="mr-4 iti__flag iti__lu"></span> <span>Luxembourg</span>',
      mo: '<span class="mr-4 iti__flag iti__mo"></span> <span>Macao</span>',
      mk: '<span class="mr-4 iti__flag iti__mk"></span> <span>Macédoine</span>',
      mg: '<span class="mr-4 iti__flag iti__mg"></span> <span>Madagascar</span>',
      my: '<span class="mr-4 iti__flag iti__my"></span> <span>Malaisie</span>',
      mw: '<span class="mr-4 iti__flag iti__mw"></span> <span>Malawi</span>',
      mv: '<span class="mr-4 iti__flag iti__mv"></span> <span>Maldives</span>',
      ml: '<span class="mr-4 iti__flag iti__ml"></span> <span>Mali</span>',
      mt: '<span class="mr-4 iti__flag iti__mt"></span> <span>Malte</span>',
      ma: '<span class="mr-4 iti__flag iti__ma"></span> <span>Maroc</span>',
      mq: '<span class="mr-4 iti__flag iti__mq"></span> <span>Martinique</span>',
      mu: '<span class="mr-4 iti__flag iti__mu"></span> <span>Maurice</span>',
      mr: '<span class="mr-4 iti__flag iti__mr"></span> <span>Mauritanie</span>',
      yt: '<span class="mr-4 iti__flag iti__yt"></span> <span>Mayotte</span>',
      mx: '<span class="mr-4 iti__flag iti__mx"></span> <span>Mexique</span>',
      fm: '<span class="mr-4 iti__flag iti__fm"></span> <span>Micronésie</span>',
      md: '<span class="mr-4 iti__flag iti__md"></span> <span>Moldavie</span>',
      mc: '<span class="mr-4 iti__flag iti__mc"></span> <span>Monaco</span>',
      mn: '<span class="mr-4 iti__flag iti__mn"></span> <span>Mongolie</span>',
      me: '<span class="mr-4 iti__flag iti__me"></span> <span>Monténégro</span>',
      ms: '<span class="mr-4 iti__flag iti__ms"></span> <span>Montserrat</span>',
      mz: '<span class="mr-4 iti__flag iti__mz"></span> <span>Mozambique</span>',
      mm: '<span class="mr-4 iti__flag iti__mm"></span> <span>Myanmar (Birmanie)</span>',
      na: '<span class="mr-4 iti__flag iti__na"></span> <span>Namibie</span>',
      nr: '<span class="mr-4 iti__flag iti__nr"></span> <span>Nauru</span>',
      ni: '<span class="mr-4 iti__flag iti__ni"></span> <span>Nicaragua</span>',
      ne: '<span class="mr-4 iti__flag iti__ne"></span> <span>Niger</span>',
      ng: '<span class="mr-4 iti__flag iti__ng"></span> <span>Nigéria</span>',
      nu: '<span class="mr-4 iti__flag iti__nu"></span> <span>Niue</span>',
      no: '<span class="mr-4 iti__flag iti__no"></span> <span>Norvège</span>',
      nc: '<span class="mr-4 iti__flag iti__nc"></span> <span>Nouvelle-Calédonie</span>',
      nz: '<span class="mr-4 iti__flag iti__nz"></span> <span>Nouvelle-Zélande</span>',
      np: '<span class="mr-4 iti__flag iti__np"></span> <span>Népal</span>',
      om: '<span class="mr-4 iti__flag iti__om"></span> <span>Oman</span>',
      ug: '<span class="mr-4 iti__flag iti__ug"></span> <span>Ouganda</span>',
      uz: '<span class="mr-4 iti__flag iti__uz"></span> <span>Ouzbékistan</span>',
      pk: '<span class="mr-4 iti__flag iti__pk"></span> <span>Pakistan</span>',
      pw: '<span class="mr-4 iti__flag iti__pw"></span> <span>Palaos</span>',
      pa: '<span class="mr-4 iti__flag iti__pa"></span> <span>Panama</span>',
      pg: '<span class="mr-4 iti__flag iti__pg"></span> <span>Papouasie-Nouvelle-Guinée</span>',
      py: '<span class="mr-4 iti__flag iti__py"></span> <span>Paraguay</span>',
      nl: '<span class="mr-4 iti__flag iti__nl"></span> <span>Pays-Bas</span>',
      bq: '<span class="mr-4 iti__flag iti__bq"></span> <span>Pays-Bas caribéens</span>',
      pe: '<span class="mr-4 iti__flag iti__pe"></span> <span>Pérou</span>',
      ph: '<span class="mr-4 iti__flag iti__ph"></span> <span>Philippines</span>',
      pl: '<span class="mr-4 iti__flag iti__pl"></span> <span>Pologne</span>',
      pf: '<span class="mr-4 iti__flag iti__pf"></span> <span>Polynésie française</span>',
      pr: '<span class="mr-4 iti__flag iti__pr"></span> <span>Porto Rico</span>',
      pt: '<span class="mr-4 iti__flag iti__pt"></span> <span>Portugal</span>',
      qa: '<span class="mr-4 iti__flag iti__qa"></span> <span>Qatar</span>',
      ro: '<span class="mr-4 iti__flag iti__ro"></span> <span>Roumanie</span>',
      ru: '<span class="mr-4 iti__flag iti__ru"></span> <span>Russie</span>',
      rw: '<span class="mr-4 iti__flag iti__rw"></span> <span>Rwanda</span>',
      cf: '<span class="mr-4 iti__flag iti__cf"></span> <span>République centrafricaine</span>',
      do: '<span class="mr-4 iti__flag iti__do"></span> <span>République Dominicaine</span>',
      cd: '<span class="mr-4 iti__flag iti__cd"></span> <span>République démocratique du Congo</span>',
      cz: '<span class="mr-4 iti__flag iti__cz"></span> <span>République tchèque</span>',
      re: '<span class="mr-4 iti__flag iti__re"></span> <span>Réunion</span>',
      eh: '<span class="mr-4 iti__flag iti__eh"></span> <span>Sahara occidental</span>',
      sh: '<span class="mr-4 iti__flag iti__sh"></span> <span>Sainte-Hélène</span>',
      sx: '<span class="mr-4 iti__flag iti__sx"></span> <span>Saint-Martin</span>',
      pm: '<span class="mr-4 iti__flag iti__pm"></span> <span>Saint-Pierre-et-Miquelon</span>',
      bl: '<span class="mr-4 iti__flag iti__bl"></span> <span>Saint-Barthélemy</span>',
      kn: '<span class="mr-4 iti__flag iti__kn"></span> <span>Saint-Christophe-et-Niévès</span>',
      sm: '<span class="mr-4 iti__flag iti__sm"></span> <span>Saint-Marin</span>',
      vc: '<span class="mr-4 iti__flag iti__vc"></span> <span>Saint-Vincent-et-les-Grenadines</span>',
      lc: '<span class="mr-4 iti__flag iti__lc"></span> <span>Sainte-Lucie</span>',
      sv: '<span class="mr-4 iti__flag iti__sv"></span> <span>Salvador</span>',
      ws: '<span class="mr-4 iti__flag iti__ws"></span> <span>Samoa</span>',
      as: '<span class="mr-4 iti__flag iti__as"></span> <span>Samoa américaines</span>',
      st: '<span class="mr-4 iti__flag iti__st"></span> <span>Sao Tomé-et-Principe</span>',
      rs: '<span class="mr-4 iti__flag iti__rs"></span> <span>Serbie</span>',
      sc: '<span class="mr-4 iti__flag iti__sc"></span> <span>Seychelles</span>',
      sl: '<span class="mr-4 iti__flag iti__sl"></span> <span>Sierra Leone</span>',
      sg: '<span class="mr-4 iti__flag iti__sg"></span> <span>Singapour</span>',
      sk: '<span class="mr-4 iti__flag iti__sk"></span> <span>Slovaquie</span>',
      si: '<span class="mr-4 iti__flag iti__si"></span> <span>Slovénie</span>',
      so: '<span class="mr-4 iti__flag iti__so"></span> <span>Somalie</span>',
      sd: '<span class="mr-4 iti__flag iti__sd"></span> <span>Soudan</span>',
      ss: '<span class="mr-4 iti__flag iti__ss"></span> <span>Soudan du Sud</span>',
      lk: '<span class="mr-4 iti__flag iti__lk"></span> <span>Sri Lanka</span>',
      sr: '<span class="mr-4 iti__flag iti__sr"></span> <span>Suriname</span>',
      se: '<span class="mr-4 iti__flag iti__se"></span> <span>Suède</span>',
      sj: '<span class="mr-4 iti__flag iti__sj"></span> <span>Svalbard et Jan Mayen</span>',
      sz: '<span class="mr-4 iti__flag iti__sz"></span> <span>Swaziland</span>',
      sy: '<span class="mr-4 iti__flag iti__sy"></span> <span>Syrie</span>',
      sn: '<span class="mr-4 iti__flag iti__sn"></span> <span>Sénégal</span>',
      tw: '<span class="mr-4 iti__flag iti__tw"></span> <span>Taïwan</span>',
      tj: '<span class="mr-4 iti__flag iti__tj"></span> <span>Tadjikistan</span>',
      tz: '<span class="mr-4 iti__flag iti__tz"></span> <span>Tanzanie</span>',
      td: '<span class="mr-4 iti__flag iti__td"></span> <span>Tchad</span>',
      tf: '<span class="mr-4 iti__flag iti__tf"></span> <span>Terres australes françaises</span>',
      io: '<span class="mr-4 iti__flag iti__io"></span> <span>Territoire britannique de l\'océan Indien</span>',
      ps: '<span class="mr-4 iti__flag iti__ps"></span> <span>Territoire palestinien</span>',
      th: '<span class="mr-4 iti__flag iti__th"></span> <span>Thaïlande</span>',
      tl: '<span class="mr-4 iti__flag iti__tl"></span> <span>Timor oriental</span>',
      tg: '<span class="mr-4 iti__flag iti__tg"></span> <span>Togo</span>',
      tk: '<span class="mr-4 iti__flag iti__tk"></span> <span>Tokelau</span>',
      to: '<span class="mr-4 iti__flag iti__to"></span> <span>Tonga</span>',
      tt: '<span class="mr-4 iti__flag iti__tt"></span> <span>Trinité-et-Tobago</span>',
      tn: '<span class="mr-4 iti__flag iti__tn"></span> <span>Tunisie</span>',
      tm: '<span class="mr-4 iti__flag iti__tm"></span> <span>Turkménistan</span>',
      tr: '<span class="mr-4 iti__flag iti__tr"></span> <span>Turquie</span>',
      tv: '<span class="mr-4 iti__flag iti__tv"></span> <span>Tuvalu</span>',
      ua: '<span class="mr-4 iti__flag iti__ua"></span> <span>Ukraine</span>',
      uy: '<span class="mr-4 iti__flag iti__uy"></span> <span>Uruguay</span>',
      vu: '<span class="mr-4 iti__flag iti__vu"></span> <span>Vanuatu</span>',
      vn: '<span class="mr-4 iti__flag iti__vn"></span> <span>Vietnam</span>',
      ve: '<span class="mr-4 iti__flag iti__ve"></span> <span>Venezuela</span>',
      wf: '<span class="mr-4 iti__flag iti__wf"></span> <span>Wallis-et-Futuna</span>',
      ye: '<span class="mr-4 iti__flag iti__ye"></span> <span>Yémen</span>',
      zm: '<span class="mr-4 iti__flag iti__zm"></span> <span>Zambie</span>',
      zw: '<span class="mr-4 iti__flag iti__zw"></span> <span>Zimbabwe</span>',
      eg: '<span class="mr-4 iti__flag iti__eg"></span> <span>Égypte</span>',
      ec: '<span class="mr-4 iti__flag iti__ec"></span> <span>Équateur</span>',
      er: '<span class="mr-4 iti__flag iti__er"></span> <span>Érythrée</span>',
      va: '<span class="mr-4 iti__flag iti__va"></span> <span>État de la Cité du Vatican</span>',
      us: '<span class="mr-4 iti__flag iti__us"></span> <span>États-Unis</span>',
      et: '<span class="mr-4 iti__flag iti__et"></span> <span>Éthiopie</span>',
      cx: '<span class="mr-4 iti__flag iti__cx"></span> <span>Île Christmas</span>',
      bv: '<span class="mr-4 iti__flag iti__bv"></span> <span>Île Bouvet</span>',
      im: '<span class="mr-4 iti__flag iti__im"></span> <span>Île de Man</span>',
      nf: '<span class="mr-4 iti__flag iti__nf"></span> <span>Île Norfolk</span>',
      ky: '<span class="mr-4 iti__flag iti__ky"></span> <span>Îles Caïmans</span>',
      cc: '<span class="mr-4 iti__flag iti__cc"></span> <span>Îles Cocos - Keeling</span>',
      ck: '<span class="mr-4 iti__flag iti__ck"></span> <span>Îles Cook</span>',
      hm: '<span class="mr-4 iti__flag iti__hm"></span> <span>Îles Heard et McDonald</span>',
      mp: '<span class="mr-4 iti__flag iti__mp"></span> <span>Îles Mariannes du Nord</span>',
      mh: '<span class="mr-4 iti__flag iti__mh"></span> <span>Îles Marshall</span>',
      um: '<span class="mr-4 iti__flag iti__um"></span> <span>Îles mineures éloignées des États-Unis</span>',
      pn: '<span class="mr-4 iti__flag iti__pn"></span> <span>Îles Pitcairn</span>',
      sb: '<span class="mr-4 iti__flag iti__sb"></span> <span>Îles Salomon</span>',
      tc: '<span class="mr-4 iti__flag iti__tc"></span> <span>Îles Turques-et-Caïques</span>',
      vg: '<span class="mr-4 iti__flag iti__vg"></span> <span>Îles Vierges britanniques</span>',
      vi: '<span class="mr-4 iti__flag iti__vi"></span> <span>Îles Vierges des États-Unis</span>',
      ax: '<span class="mr-4 iti__flag iti__ax"></span> <span>Îles Åland</span>'
    },
    calendar: {
      days: {
        monday: 'Lun.',
        tuesday: 'Mar.',
        wednesday: 'Mer.',
        thursday: 'Jeu.',
        friday: 'Ven.',
        saturday: 'Sam.',
        sunday: 'Dim.'
      },
      months: {
        january: 'Janvier',
        february: 'F&eacute;vrier',
        march: 'Mars',
        april: 'Avril',
        may: 'Mai',
        june: 'Juin',
        july: 'Juillet',
        august: 'Ao&ucirc;t',
        september: 'Septembre',
        october: 'Octobre',
        november: 'Novembre',
        december: 'D&eacute;cembre'
      },
      timelineSelection: {
        uponReceipt: 'À réception',
        inSevenDays: 'À 7 jours',
        inFifteenDays: 'À 15 jours',
        inThirtyDays: 'À 30 jours',
        inFortyFiveDays: 'À 45 jours',
        inSixtyDays: 'À 60 jours',
        inNinetyDays: 'À 90 jours'
      }
    },
    timeAgo: {
      now: 'Juste maintenant',
      secondsAgo: 'il y a %s secondes',
      minuteAgo: 'il y a 1 minute',
      minutesAgo: 'il y a %s minutes',
      hourAgo: 'il y a 1 heure',
      hoursAgo: 'il y a %s heures',
      dayAgo: 'hier',
      daysAgo: 'il y a %s jours',
      weekAgo: 'il y a 1 semaine',
      weeksAgo: 'il y a %s semaines',
      monthAgo: 'il y a 1 mois',
      monthsAgo: 'il y a %s mois',
      yearAgo: 'il y a 1 an',
      yearsAgo: 'il y a %s ans'
    },
    timeTo: {
      now: 'En ce moment',
      secondsTo: 'dans %s secondes',
      minuteTo: 'dans 1 minute',
      minutesTo: 'en % minutes',
      hourTo: 'dans 1 heure',
      hoursTo: 'dans %s heures',
      dayTo: 'dans 1 jour',
      daysTo: 'dans %s jours',
      weekTo: 'dans 1 semaine',
      weeksTo: 'dans %s semaines',
      monthTo: 'dans 1 mois',
      monthsTo: 'dans %s mois',
      yearTo: 'dans 1 an',
      yearsTo: 'dans %s années'
    },
    userRoles: {
      owner: 'Propriétaire',
      member: 'Membre'
    },
    entities: {
      invoice: 'facture',
      invoices: 'factures',
      todo: 'todo',
      todos: 'todos',
      profile: 'profil',
      workspace: 'espace de travail',
      customer: 'client',
      customers: 'clients',
      contact: 'contact',
      contacts: 'contacts',
      bankAccount: 'compte bancaire',
      bbb_bank_connection: 'le compte bancaire',
      bankConnection: 'connection bancaire',
      paymentLink: 'lien de paiement',
      email: 'email',
      history: 'historique'
    },
    feedback: {
      general: 'Une erreur s\'est produite.',
      apiError: 'Une erreur s\'est produite lors de la tentative de {{action}} {{entity}}.\n{{errorMessage}}',
      action: {
        create: 'créer',
        read: 'lire',
        update: 'mise à jour',
        delete: 'supprimer'
      },
      verifyEmail: 'Veuillez suivre les instructions envoyées à votre adresse e-mail.',
      bbbAmountExceeded: 'Impossible de créer un lien de paiement. Les virements supérieurs à 35000€ ne sont pas autorisés.',
      emailAddressNotProvided: 'Veuillez fournir une adresse e-mail valide.'
    },
    email: {
      welcome: {
        subject: 'Un grand accueil chaleureux de nous tous ici à Dundy'
      },
      invitation: {
        subject: 'Vous avez été invité à Dundy',
        success: 'Membre de l\'équipe invité',
        error: 'Impossible d\'inviter le membre de l\'équipe'
      }
    },
    amountsMeter: {
      outstanding: 'dû',
      overdue: 'En retard',
      onTime: 'Dans les temps',
      onTimeTip: '<b>Dans les temps</b> : vous avez émis une facture mais elle <b>n\'est pas encore exigible</b> car sa date d\'échéance est postérieure à la date du jour.',
      overdueTip: '<b>En retard</b> :  facture dont la date d\'échéance - <b>la date limite de paiement</b> - est passée. Le paiement de cette facture est alors exigible.'
    },
    missingContact: {
      text: 'Contact manquant',
      link: 'Ajouter un contact'
    },
    missingBankAccount: {
      text: 'Compte bancaire manquant',
      link: 'Ajouter un compte bancaire'
    },
    form: {
      labels: {
        show: 'Voir',
        hide: 'Cacher'
      },
      validation: {
        invalidEmailDomain: 'Les addresses {{domain}} ne sont pas acceptées.',
        invalidSIREN: 'Veuillez saisir un SIREN ou SIRET valide.',
        invalidVAT: 'Veuillez saisir un numéro de TVA valide.',
        invalidPhoneNumber: 'Veuillez saisir un numéro de téléphone valide.'
      }
    },
    errorPage: {
      pageNotFound: {
        title: 'Page introuvable',
        backHome: 'Accéder à mon tableau de bord'
      }
    },
    topMenu: {
      nav: {
        dashboard: 'Aujourd\'hui',
        transactions: 'Transactions',
        payments: 'Paiements',
        getPaidSection: 'Se faire payer',
        quotes: 'Devis',
        invoices: 'Factures',
        dunning: 'Relances',
        clients: {
          title: 'Clients',
          manage: 'Liste des clients',
          agingBalance: 'Balance âgée'
        },
        workspaces: 'Espaces de travail',
        profileSection: 'Réglages',
        settings: {
          templates: 'Modèles',
          reminderWorkflow: 'Plan de relance',
          profileSettings: 'Mon Profil',
          invoicingSettings: 'Réglages de facturation',
          companySettings: 'Profil de l\'entreprise',
          bankAccounts: 'Comptes bancaires',
          exports: 'Exports'
        },
        team: {
          label: 'Equipe',
          inviteButton: 'Inviter'
        },
        logout: 'Me déconnecter',
        exports: 'Exports'
      },
      userNav: {
        forLabel: 'pour',
        signedAs: 'Connecté(e) en tant que',
        teamMember_one: '{{count}} membre',
        teamMember_other: '{{count}} membres',
        manageWorkspaces: 'Gérer les espaces de travail',
        profile: 'Paramètres de Profil',
        invoicing_app: 'Paramètres de Facturation',
        company: 'Paramètres de la Société',
        bank: 'Paramètres Bancaires',
        logOut: 'Me déconnecter'
      }
    },
    dashboard: {
      hud: {
        revenue: {
          title: 'Revenue',
          tooltip: 'Tous les encaissements effectués sur votre compte bancaire au cours de la période sélectionnée.'
        },
        expenses: {
          title: 'Dépenses',
          tooltip: 'Toutes les dépenses effectuées sur votre compte bancaire au cours de la période sélectionnée.'
        },
        lateInvoices: {
          title: 'Factures en retard'
        }
      },
      gridSoloItem: {
        noBank: {
          copy_highlight: 'Connectez votre compte en banque',
          copy_regular: 'pour profiter du suivi de trésorerie.'
        }
      },
      todoModule: {
        title: 'Vos tâches en attente',
        setupIncomplete: {
          title: 'Configurez votre compte',
          tooltip: 'Complétez les étapes de configuration de votre compte pour débloquer l\'affichage de votre suivi.',
          accountCreation: 'Je crée mon compte Dundy',
          firstClient: 'J\’ajoute mon premier client',
          firstInvoice: 'Je crée ma première facture',
          connectBank: 'Je connecte mon compte en banque'
        },
        loading: 'Chargement de vos tâches...',
        tasks: {
          criticalTasks_one: '{{count}} relance critique',
          criticalTasks_other: '{{count}} relances critiques',
          invoiceToSend_one: '{{count}} facture à envoyer',
          invoiceToSend_other: '{{count}} factures à envoyer',
          invoicesDraft_one: '{{count}} brouillon de facture',
          invoicesDraft_other: '{{count}} brouillons de factures',
          creditNotesDraft_one: '{{count}} brouillon d\'avoir',
          creditNotesDraft_other: '{{count}} brouillons d\'avoirs'
        },
        noMoreTasks: {
          title: 'Vous n\'avez pas de tâches à effectuer',
          subtitle: 'Ajoutez de nouvelles factures et suivez-les pour générer de nouvelles tâches.'
        }

      },
      invoicesToGenerateModule: {
        title: 'Reste à facturer',
        amountAllTaxes: 'Montant TTC',
        latestInvoice: 'Dernière facture : ',
        seeAll_one: 'Parcourir le reste à facturer',
        seeAll_other: 'Parcourir les {{count}} restes à facturer',
        tooltip: 'Reprenez vos facturations en cours : si vous avez émis une facture d\'acompte, vous pouvez retrouver automatiquement la facture intermédiaire ou facture de solde qui finalisera votre facturation.',
        noInvoices: {
          copy: 'Les factures intermédiaires et factures de soldes à émettre seront automatiquement affichées ici.'
        }
      },
      debtorsModule: {
        title: 'Principaux débiteurs',
        seeAll: 'Tous mes débiteurs',
        noMoreDebtors: {
          title: 'Vous n\'avez plus de débiteurs',
          subtitle: 'Bravo ! Réduire le nombre de débiteurs est un excellent moyen d\'améliorer votre trésorerie.'
        },
        NoDebtorsYet: {
          copy: 'Vous n’avez aucun débiteur pour le moment.'
        }
      }
    },
    signIn: {
      back: 'Retour',
      createAccount: 'Créez un compte',
      title: 'Ravi de vous revoir!',
      subtitle: 'Récupérons quelques paiements.',
      emailLabel: 'Adresse e-mail',
      emailPlaceholder: 'Saisissez votre adresse e-mail',
      errorEmail: 'Veuillez renseigner un e-mail valide',
      passwordLabel: 'Mot de passe',
      passwordPlaceholder: 'Entrez votre mot de passe',
      errorPassword: 'Veuillez renseigner un mot de passe valide',
      forgotPassword: 'Mot de passe oublié ?',
      login: 'Accèdez à votre espace de travail',
      notRegistered: 'Pas encore de compte ?',
      loginFailed: 'Nous n\'avons pas réussi à vous connecter.',
      tagLine: 'Simplifiez la vie de votre entreprise',
      tagLineDescription: 'Dundy vous aide à gérer vos factures, vos paiements et vos relances. Tout cela en un seul endroit.'
    },
    signUp: {
      back: 'Retour à l\'accueil',
      signIn: 'Me connecter',
      loginFailed: 'La connexion a échoué.'
    },
    resetPassword: {
      resetFailed: 'La réinitialisation de mot de passe a échoué.',
      resetSuccessful: 'Votre mot de passe a bien été réinitialisé.',
      checkMail: 'Vérifiez votre boîte mail',
      back: 'Retour à l\'accueil',
      signIn: 'Me connecter',
      title: 'Un souci pour vous connecter ?',
      subtitle: 'Saisissez votre email pour réinitialiser votre mot de passe.',
      email: 'Email',
      errorEmail: 'Merci de saisir une adresse email valide',
      reset: 'Réinitialiser mon mot de passe',
      note: 'Vous êtes au bon endroit pour réinitialiser un mot de passe oublié. Pour votre sécurité,',
      boldnote:
        'nous vous enverrons un email contenant un lien pour récupérer l\'accès à votre compte.'
    },
    pagination: {
      label1: 'Affichage',
      label2: 'à',
      label3: 'sur',
      label4: 'éléments'
    },
    workspaces: {
      selectWorkspace: 'Sélectionnez un espace de travail',
      intro: 'Sélectionnez l\'espace de travail que vous souhaitez activer.',
      logout: 'Me déconnecter et revenir à l\'accueil',
      add: 'Créer un espace de travail',
      addSubtitle: 'Commencez par indiquer les informations de la société.',
      delete: 'Supprimer l\'espace de travail',
      search: {
        placeholder: 'Recherche par nom d\'entreprise ou banque'
      },
      empty: {
        title: 'Aucun espace de travail pour le moment',
        first_paragraph: 'Commencez par créer un nouvel espace de travail.'
      },
      dataTable: {
        heading: 'TÂCHES HEBDOMADAIRES',
        columnOneHeading: 'Priorité',
        columnTwoHeading: 'Date à faire',
        columnThreeHeading: 'Facturer #',
        columnFourHeading: 'Montant de la facture',
        columnFiveHeading: 'Statut',
        columnSixHeading: 'Jours d\'échéance',
        footerText: 'Montant hebdomadaire dû:',
        dataSheet: 'Fiche '
      },
      export: 'Exporter les données',
      loadingData: 'Chargement des données d\'exportation...',
      selectSuccess: 'Espace de travail modifié avec succès',
      modalHeading: 'Ajoutez un nouvel espace de travail'
    },
    editWorkspace: {
      error: 'Échec de la mise à jour',
      title: 'Détails de l\'espace de travail',
      cancel: 'Annuler',
      save: 'Enregistrer les modifications',
      companyLinkTitle: 'Détails de l\'entreprise',
      newCompanyCaption: 'Commencez par renseigner les coordonnées de l\'entreprise de l\'espace de travail que vous créez.',
      loading: 'Travail...',
      added: 'Espace de travail ajouté',
      updated: 'Espace de travail mis à jour',
      addOrEditCompanyDetails: 'Détails de l\'espace de travail',
      saveWorkspace: 'Enregistrer l\'espace de travail',
      errorCreatingWorkspace: 'Une erreur est survenue lors de la tentative de création de votre espace de travail.'
    },
    firstTask: {
      title: 'Let’s get started!',
      subtitle: {
        one: 'You are ',
        two: '1',
        three:
          '  task away from getting your coach working for you! This should take you about ',
        four: '2 minutes',
        five: ' and get you ',
        six: 'onboarded'
      },
      card: {
        title: {
          one: 'Ajoutez',
          two: ' votre première facture en l\'uploadant ou en saisissant manuellement ses informations'
        },
        subtitle:
          'Dundy vous aide en assurant le suivi de vos factures, afin que vous vous occupiez du reste !'
      }
    },
    addInvoiceTask: {
      card: {
        title: {
          one: 'Ajoutez',
          two: ' de nouvelles factures en uploadant ou en saisissant manuellement leurs informations'
        },
        subtitle:
          'Dundy vous assiste dans le lettrage: vous n\'avez qu\'à valider les paiements reçus !'
      }
    },
    automaticNumbering: {
      defaultAutomaticNumberingAccordingToKind: {
        Invoice: 'F-{{YYYY}}-{{MM}}-{{SEQ}}',
        CreditNote: 'A-{{YYYY}}-{{MM}}-{{SEQ}}',
        PurchaseOrder: 'C-{{YYYY}}-{{MM}}-{{SEQ}}',
        Quote: 'D-{{YYYY}}-{{MM}}-{{SEQ}}'
      },
      replacementsNumbering: {
        YYYY: 'AAAA',
        YY: 'AA',
        MM: 'MM',
        DD: 'JJ'
      },
      replacementsNumberingWithParentheses: {
        YYYY: '(AAAA)',
        YY: '(AA)',
        MM: '(MM)',
        DD: '(JJ)'
      },
      replacementsServerSideValues: {
        YYYY: '{{YYYY}}',
        YY: '{{YY}}',
        MM: '{{MM}}',
        DD: '{{DD}}'
      },
      replacementsDefaultLettersForTemplates: {
        Invoice: 'F',
        CreditNote: 'A',
        PurchaseOrder: 'B',
        Quote: 'D'
      },
      Quote: {
        title: 'Paramétrez la numérotation automatique de vos devis',
        copy: 'Définissez le modèle de votre choix pour la numérotation automatique de vos devis.',
        inputModelLabel: 'Modèle de numérotation',
        inputModelPlaceholder: 'Saisissez un préfixe',
        inputStartLabel: 'Numéro du prochain devis',
        inputStartPlaceholder: 'Saisissez un numéro',
        inputStartError: 'Veuillez saisir un numéro de séquence valide',
        previousNumber: 'Votre précédent devis avait le numéro <em class="font-medium">{{previewFinalBusinessDocumentNumber}}</em>',
        nextInvoiceNumberPreview: 'Votre prochain devis sera <em class="font-medium">{{previewFinalBusinessDocumentNumber}}</em>',
        help: '<h3 class=\'font-bold mb-2\'>Choisir ma numérotation de devis</h3>\n' +
          '                    <ul class=\'list-disc ml-4 text-s\'>\n' +
          '                        <li class=\'py-2\'>Chaque devis doit posséder <strong>un numéro unique, basé sur une suite\n' +
          '                            chronologique continue</strong> afin d\'être conforme aux exigences de l\'administration\n' +
          '                            fiscale. <strong>Par exemple: D-01, D-02, D-03</strong>.\n' +
          '                        </li>\n' +
          '                        <li class=\'py-2\'>Affichez l\'année en utilisant <b>(AAAA)</b>, le mois avec <b>(MM)</b> ou le\n' +
          '                            jour avec <b>(JJ)</b></li>\n' +
          '                    </ul>',
        cancelButton: 'Annuler',
        saveButton: 'Confirmer',
        defaultAutomaticInvoiceNumberingPlaceholder: 'D-(AAAA)-(MM)-',
        defaultAutomaticInvoiceNumberingSequenceStarter: '01',
        tipAboutFinalInvoiceNumber: 'Lorsque vous finaliserez votre avoir, un numéro lui sera automatiquement attribué, respectant votre modèle de numérotation et la suite logique des avoirs émis précédemment.'
      },
      CreditNote: {
        title: 'Paramétrez la numérotation automatique de vos avoirs',
        copy: 'Définissez le modèle de votre choix pour la numérotation automatique de vos avoirs.',
        inputModelLabel: 'Modèle de numérotation',
        inputModelPlaceholder: 'Saisissez un préfixe',
        inputStartLabel: 'Numéro du prochain avoir',
        inputStartPlaceholder: 'Saisissez un numéro',
        inputStartError: 'Veuillez saisir un numéro de séquence valide',
        previousNumber: 'Votre avoir précédent portait le numéro <em class="font-medium">{{previewFinalBusinessDocumentNumber}}</em>',
        nextInvoiceNumberPreview: 'Votre prochain avoir sera <em class="font-medium">{{previewFinalBusinessDocumentNumber}}</em>',
        help: '<h3 class=\'font-bold mb-2\'>Choisir ma numérotation d\'avoir</h3>\n' +
          '                    <ul class=\'list-disc ml-4 text-s\'>\n' +
          '                        <li class=\'py-2\'>Chaque avoir doit posséder <strong>un numéro unique, basé sur une suite\n' +
          '                            chronologique continue</strong> afin d\'être conforme aux exigences de l\'administration\n' +
          '                            fiscale. <strong>Par exemple: A-01, A-02, A-03</strong>.\n' +
          '                        </li>\n' +
          '                        <li class=\'py-2\'>Affichez l\'année en utilisant <b>(AAAA)</b>, le mois avec <b>(MM)</b> ou le\n' +
          '                            jour avec <b>(JJ)</b></li>\n' +
          '                    </ul>',
        cancelButton: 'Annuler',
        saveButton: 'Confirmer',
        defaultAutomaticInvoiceNumberingPlaceholder: 'A-(AAAA)-(MM)-',
        defaultAutomaticInvoiceNumberingSequenceStarter: '01',
        tipAboutFinalInvoiceNumber: 'Lorsque vous finaliserez votre avoir, un numéro lui sera automatiquement attribué, respectant votre modèle de numérotation et la suite logique des avoirs émis précédemment.'
      },
      Invoice: {
        title: 'Paramétrez la numérotation automatique de vos factures',
        copy: 'Définissez le modèle de votre choix pour la numérotation automatique de vos factures.',
        inputModelLabel: 'Modèle de numérotation',
        inputModelPlaceholder: 'Saisissez un préfixe',
        inputStartLabel: 'Numéro de la prochaine facture',
        inputStartPlaceholder: 'Saisissez un numéro',
        inputStartError: 'Veuillez saisir un numéro de séquence valide',
        previousNumber: 'Votre facture précédente portait le numéro <em class="font-medium">{{previewFinalBusinessDocumentNumber}}</em>',
        nextInvoiceNumberPreview: 'Votre prochaine facture sera <em class="font-medium">{{previewFinalBusinessDocumentNumber}}</em>',
        help: '<h3 class=\'font-bold mb-2\'>Choisir ma numérotation de facture</h3>\n' +
          '                    <ul class=\'list-disc ml-4 text-s\'>\n' +
          '                        <li class=\'py-2\'>Chaque facture doit avoir <strong>un numéro unique, basé sur une suite\n' +
          '                            chronologique continue</strong> afin d\'être conforme aux exigences de l\'administration\n' +
          '                            fiscale. <strong>Par exemple: F-01, F-02, F-03</strong>.\n' +
          '                        </li>\n' +
          '                        <li class=\'py-2\'>Affichez l\'année en utilisant <b>(AAAA)</b>, le mois avec <b>(MM)</b> ou le\n' +
          '                            jour avec <b>(JJ)</b></li>\n' +
          '                    </ul>',
        cancelButton: 'Annuler',
        saveButton: 'Confirmer',
        defaultAutomaticInvoiceNumberingPlaceholder: 'F-(AAAA)-(MM)-',
        defaultAutomaticInvoiceNumberingSequenceStarter: '01',
        tipAboutFinalInvoiceNumber: 'Lorsque vous finaliserez votre facture, un numéro lui sera automatiquement attribué, respectant votre modèle de numérotation et la suite logique des factures émises précédemment.'
      }
    },
    transactions: {
      lastMonth: 'Mois dernier :',
      hud: {
        expenses: {
          title: 'Décaissements',
          tooltip: 'Toutes les dépenses effectuées sur votre compte bancaire au cours de la période sélectionnée.'
        },
        revenue: {
          revenueTitle: 'Revenue',
          title: 'Encaissements',
          tooltip: 'Tous les encaissements effectués sur votre compte bancaire au cours de la période sélectionnée.'
        },
        cashburn: {
          title: 'Cashburn',
          tooltip: 'Le cashburn est la différence entre les encaissements et les dépenses. Il représente le montant de trésorerie que vous avez utilisé au cours de la période sélectionnée.'
        },
        periodTime: 'Période : 30 derniers jours\n' +
          '            (du {{startDateCurrentPeriod}}\n' +
          '            au {{endDateCurrentPeriod}}), comparée avec les 30 jours\n' +
          '            précédents (du {{startDatePreviousPeriod}}\n' +
          '            au {{endDatePreviousPeriod}})'

      },
      pageTitle: 'Transactions',
      empty: {
        title: 'Ce compte ne contient pas de transaction',
        subtitle: 'Changez de compte ou revenez plus tard.',
        button: 'Changer mon compte bancaire'
      },
      refreshTransactions: {
        refreshTransactionsButtonTitle: 'Rafraîchir les transactions',
        refreshTransactionsNoTransaction: 'Pas de paiement reçu'
      },
      error: {
        title: 'Nous n\'avons pas pu charger vos transactions',
        subtitle: 'Veuillez connecter votre compte bancaire ou vérifier la connexion de votre compte bancaire pour continuer.',
        button: 'Vérifier mon compte bancaire'
      },
      header: {
        date: 'Date d\'opération',
        operation: 'Transaction',
        amount: 'Montant',
        account: 'Compte'

      }
    },
    supportedCurrenciesList: {
      euroLabel: 'Euro (€)',
      dollarLabel: 'Dollar Américain ($)',
      swissFrancLabel: 'Franc Suisse (CHF)',
      poundLabel: 'Livre Britannique (£)',
      yenLabel: 'Japanese Yen (¥)',
      canadianDollarLabel: 'Dollar Canadien (CAD)',
      australianDollarLabel: 'Dollar Australien (AUD)',
      newZealandDollarLabel: 'Dollar Néo-Zélandais (NZD)'
    },
    unitTypesList: {
      unit_unit: 'Unité',
      unit_unit_one: 'Unité',
      unit_unit_other: 'Unités',
      unit_gram_one: 'Gramme',
      unit_gram_other: 'Grammes',
      unit_hour_one: 'Heure',
      unit_hour_other: 'Heures',
      unit_day_one: 'Jour',
      unit_day_other: 'Jours',
      unit_week_one: 'Semaine',
      unit_week_other: 'Semaines',
      unit_month_one: 'Mois',
      unit_month_other: 'Mois',
      unit_kg_one: 'Kilogramme',
      unit_kg_other: 'Kilogrammes',
      unit_km_one: 'Kilomètre',
      unit_km_other: 'Kilomètres',
      unit_liter_one: 'Litre',
      unit_liter_other: 'Litres',
      unit_lot_one: 'Lot',
      unit_lot_other: 'Lots',
      unit_meter_one: 'Mètre',
      unit_meter_other: 'Mètres',
      unit_square_meter_one: 'Mètre carré',
      unit_square_meter_other: 'Mètres carrés',
      unit_cubic_meter_one: 'Mètre cube',
      unit_cubic_meter_other: 'Mètres cubes',
      unit_linear_meter_one: 'Mètre linéaire',
      unit_linear_meter_other: 'Mètres linéaires',
      unit_person_one: 'Personne',
      unit_person_other: 'Personnes',
      unit_ton_one: 'Tonne',
      unit_ton_other: 'Tonnes'
    },
    quotes: {
      pageTitle: 'Mes devis',
      tabs: {
        all: 'Tous les devis',
        accepted: 'Acceptés',
        draft: 'Brouillons',
        toSend: 'À envoyer'
      },
      columns: {
        client: 'Client',
        customerName: 'Nom du client',
        quoteNumber: 'Devis',
        issuedDate: 'Émission',
        amount: 'Montant TTC',
        amountIncludingTaxes: 'Montant(taxes inclues)',
        amountExcludingTaxes: 'Montant (hors taxes)',
        currency: 'Devise',
        vat: 'TVA',
        taxesPercent: 'Taxes %',
        taxesAmount: 'Total Taxes',
        dueDate: 'Échéance',
        status: 'Statut'
      },
      menu: {
        delete: 'Supprimer',
        customer: 'Afficher le client',
        edit: 'Modifier le devis',
        view: 'Voir le devis'
      },
      createQuote: {
        newQuoteHeader: 'Nouveau devis',
        draftQuoteHeader: 'Brouillon de devis',
        quoteDetailsTitle: 'Détails du devis',
        automaticNumberingLabel: 'Numéro automatique du devis',
        paymentTitle: 'Paiement',
        paymentTermsLabel: 'Modalités de paiement'
      },
      viewQuote: {
        menu: {
          itemDetails: 'Détails',
          itemActivity: 'Activité',
          itemSettings: 'Paramètres'
        }
      },
      form: {
        quoteContent: {
          label: 'Contenu du devis',
          titleLabel: 'Titre de document',
          descriptionLabel: 'Description'
        },
        dateIssued: {
          label: 'Date d\'émission',
          error: 'Pas une date valide'
        },
        dateExpired: {
          label: 'Date d\'expiration',
          error: 'Cette date n\'est pas valide'
        }
      }
    },
    uploadAttachments: {
      dragAndDrop: 'Glissez-déposez votre',
      attachment: 'fichier',
      or: 'ou',
      browseFiles: 'parcourir les fichiers',
      onYourComputer: 'sur votre ordinateur',
      uploadingAttachment: 'Téléchargement de le fichier...',
      newUpload: 'Nouveau téléchargement',
      drop: 'Déposez la pièce jointe ici ou cliquez pour sélectionner.',
      uploadButton: 'Télécharger le fichier',
      close: 'Fermer',
      errors: {
        maxSize: 'La taille du fichier ne peut pas dépasser 10MB',
        accept: 'Type de fichier non pris en charge'

      }
    },
    invoices: {
      pageTitle: 'Mes factures',
      states: {
        voided: 'Annulée',
        creditNote: 'Avoir',
        draft: 'Brouillon'
      },
      sources: {
        voxy: 'Editeur de facture',
        dundy: 'Pied de facture'
      },
      total: 'Total',
      tabs: {
        all: 'Toutes les factures',
        tracking: 'Suivies',
        paid: 'Payées',
        invoices: 'Factures',
        creditNotesAndVoidedInvoices: 'Avoirs',
        voided: 'Annulés',
        draft: 'Brouillons',
        toSend: 'À envoyer'
      },
      filters: {
        all: 'Toutes',
        paid: 'Payées',
        outstanding: 'Ouvertes',
        deleted: 'Effacées'
      },
      controls: {
        exportFECFile: 'Exporter au format FEC',
        exportZIPFile: 'Exporter au format ZIP',
        deleteSelectedInvoice_one: 'Supprimer',
        deleteSelectedInvoice_other: 'Supprimer ({{count}})',
        deleteInProgress: 'Suppression...'
      },
      columns: {
        client: 'Client',
        customerName: 'Nom du client',
        invoiceNumber: 'Facture',
        issuedDate: 'Émission',
        amount: 'Montant TTC',
        totalIncludingTaxes: 'Total TTC',
        installmentIncludingTaxes: 'Facturé TTC',
        amountIncludingTaxes: 'Montant(taxes inclues)',
        amountExcludingTaxes: 'Montant (hors taxes)',
        currency: 'Devise',
        vat: 'TVA',
        taxesPercent: 'Taxes %',
        taxesAmount: 'Total Taxes',
        dueDate: 'Échéance',
        status: 'Statut'
      },
      datatable: {
        createSearchedCustomerOne: 'Créer « ',
        createSearchedCustomerTwo: ' »',
        createNewCustomer: 'Créer un nouveau client',
        chooseACustomer: 'Choisir un client',
        missingCompanyName: '(nom de société non défini)',
        createNewContact: 'Nouveau contact'
      },
      menu: {
        delete: 'Supprimer la facture',
        dunning: 'Envoyer un rappel',
        paid: 'Marquer comme payée',
        unpaid: 'Marquer comme non payée',
        untrack: 'Arrêter le suivi',
        track: 'Suivre la facture',
        customer: 'Afficher le client',
        edit: 'Modifier la facture',
        view: 'Voir la facture',
        action: 'Relancer la facture'
      },
      actionMenu: {
        createInvoice: 'Créer une facture',
        createNewCreditNote: 'Créer un avoir commercial'

      },
      duplicateInvoice: {
        duplicateInvoiceSuffix: '(copie)',
        duplicateSuccess: 'Votre facture a été dupliquée avec succès !',
        duplicateError: 'Une erreur est survenue lors de la duplication de la facture'
      },
      uploadInvoice: {
        dragAndDrop: 'Glissez-déposez votre',
        invoice: 'facture',
        or: 'ou',
        browseFiles: 'parcourir les fichiers',
        onYourComputer: 'sur votre ordinateur',
        uploadingInvoice: 'Téléchargement de la facture...',
        newUpload: 'Nouveau téléchargement',
        drop: 'Déposez la facture ici ou cliquez pour sélectionner.',
        uploadButton: 'Télécharger la facture',
        close: 'Fermer',
        errors: {
          maxSize: 'La taille du fichier ne peut pas dépasser 10MB',
          accept: 'Seuls les fichiers PDF sont acceptés'

        }
      },
      businessDocument: {
        commonWords: {
          description: 'Objet:'
        },
        mainHeaderTitle: {
          invoicePreviewAndPDF: 'Facture',
          intermediateInvoicePreviewAndPDF: 'Facture intermédiaire',
          depositInvoicePreviewAndPDF: 'Facture d\'acompte',
          balanceInvoicePreviewAndPDF: 'Facture de solde',
          commercialInvoicePreviewAndPDF: 'Facture', // #sales invoice
          correctiveInvoicePreviewAndPDF: 'Facture rectificative',
          creditNotePreviewAndPDF: 'Avoir',
          commercialCreditNotePreviewAndPDF: 'Avoir commercial',
          correctiveCreditNotePreviewAndPDF: 'Avoir rectificatif',
          quotePreviewAndPDF: 'Devis',
          purchaseOrderPreviewAndPDF: 'Bon de commande',
          documentPreviewAndPDF: 'Document',
          installmentInvoicePreviewAndPDF: 'Facture',
          commercialInstallmentInvoicePreviewAndPDF: 'Facture', // #sales invoice
          correctiveInstallmentInvoicePreviewAndPDF: 'Facture rectificative',
          installmentCreditNotePreviewAndPDF: 'Avoir',
          commercialInstallmentCreditNotePreviewAndPDF: 'Avoir commercial',
          correctiveInstallmentCreditNotePreviewAndPDF: 'Avoir rectificatif',
          installmentQuotePreviewAndPDF: 'Devis',
          installmentPurchaseOrderPreviewAndPDF: 'Bon de commande',
          installmentDocumentPreviewAndPDF: 'Document'
          // ------ with Partial mentioned ---------------------------
          // installmentInvoicePreviewAndPDF:              "Facture partielle",
          // commercialInstallmentInvoicePreviewAndPDF:    "Facture de vente partielle",
          // correctiveInstallmentInvoicePreviewAndPDF:    "Facture partielle rectificative",
          // installmentCreditNotePreviewAndPDF:           "Avoir partiel",
          // commercialInstallmentCreditNotePreviewAndPDF: "Avoir commercial partiel",
          // correctiveInstallmentCreditNotePreviewAndPDF: "Avoir rectificatif partiel",
          // installmentQuotePreviewAndPDF:                "Devis partiel",
          // installmentPurchaseOrderPreviewAndPDF:        "Bon de commande partiel",
          // installmentDocumentPreviewAndPDF:             "Document partiel",
          // ---------------------------------
        },
        businessDocumentKindWithPercentage: {
          depositInvoice: 'Acompte de {{percentage}}%',
          partialInvoice: 'Facture intermédiaire de {{percentage}}%',
          balanceInvoice: 'Solde de {{percentage}}%'
        },
        businessDocumentRelationKind: {
          // quoteInvoicing:             "Facture {{toNumber}} ({{toDocumentKindName}}) issue du devis {{fromNumber}} ({{fromDocumentKindName}})",
          // invoiceDuplication:         "Facture {{toNumber}} ({{toDocumentKindName}}) copie de la facture {{fromNumber}} ({{fromDocumentKindName}})",
          // invoiceVoiding:             "Avoir {{toNumber}} ({{toDocumentKindName}}) annulant la facture {{fromNumber}} ({{fromDocumentKindName}})",
          // creditNoteOnInvoice:        "Avoir {{toNumber}} ({{toDocumentKindName}}) sur la facture {{fromNumber}} ({{fromDocumentKindName}})",
          // invoiceOnCreditNote:        "Facture {{toNumber}} ({{toDocumentKindName}}) sur l'avoir {{fromNumber}} ({{fromDocumentKindName}})",
          // partialQuoteInvoicing:      "Facture partielle {{toNumber}} ({{toDocumentKindName}}) issue du devis {{fromNumber}} ({{fromDocumentKindName}})",
          // partialInvoiceDuplication:  "Facture {{toNumber}} ({{toDocumentKindName}}) copie partielle de la facture {{fromNumber}} ({{fromDocumentKindName}})",
          // partialInvoiceVoiding:      "Avoir {{toNumber}} ({{toDocumentKindName}}) annulant partiellement la facture {{fromNumber}} ({{fromDocumentKindName}})",
          // partialCreditNoteOnInvoice: "Avoir partiel {{toNumber}} ({{toDocumentKindName}}) sur la facture {{fromNumber}} ({{fromDocumentKindName}})",
          // partialInvoiceOnCreditNote: "Facture partielle {{toNumber}} ({{toDocumentKindName}}) sur l'avoir {{fromNumber}} ({{fromDocumentKindName}})",
          // other:                      "Document {{toNumber}} ({{toDocumentKindName}}) issu de {{fromNumber}} ({{fromDocumentKindName}})",
          commercialQuoteInvoicing: 'Facture {{toNumber}} issue du devis {{fromNumber}}', // #sales invoice
          correctiveQuoteInvoicing: 'Facture rectificative {{toNumber}} issue du devis {{fromNumber}}',
          quoteInvoicing: 'Facture {{toNumber}} issue du devis {{fromNumber}}',
          invoiceDuplication: 'Facture {{toNumber}} copie de la facture {{fromNumber}}',
          invoiceVoiding: 'Avoir {{toNumber}} annulant la facture {{fromNumber}} (erreur d\'implémentation)',
          commercialCreditNoteOnCommercialInvoice: 'Avoir commercial {{toNumber}} sur la facture {{fromNumber}}', // #sales invoice
          commercialCreditNoteOnCorrectiveInvoice: 'Avoir commercial {{toNumber}} sur la facture rectificative {{fromNumber}}',
          commercialCreditNoteOnInvoice: 'Avoir commercial {{toNumber}} sur la facture {{fromNumber}}',
          voidingCreditNoteOnCommercialInvoice: 'Avoir rectificatif {{toNumber}} sur la facture {{fromNumber}}', // #sales invoice
          voidingCreditNoteOnCorrectiveInvoice: 'Avoir rectificatif {{toNumber}} sur la facture rectificative {{fromNumber}}',
          voidingCreditNoteOnInvoice: 'Avoir rectificatif {{toNumber}} sur la facture {{fromNumber}}',
          creditNoteOnInvoice: 'AvoirA {{toNumber}} sur la facture {{fromNumber}}',
          commercialInvoiceOnCommercialCreditNote: 'Facture {{toNumber}} sur l\'avoir commercial {{fromNumber}}', // #sales invoice
          commercialInvoiceOnCorrectiveCreditNote: 'Facture {{toNumber}} sur l\'avoir rectificatif {{fromNumber}}', // #sales invoice
          commercialInvoiceOnCreditNote: 'Facture {{toNumber}} sur l\'avoir {{fromNumber}}', // #sales invoice
          correctiveInvoiceOnCommercialCreditNote: 'Facture rectificative {{toNumber}} sur l\'avoir commercial {{fromNumber}}',
          correctiveInvoiceOnCorrectiveCreditNote: 'Facture rectificative {{toNumber}} sur l\'avoir rectificatif {{fromNumber}}',
          correctiveInvoiceOnCreditNote: 'Facture rectificative {{toNumber}} sur l\'avoir {{fromNumber}}',
          invoiceOnCreditNote: 'Facture {{toNumber}} sur l\'avoir {{fromNumber}}',
          partialCommercialQuoteInvoicing: 'Facture {{toNumber}} issue du devis {{fromNumber}}', // #sales invoice
          partialCorrectiveQuoteInvoicing: 'Facture rectificative {{toNumber}} issue du devis {{fromNumber}}',
          partialQuoteInvoicing: 'Facture {{toNumber}} issue du devis {{fromNumber}}',
          partialInvoiceDuplication: 'Facture {{toNumber}} copie de la facture {{fromNumber}}',
          partialInvoiceVoiding: 'Avoir {{toNumber}} annulant partiellement la facture {{fromNumber}} (erreur d\'implémentation)',
          partialVoidingCreditNoteOnDepositCommercialInvoice: 'Avoir rectificatif {{toNumber}} sur la facture d\'acompte {{fromNumber}}',
          partialVoidingCreditNoteOnIntermediateCommercialInvoice: 'Avoir rectificatif {{toNumber}} sur la facture intermédiaire {{fromNumber}}',
          partialVoidingCreditNoteOnBalanceCommercialInvoice: 'Avoir rectificatif {{toNumber}} sur la facture de solde {{fromNumber}}',
          partialVoidingCreditNoteOnCommercialInvoice: 'Avoir rectificatif {{toNumber}} sur la facture {{fromNumber}}', // #sales invoice
          partialVoidingCreditNoteOnCorrectiveInvoice: 'Avoir rectificatif {{toNumber}} sur la facture corrective {{fromNumber}}',
          partialVoidingCreditNoteOnInvoice: 'Avoir rectificatif {{toNumber}} sur la facture {{fromNumber}}',
          partialCommercialCreditNoteOnCommercialInvoice: 'Avoir commercial {{toNumber}} sur la facture {{fromNumber}}', // #sales invoice
          partialCommercialCreditNoteOnCorrectiveInvoice: 'Avoir commercial {{toNumber}} sur la facture rectificative {{fromNumber}}',
          partialCommercialCreditNoteOnInvoice: 'Avoir commercial {{toNumber}} sur la facture {{fromNumber}}',
          partialCreditNoteOnInvoice: 'AvoirB {{toNumber}} sur la facture {{fromNumber}}',
          partialCommercialDepositInvoiceOnCommercialCreditNote: 'Facture d\'acompte {{toNumber}} suivant l\'avoir commercial {{fromNumber}}',
          partialCommercialIntermediateInvoiceOnCommercialCreditNote: 'Facture intermédiaire {{toNumber}} suivant l\'avoir commercial {{fromNumber}}',
          partialCommercialBalanceInvoiceOnCommercialCreditNote: 'Facture de solde {{toNumber}} suivant l\'avoir commercial {{fromNumber}}',
          partialCommercialInvoiceOnCommercialCreditNote: 'Facture {{toNumber}} sur l\'avoir commercial {{fromNumber}}', // #sales invoice
          partialCommercialDepositInvoiceOnCorrectiveCreditNote: 'Facture d\'acompte {{toNumber}} suivant l\'avoir rectificatif {{fromNumber}}',
          partialCommercialIntermediateInvoiceOnCorrectiveCreditNote: 'Facture intermédiaire {{toNumber}} suivant l\'avoir rectificatif {{fromNumber}}',
          partialCommercialBalanceInvoiceOnCorrectiveCreditNote: 'Facture de solde {{toNumber}} suivant l\'avoir rectificatif {{fromNumber}}',
          partialCommercialInvoiceOnCorrectiveCreditNote: 'Facture {{toNumber}} sur l\'avoir rectificatif {{fromNumber}}', // #sales invoice
          partialCommercialInvoiceOnCreditNote: 'Facture {{toNumber}} sur l\'avoir {{fromNumber}}', // #sales invoice
          partialCorrectiveInvoiceOnCommercialCreditNote: 'Facture rectificative {{toNumber}} sur l\'avoir commercial {{fromNumber}}',
          partialCorrectiveInvoiceOnCorrectiveCreditNote: 'Facture rectificative {{toNumber}} sur l\'avoir rectificatif {{fromNumber}}',
          partialCorrectiveInvoiceOnCreditNote: 'Facture rectificative {{toNumber}} sur l\'avoir {{fromNumber}}',
          partialInvoiceOnCreditNote: 'Facture {{toNumber}} sur l\'avoir {{fromNumber}}',
          other: 'Document {{toNumber}} ({{toDocumentKindName}}) issu de {{fromNumber}} ({{fromDocumentKindName}})',
          intermediateInvoiceAfterDepositInvoice: 'Facture intermédiaire {{toNumber}} suivant la facture d\'acompte {{fromNumber}}.',
          intermediateInvoiceAfterIntermediateInvoice: 'Facture intermédiaire {{toNumber}} suivant la facture intermédiaire {{fromNumber}}.',
          balanceInvoiceAfterIntermediateInvoice: 'Facture de solde {{toNumber}} suivant la facture intermédiaire {{fromNumber}}.',
          balanceInvoiceAfterDepositInvoice: 'Facture de solde {{toNumber}} suivant la facture d\'acompte {{fromNumber}}.',
          partialInvoiceOnInvoice: 'Facture {{toNumber}} issue de la facture {{fromNumber}}.'
          // ------ with Partial mentioned ---------------------------
          // commercialQuoteInvoicing:             "Facture de vente {{toNumber}} issue du devis {{fromNumber}}",
          // correctiveQuoteInvoicing:             "Facture rectificative {{toNumber}} issue du devis {{fromNumber}}",
          // quoteInvoicing:             "Facture {{toNumber}} issue du devis {{fromNumber}}",
          // invoiceDuplication:         "Facture {{toNumber}} copie de la facture {{fromNumber}}",
          // invoiceVoiding:             "Avoir {{toNumber}} annulant la facture {{fromNumber}} (erreur d'implémentation)",
          // commercialCreditNoteOnCommercialInvoice:        "Avoir commercial {{toNumber}} sur la facture de vente {{fromNumber}}",
          // commercialCreditNoteOnCorrectiveInvoice:        "Avoir commercial {{toNumber}} sur la facture rectificative {{fromNumber}}",
          // commercialCreditNoteOnInvoice:        "Avoir commercial {{toNumber}} sur la facture {{fromNumber}}",
          // voidingCreditNoteOnCommercialInvoice:        "Avoir rectificatif {{toNumber}} sur la facture de vente {{fromNumber}}",
          // voidingCreditNoteOnCorrectiveInvoice:        "Avoir rectificatif {{toNumber}} sur la facture rectificative {{fromNumber}}",
          // voidingCreditNoteOnInvoice:        "Avoir rectificatif {{toNumber}} sur la facture {{fromNumber}}",
          // creditNoteOnInvoice:        "Avoir {{toNumber}} sur la facture {{fromNumber}}",
          // commercialInvoiceOnCommercialCreditNote:        "Facture de vente {{toNumber}} sur l'avoir commercial {{fromNumber}}",
          // commercialInvoiceOnCorrectiveCreditNote:        "Facture de vente {{toNumber}} sur l'avoir rectificatif {{fromNumber}}",
          // commercialInvoiceOnCreditNote:        "Facture de vente {{toNumber}} sur l'avoir {{fromNumber}}",
          // correctiveInvoiceOnCommercialCreditNote:        "Facture rectificative {{toNumber}} sur l'avoir commercial {{fromNumber}}",
          // correctiveInvoiceOnCorrectiveCreditNote:        "Facture rectificative {{toNumber}} sur l'avoir rectificatif {{fromNumber}}",
          // correctiveInvoiceOnCreditNote:        "Facture rectificative {{toNumber}} sur l'avoir {{fromNumber}}",
          // invoiceOnCreditNote:        "Facture {{toNumber}} sur l'avoir {{fromNumber}}",
          // partialCommercialQuoteInvoicing:      "Facture de vente partielle {{toNumber}} issue du devis {{fromNumber}}",
          // partialCorrectiveQuoteInvoicing:      "Facture rectificative partielle {{toNumber}} issue du devis {{fromNumber}}",
          // partialQuoteInvoicing:      "Facture partielle {{toNumber}} issue du devis {{fromNumber}}",
          // partialInvoiceDuplication:  "Facture {{toNumber}} copie partielle de la facture {{fromNumber}}",
          // partialInvoiceVoiding:      "Avoir {{toNumber}} annulant partiellement la facture {{fromNumber}} (erreur d'implémentation)",
          // partialVoidingCreditNoteOnCommercialInvoice: "Avoir rectificatif partiel {{toNumber}} sur la facture de vente {{fromNumber}}",
          // partialVoidingCreditNoteOnCorrectiveInvoice: "Avoir rectificatif partiel {{toNumber}} sur la facture corrective {{fromNumber}}",
          // partialVoidingCreditNoteOnInvoice: "Avoir rectificatif partiel {{toNumber}} sur la facture {{fromNumber}}",
          // partialCommercialCreditNoteOnCommercialInvoice: "Avoir commercial partiel {{toNumber}} sur la facture de vente {{fromNumber}}",
          // partialCommercialCreditNoteOnCorrectiveInvoice: "Avoir commercial partiel {{toNumber}} sur la facture rectificative {{fromNumber}}",
          // partialCommercialCreditNoteOnInvoice: "Avoir commercial partiel {{toNumber}} sur la facture {{fromNumber}}",
          // partialCreditNoteOnInvoice: "Avoir partiel {{toNumber}} sur la facture {{fromNumber}}",
          // partialCommercialInvoiceOnCommercialCreditNote: "Facture de vente partielle {{toNumber}} sur l'avoir commercial {{fromNumber}}",
          // partialCommercialInvoiceOnCorrectiveCreditNote: "Facture de vente partielle {{toNumber}} sur l'avoir rectificatif {{fromNumber}}",
          // partialCommercialInvoiceOnCreditNote: "Facture de vente partielle {{toNumber}} sur l'avoir {{fromNumber}}",
          // partialCorrectiveInvoiceOnCommercialCreditNote: "Facture rectificative partielle {{toNumber}} sur l'avoir commercial {{fromNumber}}",
          // partialCorrectiveInvoiceOnCorrectiveCreditNote: "Facture rectificative partielle {{toNumber}} sur l'avoir rectificatif {{fromNumber}}",
          // partialCorrectiveInvoiceOnCreditNote: "Facture rectificative partielle {{toNumber}} sur l'avoir {{fromNumber}}",
          // partialInvoiceOnCreditNote: "Facture partielle {{toNumber}} sur l'avoir {{fromNumber}}",
          // other:                      "Document {{toNumber}} ({{toDocumentKindName}}) issu de {{fromNumber}} ({{fromDocumentKindName}})",
          // ---------------------------------
          // quoteInvoicing:                "Facture {{toNumber}} issue du devis {{fromNumber}}",
          // invoiceDuplication:            "Facture {{toNumber}} copie de la facture {{fromNumber}}",
          // invoiceVoiding:                "Avoir {{toNumber}} annulant la facture {{fromNumber}}",
          // creditNoteOnInvoice:           "Avoir {{toNumber}} sur la facture {{fromNumber}}",
          // invoiceOnCreditNote:        "Avoir {{toNumber}} sur l'avoir {{fromNumber}}",
          // partialQuoteInvoicing:         "Facture partielle {{toNumber}} issue du devis {{fromNumber}}",
          // partialInvoiceDuplication:     "Facture {{toNumber}} copie partielle de la facture {{fromNumber}}",
          // partialInvoiceVoiding:         "Avoir {{toNumber}} annulant partiellement la facture {{fromNumber}}",
          // partialCreditNoteOnInvoice:    "Avoir partiel {{toNumber}} sur la facture {{fromNumber}}",
          // partialInvoiceOnCreditNote: "Avoir partiel {{toNumber}} sur l'avoir {{fromNumber}}",
          // other:                         "Document {{toNumber}} issu de {{fromNumber}}",
        },
        kindName: {
          invoiceKindName: 'facture',
          creditNoteKindName: 'avoir', // #credit note invoice
          quoteKindName: 'devis',
          purchaseOrderKindName: 'bon de commande',
          documentKindName: 'document',
          installmentInvoiceKindName: 'facture partielle',
          installmentCreditNoteKindName: 'avoir partiel', // #credit note invoice
          installmentQuoteKindName: 'devis partiel',
          installmentPurchaseOrderKindName: 'bon de commande partiel',
          installmentDocumentKindName: 'document partiel'
        }
      },
      createCreditNote: {
        draftCreditNoteHeader: 'Modifiez le brouillon d\'avoir',
        creditNoteLabel: 'Avoir', // #credit note invoice
        creditNoteDetailsTitle: 'Détails de l\'avoir', // #credit note invoice
        automaticNumberingLabel: 'Numéro automatique de l\'avoir', // #credit note invoice
        newCreditNoteHeader: 'Nouvel avoir', // #credit note invoice
        creditNoteInformationWithRelatedDocuments: '<p class="text-s">Vous êtes en train de <strong>créer un avoir</strong> qui annulera la <strong>facture {{invoiceNumber}}</strong> précédemment établie au nom de votre client <strong>{{clientName}}</strong>.</p>', // #credit note invoice
        creditNoteInformation: '<p class="text-s">Vous êtes en train de <strong>créer un avoir commercial</strong>.\n' +
          'L\'avoir est notamment utile pour <strong>accorder un geste commercial</strong> à un client\n' +
          '(remise ou ristourne à valoir sur un achat prochain).'
      },
      resumeInvoicing: {
        resumeTitle: 'Reprenez votre facturation',
        resumeText: 'Cliquez sur une facture pour créer automatiquement une facture de solde ou une nouvelle facture intermédiaire.',
        buttonCancel: 'Annuler et revenir',
        buttonResume: 'Reprendre cette facturation'
      },
      cancelInvoice: {
        warn: 'Pour annuler définitivement la facture, il faut finaliser l\'avoir maintenant ou le brouillon ultérieurement'
      },
      createInvoice: {
        otherKinds: {
          None: 'Client destinataire identique',
          DeliveryCustomer: 'Client de livraison',
          BeneficiaryCustomer: 'Client bénéficiaire',
          SubcontractingCustomer: 'Client sous-traitant'
        },
        description: 'Description',
        generateIntermediateInvoices: {
          copy: 'Des <b>factures partielles</b> (acomptes, intermédiaires) ont été éditées pour ce client.<br> Reprenez votre facturation.',
          accessButtonLabel: 'Reprendre',
          resetDissociateBusinessDocument: 'Dissocier'
        },
        newInvoiceHeader: 'Nouvelle facture',
        draftInvoiceHeader: 'Modifiez le brouillon de facture',
        noClientYet: 'Pour créer une facture, commencez par ajouter un premier client. Vous pourrez alors le sélectionner pour que toutes ses informations remplissent automatiquement votre facture.',
        noContactYet: 'Contact client manquant',
        addLine: 'Ajouter un élément',
        itemHeader: 'Elément {{count}}',
        editClientFile: 'Editer la fiche client',
        clientLabel: 'Client',
        clientInvoiceLabel: 'Client :',
        noCompleteAddressCustomer: 'L\'adresse du client n\'est pas complète',
        accountInvoiceLabel: 'Emetteur :',
        editButton: 'Editer',
        addContact: 'Ajouter un contact',
        createContactForClient: 'Créer un contact pour ce client',
        chooseContactPlaceholder: 'Veuillez sélectionner un contact',
        createClient: 'Ajouter un nouveau client de facturation',
        chooseClientPlaceholder: 'Choisissez un client de facturation',
        changeClient: 'Choisir un autre client',
        invoiceDetailsTitle: 'Détails de la facture',
        itemsTitle: 'Prestations / Services',
        itemOptional: '(optionnel)',
        itemTitle: 'Titre',
        itemTitlePlaceholder: 'Votre Prestation / Service',
        itemDescription: 'Description',
        itemDescriptionPlaceholder: 'Indiquez la description de votre prestation ou service',
        itemSupplierReference: 'Référence fournisseur',
        itemSupplierReferencePlaceholder: 'Indiquez une référence produit ou service',
        unitPriceNoTaxes: 'Prix unitaire HT',
        installmentPercentage: 'Pourcentage du total',
        installmentAmountInclTaxes: 'Montant du paiement TTC',
        quantity: 'Quantité',
        vatRate: 'Taux TVA',
        designationLabel: 'Désignation',
        servicesLabel: 'Services',
        productsLabel: 'Produits',
        quantityLabel: 'Qté',
        unitTypeLabel: 'Unité',
        unitPriceNoTaxLabel: 'Prix Unit. HT',
        taxRateLabel: 'Taux TVA',
        totalPriceNoTaxLabel: 'Total HT',
        finalTotalPriceNoTaxLabel: 'Total (HT)',
        finalPriceTaxLabel: 'Total TVA',
        finalTotalPriceWithTaxLabel: 'Total TTC',
        totalInvoicedPriceNoTaxLabel: 'Montant HT',
        finalInvoicedPriceTaxLabel: 'TVA',
        finalInvoicedTotalPriceWithTaxLabel: 'Montant TTC',
        installmentDetailsTitle: 'Détail de facturation',
        detailsOfFirstInstallmentInvoice: 'Détail de la facture partielle',
        detailsOfFirstInstallmentCreditNote: 'Détail de l\'avoir partiel',
        detailsOfFirstInstallmentOtherBusinessDocument: 'Détail du document partiel',
        partialInstallmentDescriptionCreditNotePercentageOfAmount: 'Avoir partiel de {{calculatedPercentage}} %',
        partialInstallmentDescriptionInvoicePercentageOfAmount: 'Facture partielle de {{calculatedPercentage}} %',
        partialDepositInstallmentDescriptionInvoicePercentageOfAmount: 'Facture d\'acompte de {{calculatedPercentage}} %',
        partialIntermediateInstallmentDescriptionInvoicePercentageOfAmount: 'Facture intermédiaire de {{calculatedPercentage}} %',
        partialBalanceInstallmentDescriptionInvoicePercentageOfAmount: 'Facture de solde de {{calculatedPercentage}} %',
        partialInstallmentDescriptionDocumentKindPercentageOfAmount: 'Document partiel de {{calculatedPercentage}} %',
        partialInstallmentDescriptionCreditNoteAbsoluteAmount: 'Avoir partiel de {{absoluteValue}}',
        partialInstallmentDescriptionInvoiceAbsoluteAmount: 'Facture partielle de {{absoluteValue}}',
        partialDepositInstallmentDescriptionInvoiceAbsoluteAmount: 'Facture d\'acompte de {{absoluteValue}}',
        partialIntermediateInstallmentDescriptionInvoiceAbsoluteAmount: 'Facture intermédiaire de {{absoluteValue}}',
        partialBalanceInstallmentDescriptionInvoiceAbsoluteAmount: 'Facture de solde de {{absoluteValue}}',
        partialInstallmentDescriptionDocumentKindAbsoluteAmount: 'Document partiel de {{absoluteValue}}',
        partialInstallmentDescriptionDocumentKindChosenKind: 'Document partiel de {{partialValue}}',
        amountDueScaledValueLabel: 'Montant dû',
        amountOfInstallmentValueForInvoiceLabel: 'Montant facturé',
        amountOfInstallmentValueForCreditNoteLabel: 'Montant de l\'avoir',
        amountOfInstallmentValueForBusinessDocumentLabel: 'Montant partiel du document',
        amountToPayScaledValueLabel: 'Montant à régler',
        discountLabel: 'Remise',
        addDiscount: 'Appliquer une remise',
        emptyItemsState: 'Commencez par ajouter une nouvelle prestation / un nouveau service.',
        paymentTitle: 'Paiement',
        payOnlineLabel: 'Payer en ligne',
        paidInFullToggle: 'Facture payée en totalité',
        paidInFullToggleCopy: 'Cochez cette case si le client a déjà acquitté le montant dû de la facture.',
        paymentLink: 'Lien de paiement',
        paymentLinkCopy: 'Une fois activé, vos clients peuvent vous payer directement depuis un lien dédié.',
        activatePaymentNotice: 'Pour activer le lien de paiement, vous devez <a href="/settings/bank/view" class="text-cabaret" use:link>connecter votre banque à Dundy</a>.',
        sirenNumberWithDataLabel: 'N° SIREN : {{siren}}',
        sirenNumberLabel: 'N° SIREN',
        vatNumberWithDataLabel: 'N° de TVA : {{tva}}',
        vatNumberLabel: 'N° de TVA',
        installmentsTitle: 'Échéance de règlement',
        installmentsSubTitle: 'Créez un acompte ou définissez plusieurs échéances de règlements. Une facture de solde sera automatiquement créée à l\'état de brouillon.',
        installmentsConditions: 'Modalités de règlement',
        noInstallmentChoice: 'Totalité du montant',
        yesInstallmentChoice: 'Montant partiel',
        mustDefineAFinalCustomer: 'Il faut définir un client final pour le document.',
        aLineItemTitleIsMissing: 'Tous les articles doivent avoir une désignation / un titre.',
        switchToFullToSetTheFullAmount: 'Sélectionnez \'Totalité du montant\' pour passer d\'un montant partiel à un montant total.',
        aLineItemPriceIsMissing: 'Tous les articles doivent avoir un prix.',
        totalOfInvoiceShouldBePositive: 'Le montant global d\'une facture doit être positif. Le document peut cependant comporter des lignes négatives.',
        totalOfCreditNoteShouldBeNegative: 'Le montant global d\'un avoir doit être négatif. Le document peut cependant comporter des lignes positives.',
        partialAmountInvoiceTitle: 'Montant facturé',
        partialAmountCreditNoteTitle: 'Montant de l\'avoir',
        partialAmountAnyTitle: 'Montant applicable',
        paymentConditions: 'Conditions de paiement',
        showPenaltyText: 'Afficher les pénalités de retard et frais de recouvrements légaux',
        defaultFrenchPenaltyTerms: 'Pénalités de retard : trois fois le taux annuel d\'intérêt légal en vigueur calculé depuis la date d\'échéance jusqu\'à complet paiement du prix · Indemnité forfaitaire de 40 € pour frais de recouvrement conformément aux articles L 441-3 et L 441-6 du code de commerce · ',
        defaultFrenchNoDiscountEarlyBirds: 'Pas d’escompte en cas de paiement anticipé · ',
        recommendedNotice: 'Recommandé',
        showNoDiscountText: 'Pas d\'escompte pour paiement anticipé',
        addCustomTerms: 'Ajouter mes conditions générales de vente',
        paymentTermTitle: 'Conditions de paiement :',
        customTermsPlaceholder: 'Renseignez vos conditions de paiement personnalisées...',
        saveAsDraftButton: 'Enregistrer le brouillon',
        savingInProgress: 'Sauvegarde en cours...',
        saveAndTrackInvoiceButton: 'Finaliser & suivre la facture',
        saveAsCreditNoteButton: 'Finaliser l\'avoir', // #credit note invoice
        finalizingInvoiceInProgress: 'Création de la facture finale...',
        finalizingCreditNoteInProgress: 'Création de l\'avoir...', // #credit note invoice
        dateIssued: 'Date d\'émission',
        dateDue: 'Date d\'échéance',
        dueLabel: 'Échéance :',
        paymentInformation: 'Informations de paiement :',
        missingBankInformation: 'Certaines informations de votre compte en banque sont manquantes. Veuillez les compléter avant de valider votre facture.',
        missingTaxNumber: 'Numéro de TVA manquant',
        missingSIRENNumber: 'Numéro de SIREN manquant',
        missingLegalMention: 'Certaines informations de votre entreprise sont manquantes. Veuillez les compléter avant de valider votre facture.',
        beneficiaryCompanyName: 'Nom du Bénéficiaire',
        beneficiaryCompanyNameLabel: 'Nom du Bénéficiaire :',
        beneficiaryIBAN: 'IBAN',
        beneficiaryIBANLabel: 'IBAN :',
        beneficiaryBIC: 'BIC',
        beneficiaryBICLabel: 'BIC :',
        invoiceReference: 'Référence',
        invoiceReferenceLabel: 'Référence :',
        successfullySavedDraft: 'Brouillon de facture enregistré !',
        successfullySavedFinalInvoice: 'Facture finalisée !',
        successfullySavedFinalCreditNote: 'Avoir finalisée !', // #credit note invoice
        shareCapital: 'au capital social de {{socialCapital}}',
        poweredWithDundy: 'Document généré avec Dundy',
        errorSavingFinal: 'Oups, votre facture semble incomplète. Vérifiez que vous avez tout rempli et réessayez.',
        invoiceNotFinalized: 'Nous n\'avons pas pu finaliser votre facture. Veuillez rafraîchir la page et réessayer.',
        errorGeneratingPDF: 'Oups, nous n\'arrivons pas à générer votre facture. Veuillez réessayer.',
        linkedDocuments_one: 'Document lié',
        linkedDocuments_other: 'Documents liés',
        noLinkedDocumentFound: 'Aucun document lié n\'a été trouvé',
        issuedOn: 'Émise le',
        dueOn: 'Échue le',
        automaticNumberingLabel: 'Numéro automatique de facture',
        discountPercentageValueLabel: '%',
        discountEurValueLabel: 'EUR',
        recapThisDocumentPrefix: '(',
        recapThisDocumentSuffix: ')',
        recapThisDocument: 'ce document',
        issuedOnDateMentionPrefix: ' le ',
        paidInvoiceLabel: 'Facture acquittée',
        notEligibleToVAT: 'TVA non applicable, article 293 B du CGI',
        confirmPopin: {
          textInvoice: 'Vous êtes sur le point de finaliser cette facture. Cette action est définitive et ne pourra pas être modifiée. En cas d\'erreur, vous devrez générer un avoir pour annuler cette facture.<br><br>Voulez-vous vraiment continuer ?',
          textCreditNote: 'Vous êtes sur le point de finaliser cet avoir. Cette action est définitive et ne pourra pas être modifiée. En cas d\'erreur, vous devrez générer une facture pour annuler cet avoir.<br><br>Voulez-vous vraiment continuer ?',
          button: 'Finaliser'
        },
        buttons: {
          unlinkTooltip: 'Dissocier les documents',
          viewDocumentTooltip: 'Voir le document'
        },
        settings: {
          currencyTitle: 'Devise de la facture',
          currencyCopy: 'Choisissez la devise dans laquelle vous souhaitez émettre votre facture.'
        }
      },
      addViaOCR: {
        addByOCRTitle: 'Importez une facture par OCR',
        addByOCRCopy: 'Gérez vos factures au fil de l’eau en les envoyant à l\'adresse e-mail suivante depuis n\'importe quelle adresse e-mail. Elles seront alors automatiquement ajoutées et suivies par le système.',
        copyEmailButton: 'Copier l\'e-mail'
      },
      externalInvoice: {
        addInvoiceTitle: 'Ajouter une facture existante',
        addInvoiceCopy: 'Vous souhaitez importer une facture déjà créée dans un autre logiciel ou une facture papier ? Ajoutez-la ici.',
        editClientFile: 'Modifier la fiche client',
        changeClient: 'Changer',
        invoiceDetailsTitle: 'Détails de la facture',
        createClient: 'Ajouter un client',
        uploadInvoice: 'Téléchargez votre facture',
        noAttachment: 'Lorsque vous aurez téléchargé votre facture, elle apparaîtra ici.',
        successfullySavedDraft: 'Brouillon de pied de facture enregistré !',
        successfullySavedFinal: 'Facture finalisée !',
        deleteExternalInvoiceTitle: 'Confirmer la suppression ?',
        deleteExternalInvoiceBody: 'Souhaitez-vous vraiment supprimer cette facture ? Elle sera définitivement supprimée de nos serveurs. Cette action ne peut pas être annulée.',
        deleteConfirmButtonModal: 'Supprimer',
        deleteCancelButtonModal: 'Annuler'
      },
      editInvoice: {
        clientInformation: 'Commençons par votre client',
        selectCustomerPlaceholder: 'Choisir le client',
        saving: 'Enregistrement en cours...',
        chooseClient: 'Choisissez un client existant ou créez-en un nouveau à la volée.',
        createClient: 'Ajouter un client',
        issued: 'Émission :',
        due: 'Échéance',
        invoiceDetails: 'Informations de la facture',
        fillInformation: 'Renseignez les informations de votre facture.',
        currency: 'Devise',
        uploadInvoice: 'Téléchargez votre facture',
        acceptPDFs: 'Nous acceptons les PDFs.',
        sizeLimited: 'Taille limitée à 10 Mo.',
        savedInDraft: 'Brouillon sauvegardé',
        saveAsDraft: 'Enregistrer comme brouillon',
        saveAndTrack: 'Enregistrer et démarrer le suivi',
        cancel: 'Annuler',
        invoiceNotDraft: 'Les factures finalisées ne peuvent pas être modifiées.',
        invoiceNotFound: 'La facture que vous essayez de modifier n\'existe pas.',
        invoiceNotFinalized: 'Une erreur est survenue lors de l\'ouverture de cette facture. Veuillez rafraîchir la page et réessayer.'
      },
      viewInvoice: {
        buttons: {
          sendInvoiceButton: 'Envoyer la facture',
          dunningButton: 'Relancer la facture',
          copyButton: 'Dupliquer la facture',
          copyDundyButton: 'Dupliquer le pied de facture',
          voidButton: 'Annuler la facture',
          deleteButton: 'Supprimer le pied de facture',
          downloadButtonLabel: 'Télécharger la facture'
        },
        menu: {
          itemDetails: 'Détails',
          itemActivity: 'Activité',
          itemSettings: 'Paramètres',
          itemRelated_one: 'Document lié',
          itemRelated_other: 'Documents liés'
        },
        total: 'Total TTC',
        issued: 'Date d\'émission :',
        due: 'Date d\'échéance :',
        collectedTax: 'TVA collectée',
        leftToCollect: 'Reste à encaisser :',
        invoiceDetails: 'Détails de la facture',
        creditNoteDetails: 'Détails de l\'avoir',
        invoiceNumberLabel: 'Numéro de facture :',
        creditNoteLabel: 'Numéro d\'avoir :',
        invoice: 'Facture',
        invoiceNumber: 'Facture {{count}}',
        timeline: 'Activité',
        nextAction: 'Prochaine action pour cette facture',
        noAttachment: 'PDF de facture non disponible.',
        invoiceNotFinalized: 'Finalisez cette facture avant de pouvoir la consulter.',
        voidVoxyInvoiceBody: 'La facture étant finalisée, un brouillon d\'avoir du montant initial va être généré automatiquement. Souhaitez-vous continuer ?',
        voidVoxyInvoiceConfirmationTitle: '',
        voidVoxyInvoiceConfirmButtonModal: 'Continuer',
        voidVoxyInvoiceCancelButtonModal: 'Annuler'
      },
      form: {
        number: {
          label: 'Numéro de facture',
          error: 'Numéro non valide'
        },
        amount: {
          label: 'Montant',
          error: 'Pas un montant valide'
        },
        currency: {
          label: 'Devise',
          error: 'Ce n\'est pas une devise valide'
        },
        dateIssued: {
          label: 'Date d\'émission',
          error: 'Pas une date valide'
        },
        dateDue: {
          label: 'Date d\'échéance',
          error: 'Pas une date valide'
        }
      },
      errorDelete: 'Une erreur est survenue lors de l\'effacement',
      errorUpdate: 'Une erreur est survenue lors de la mise à jour',
      title: 'Factures',
      subtitleFull: {
        one: 'Vous avez ',
        two_one: ' facture en attente de paiement.',
        two_other: ' factures en attente de paiement.',
        three: ' Le montant total payable est de'
      },
      subtitleEmpty: {
        one: 'Il y a ',
        two: ' factures en attente de paiement.',
        three: ' Commencez par ajouter vos factures.'
      },
      delete: 'Effacer la facture',
      restore: 'Restaurer la facture',
      fetching: 'En attente des résultats',
      noResults: {
        title: 'Aucun résultat trouvé',
        one: 'Il n\'y a aucune facture correspondant à votre recherche pour ',
        two: 'Essayez de nouveau avec une rechercher différente'
      },
      autoImport: {
        howItWorks: 'Utilisez l\'adresse email dédiée pour importer automatiquement vos factures clients dans Dundy.',
        shoot: '<a href=\'mailto:{{customMailAddress}}\'>{{customMailAddress}}</a>'
      },
      note: {
        one: 'Trop de factures à remplir ?',
        two: ' Envoyez-nous vos fichiers (.csv, jpeg, .doc, .pdf) et nous les convertirons pour vous',
        shoot: 'Envoyez-nous un e-mail à work.for.me@dundy.co'
      },
      finalizeConfirmation: {
        text: 'Une fois finalisée, la facture ne peut plus être modifiée. Continuez?',
        yes: 'Oui',
        no: 'Non'
      },
      finalised: 'La facture est maintenant suivie.',
      draft: 'Le brouillon de facture a bien été enregistré.',
      deleted_one: 'Brouillon supprimé avec succès.',
      deleted_other: 'Brouillons supprimés avec succès.',
      paid_one: 'Facture enregistrée comme payée.',
      paid_other: 'Factures enregistrées comme payées.',
      unpaid_one: 'Facture enregistrée comme non payée.',
      unpaid_other: 'Factures enregistrées comme non payées.',
      landscapeNotice: 'En version BETA, veuillez utiliser le mode paysage sur cette page.',
      pagination: {
        label1: 'Affichage',
        label2: 'de',
        label3: 'sur',
        label4: 'factures'
      }
    },
    dunning: {
      pageTitle: 'Suivi des relances',
      tabs: {
        tracking: 'Suivies',
        late: 'En retard',
        paused: 'En pause',
        paid: 'Payées',
        draft: 'Brouillons'
      },
      visualisationHUD: {
        debtorsTitle: 'Poste débiteurs',
        outstandingAmountTitle: 'Poste clients',
        outstandingAmountSubtitle: 'en date du {{dateNow}}',
        dueAmountTitle: 'Dans les temps',
        dueAmountSubtitle: 'en date du {{dateNow}}',
        overdueAmountTitle: 'En retard',
        overdueAmountSubtitle: 'en date du {{dateNow}}'
      },
      actionMenu: {
        existingInvoice: 'Ajouter une facture existante',
        quickAddInvoiceExcerpt: 'Ajout rapide',
        importOCR: 'Importer une facture (OCR)'

      },
      controls: {
        pauseTracking: 'Arrêter le suivi',
        resumeTracking_one: 'Suivre la facture',
        resumeTracking_other: 'Suivre les factures'
      },
      feedback: {
        trackingIsResumed_one: 'Cette facture est maintenant suivie et sera relancée.',
        trackingIsResumed_other: 'Ces factures sont maintenant suives et seront relancées.',
        trackingIsPaused_one: 'Cette facture ne sera plus relancée. Vous pouvez la suivre à nouveau à tout moment.',
        trackingIsPaused_other: 'Ces factures ne seront plus relancées. Vous pouvez les suivre à nouveau à tout moment.'
      },
      columns: {
        client: 'Client',
        customerName: 'Nom du client',
        invoiceNumber: 'Facture',
        issuedDate: 'Émission',
        amount: 'Montant TTC',
        totalIncludingTaxes: 'Total TTC',
        installmentIncludingTaxes: 'Facturé TTC',
        amountIncludingTaxes: 'Montant(taxes inclues)',
        amountExcludingTaxes: 'Montant (hors taxes)',
        currency: 'Devise',
        vat: 'TVA',
        taxesPercent: 'Taxes %',
        taxesAmount: 'Total Taxes',
        dueDate: 'Échéance',
        status: 'Statut',
        followUp: 'Action requise / suivi'
      },
      filters: {
        filtersTitle: 'Filtres',
        filtersTitleActive_one: '{{count}} Filtre',
        filtersTitleActive_other: '{{count}} Filtres',
        clearAllFilters_one: 'Effacer le filtre',
        clearAllFilters_other: 'Effacer les filtres',
        closeFilters: 'Fermer les filtres',
        headerOnTime: 'Dans les temps',
        checkboxFilterBetween0And30DaysTillDue: 'Échue dans 0 à 30 jours',
        checkboxFilterBetween31And60DaysTillDue: 'Échue dans 31 à 60 jours',
        checkboxFilterBetween61And90DaysTillDue: 'Échue dans 61 à 90 jours',
        checkboxFilterMoreThan90TillDue: 'Échue dans plus de 90 jours',
        headerLate: 'En Retard',
        checkboxFilterBetween1And30DaysOverdue: 'Échue il y a 1 à 30 jours',
        checkboxFilterBetween31And60DaysOverdue: 'Échue il y a 31 à 60 jours',
        checkboxFilterBetween61And90DaysOverdue: 'Échue il y a 61 à 90 jours',
        checkboxFilterMoreThan90DaysOverdue: 'Échue il y a + de 90 jours',
        headerAmount: 'Montants',
        checkboxFilterAmountLessThan500: 'Moins de 500 {{currency}}',
        checkboxFilterAmountBetween500And5000: 'Entre 500 {{currency}} et 5 000 {{currency}}',
        checkboxFilterAmountBetween5000And20000: 'Entre 5 000 {{currency}} et 20 000 {{currency}}',
        checkboxFilterAmountBetween20000And75000: 'Entre 20 000 {{currency}} et 75 000 {{currency}}',
        checkboxFilterAmountBetween75000And150000: 'Entre 75 000 {{currency}} et 150 000 {{currency}}',
        checkboxFilterAmountMoreThan150000: 'Plus de 150 000 {{currency}}'


      },
      actionKind: {
        InvoiceIssued: 'Facture créée',
        InvoiceIssuance: 'Envoyer la facture',
        NoActionAtThisStage: 'Pas d\'action',
        CourtesyReminderShortlyBeforeDueDate: 'Rappel avant échéance',
        ReminderJustAfterDueDate: 'Rappel après échéance',
        ReminderShortlyAfterDueDate: '2ème rappel après échéance',
        DirectReminderSomeTimeAfterDueDate: 'Rappel direct après échéance',
        StrongLastReminderAfterDueDate: 'Addresser un recommandé',
        CollectionLitigation: 'Passer en contentieux',
        DisputeResolution: 'Litige à résoudre',
        CelebrateAndClose: 'Féliciter et fermer',
        CheckCashApplicationProposal: 'Vérifier le paiement',
        NothingAnymore: 'Plus rien à faire',
        ToBeDefined: 'A définir'
      },
      latestContact: 'Dernier contact :',
      paidAt: 'Payée le :'
    },
    onboarding: {
      back: ' Retour',
      previous: 'Revenir à l\'étape précédente',
      next: 'Continuer à l\'étape suivante',
      signIn: 'Me connecter',
      signOut: 'Me déconnecter',
      placeholders: {
        legalStructure: 'SASU - Simplified single-member joint stock company',
        role: 'Directeur Financier / CFO',
        industry: 'Hôtel-café-restaurant'
      },
      lists: {
        numberOfEmployees: '["0 (micro-entreprise)","1 à 9 employés","10 à 49 employés","50 à 499 employés","de 500 à 999 employés","+ de 1000 employés"]',
        industry: '["Hôtel-café-restaurant","Conseil / Marketing","BTP","Retail et e-commerce","Transports et automobile","Autres secteurs d’activité"]',
        role: {
          ceo: 'Dirigeant',
          cfo: 'Directeur Financier / CFO',
          accountant: 'Expert Comptable / Comptable',
          management: 'Contrôleur de gestion',
          administrative: 'Responsable Administratif',
          other: 'Autre'
        },
        invoicingSoftware: '["Axonaut","Cegid","Sage","Sellsy","Quickbooks","Pennylane","Excel / Word","A la main","Xero","Autre"]',
        numberOfInvoices: '["Moins de 10 factures","10 à 49 factures","50 à 99 factures","100 à 499 factures","Plus de 500 factures"]'
      },
      head: {
        progress: {
          step1: 'Votre compte',
          step2: 'Votre entreprise',
          step3: 'C\'est parti'
        }
      },
      start: {
        sidebar: {
          title: 'Faites-vous payer plus simplement & plus rapidement',
          subTitle: 'Avec un compte Dundy, vous avez :',
          bullet1: 'Le suivi illimité de vos factures clients',
          bullet2: 'Un portail de paiement avec 0 frais de transaction',
          bullet3: 'Un accompagnement de notre coach Dundy jusqu’à ce que vous soyez payé'
        },
        main: {
          title: 'Faisons connaissance pour commencer !',
          form: {
            firstName: 'Prénom',
            lastName: 'Nom',
            mandatory: 'Ce champ est obligatoire'
          }
        }
      },
      signup: {
        sidebar: {
          testimony: '« Dundy m\'a simplifié la vie et fait gagner un temps considérable pour mon suivi des factures. Je recommande ! »',
          name: 'Day-Mikes Assala',
          role: 'Gérant Globe&Colos',
          firstKPI: {
            title: '+6',
            description: 'Heures de gestion économisées'
          },
          secondKPI: {
            title: '+23%',
            description: 'Encaissements clients à échéance'
          },
          thirdKPI: {
            title: '100%',
            description: 'De bonheur à facturer et relancer'
          }
        },
        main: {
          title: 'Créons vos accès, maintenant.',
          note: 'Vos factures et règlements sont sensibles :) Pour les protéger, il faut votre adresse email professionelle ainsi qu’un mot de passe robuste.',
          form: {
            email: 'Email',
            password: 'Mot de passe',
            mandatory: 'Ce champ est obligatoire',
            terms1: 'En cliquant « Continuer », vous acceptez nos',
            terms2: 'Conditions d’utilisations',
            terms3: 'et notre',
            terms4: 'politique de confidentialité',
            passwordHint: 'Évitez les mots de passe déjà utilisés sur d’autres sites ou trop simples à deviner.'
          }
        }
      },
      confirmEmail: {
        sidebar: {
          title: 'Pourquoi valider votre email ?',
          text1: 'Bonne question !',
          text2: 'Si vous vous interrompez dans la création de votre compte, nous pourrons facilement vous remettre sur les rails, pardi !'
        },
        main: {
          title: 'Vous avez du courrier !',
          subTitle: 'Nous venons d’envoyer un email sur votre adresse',
          text1: 'Pour valider votre compte, cliquez sur le bouton',
          text2: '«',
          text3: 'Confirmer mon email',
          text4: '»',
          text5: 'ou copiez-collez le lien dans votre navigateur.',
          text6: 'Vous serez redirigé ici pour finir la création de votre compte.',
          text7: 'A tout de suite !'
        }
      },
      companyInfo: {
        sidebar: {
          title: 'Gagnez du temps tous les jours',
          text: 'Moins de gestion de vos factures, plus de temps pour votre business.'
        },
        search: {
          button: 'Rechercher',
          manualRegister: 'Vous n\'arrivez pas à trouver, saisssez manuellement vos informations',
          foundNone: 'Aucun résultat',
          foundOne: '1 résultat',
          foundMany: '{{n}} résultats',
          placeholder: 'Nom de la société ou code siren/siret',
          filter: 'Affinez votre recherche avec le code postal :',
          disclaimer: 'Pour le moment, Dundy s\'adresse uniquement aux entreprises domiciliées en France.',
          add: 'Ajouter une société manuellement',
          registrationPending: 'Votre société est en cours d\'immatriculation ?',
          or: 'où'
        },
        list: {
          noMore: 'Il n\'y a plus de résultat...'
        },
        edit: {
          choiceExisting: 'Entreprise existante',
          choicePending: 'En cours d\'immatriculation',
          registrationPending: 'Entreprise en cours d\'immatriculation ou aucun numéro sirène',
          notAvailable: 'Non renseigné',
          noSirenNumber: 'Aucun numéro SIREN',
          noVatNumber: 'Aucun numéro de TVA',
          title: 'Passez en revue la fiche de votre entreprise et modifiez-la si nécessaire.',
          titleNew: 'Renseignez les informations de votre entreprise.',
          formalName: 'Raison sociale',
          usualName: 'Nom usuel (facultatif)',
          naf: 'Activité (Code NAF ou APE)',
          nafError: 'Le format doit être de la forme 00.00Z',
          siren: 'SIREN',
          siret: 'SIRET',
          vatInfo: 'Pour se conformer à la législation en vigueur, les sociétés domiciliées dans l\'Union Européenne doivent faire figurer leur numéro de TVA intracommunautaire sur leurs factures et documents commerciaux.',
          vat: 'Numéro de TVA intracommunautaire',
          shareCapital: 'Capital social',
          legalStructure: 'Structure légale',
          street: 'Rue',
          extraAddressLine: 'Complément d\'addresse',
          zipCode: 'Code Postal',
          city: 'Ville',
          state: 'Etat',
          missValue: 'information manquante',
          miss: 'Renseignez les informations manquantes en utilisant le bouton ci-dessous.',
          incorrect: 'Les informations ne sont pas correctes ?', 
          edit: 'Editer les informations',
          cancel: 'Annuler',
          validate: 'Enregistrer les informations',
          errMinSize: 'La longueur doit être d\'au moins {{n}} caractères',
          errMinValue: 'La valeur doit être d\'au moins {{v}}',
          errAPE: 'Code composé de 4 chiffres suivis d\'une lettre',
          errSIRET: 'Code composé de 14 chiffres',
          errVAT: 'Code composé du code FR suivi de 11 chiffres',
          errRequired: 'Le champ ne peut être vide'
        },
        main: {
          title: 'Recherchez et sélectionnez votre entreprise.',
          form: {
            country: 'Dans quel pays est domiciliée votre entreprise ?\n',
            countryNote: 'Pour le moment, Dundy s’adresse uniquement aux entreprises domiciliées en France.',
            companyName: 'Nom de l’entreprise\n',
            balloon: {
              text1: 'Vous pouvez le trouver facilement sur',
              text2: '(recherche par le nom et la localité) ou sur les documents officiels de votre entreprise.'
            },
            legalIdentification: 'SIREN',
            legalIdentificationNote: 'C’est le numéro d’identification à 9 chiffres de votre société. Le SIRET à 14 chiffres est également accepté.',
            mandatory: 'Ce champ est obligatoire'
          }
        }
      },
      companyInfoPlus: {
        sidebar: {
          title: 'Aucuns frais cachés',
          text: 'Vous ne payez pas d’abonnement pour Dundy et les virements de vos clients sont gratuits. Pour de vrai !'
        },
        main: {
          title: 'Dites-nous en plus sur votre rôle au sein de ',
          form: {
            street: 'Siège social de l’entreprise',
            postCode: 'Code postal',
            city: 'Ville',
            role: 'Quel est votre rôle au sein de {{company}} ?',
            phoneNumber: 'Sur quel numéro pouvons-nous vous joindre ?',
            phoneNumberPlaceholder: '00 00 00 00 00',
            customRole: 'Saisissez votre titre officiel',
            activity: 'Quel est le secteur d’activité de {{company}} ?',
            legalIdentification: 'SIREN',
            legalIdentificationNote: 'C’est le numéro d’identification à 9 chiffres de votre société. Le SIRET à 14 chiffres est également accepté.',
            mandatory: 'Ce champ est obligatoire',
            back: 'Retour'
          }
        }
      },
      companyInfoFinal: {
        sidebar: {
          title: 'Faites-vous payer plus vite',
          text: 'Découvrez la tranquilité d’être payé à temps.'
        },
        main: {
          title: 'Dernière ligne droite !',
          note: 'Quelques questions complémentaires pour nous aider à configurer votre compte Dundy.',
          form: {
            monthlyInvoices: 'Combien de factures émettez-vous par mois ?',
            invoicingSoftware: 'Quel logiciel de facturation utilisez-vous ?',
            customInvoicingSoftware: 'Saisissez le nom de votre logiciel',
            numberOfEmployees: 'Combien d\'employés compte {{company}} ?',
            mandatory: 'Ce champ est obligatoire',
            back: 'Retour'
          }
        }
      },
      appIntro: {
        sidebar: {
          title: 'Dans quelques secondes, vous allez pouvoir :',
          bullet1: 'Ajouter votre première facture sur Dundy',
          bullet2: 'Accéder aux étapes de relance pour suivre le traitement de cette facture',
          bullet3: 'Envoyer un lien de paiement à votre client pour faire payer par un virement gratuit pour vous et votre client !'
        },
        main: {
          title: 'Tout est prêt pour commencer à utiliser Dundy',
          button: 'Accéder au tableau de bord'
        }
      }
    },
    sidebar: {
      sections: {
        driveBusiness: 'Piloter mon activité',
        getPaid: 'Se faire payer',
        manageStructure: 'Gérer ma structure'
      }
    },
    settings: {
      title: 'Réglages',
      invoicing_app: {
        title: 'Paramètres de l\'Application',
        subtitle: 'Paramétrez et personnalisez Dundy pour commencer à éditer vos devis, factures et relances clients.'
      },
      profile: {
        title: 'Réglages de profil',
        subtitle: 'Editez vos informations personnelles ou changez votre mot de passe.'
      },
      company: {
        title: 'Profil de la société',
        subtitle: 'Editez les informations légales de votre entreprise.'
      },
      bank: {
        title: 'Comptes bancaires',
        subtitle: 'Gérez les accès à votre compte bancaire d\'entreprise.'
      },
      uploadLogo: {
        uploadingLogo: 'Téléchargement du logo...'
      }
    },
    team: {
      title: 'Equipe',
      manage: {
        title: 'Membres de l\'équipe et contacts',
        add: 'Ajouter Membre ou Contact'
      },
      error: 'Une erreur est survenue lors de la récupération des membres de l\'équipe',
      editMember: {
        cancel: 'Annuler',
        save: 'Enregistrer les changements',
        deleteContactModalTitle: 'Confirmer la suppression ?',
        deleteContactModalCaption1: 'Souhaitez-vous vraiment supprimer ce contact ?\n' +
          'Toutes les données seront définitivement supprimées de nos serveurs. Cette\n' +
          'action ne peut pas être annulée.',
        deleteConfirmButtonModal: 'Supprimer',
        deleteCancelButtonModal: 'Annuler',
        deleteFeedback: 'Le contact a bien été supprimé.'
      },
      addMember: {
        title: 'Ajouter un membre d\'équipe',
        cancel: 'Annuler',
        save: 'Ajouter'
      },
      inviteMember: {
        loading: 'Envoi de l\'invitation en cours...',
        alert: {
          header: 'Une invitation',
          message: 'pour rejoindre l\'espace de travail a bien été envoyée à '
        },
        invite: 'Inviter un membre d\'équipe',
        error: {
          one: 'Quelque chose n\'a pas fonctionné de notre côté. Merci de nous envoyer un email à ',
          two: 'help@dundy.co',
          three: ' pour que nous puissions inviter votre collaborateur.'
        }
      },
      listMembers: {
        header: {
          name: 'Nom',
          title: 'Fonction',
          status: 'Statut du compte',
          role: 'Rôle'
        },
        content: {
          youLabel: '(Vous)',
          status: {
            active: 'Actif',
            inactive: 'Inactif'
          }
        },
        actions: {
          editSelf: 'Editer mon profil',
          delete: 'Supprimer le membre',
          edit: 'Editer le membre'
        }
      }
    },
    addCustomer: {
      error: 'Error adding client',
      title: 'Add new client',
      cancel: 'Cancel',
      save: 'Save client',
      missingInfoTitle: 'Oups, il manque des infos :)'
    },
    editCustomer: {
      error: 'Mise à jour a échoué',
      title: 'Editer la fiche client',
      cancel: 'Annuler',
      save: 'Sauvegarder les modifications',
      next: 'Ajouter un contact',
      companyLinkTitle: 'Informations de l\'entreprise',
      contactsLinkTitle: 'Contacts',
      newCompanyCaption: 'Commencez par renseigner les coordonnées de l\'entreprise que vous créez.',
      existingCompanyCaption: 'Modifiez les informations sur l\'entreprise.',
      contactsCaption: 'Gérez les noms et les informations de vos contacts.',
      backLink: 'Retour à la liste des clients',
      loading: 'Actif...',
      saveContact: 'Enregistrer le contact',
      deleteCustomer: 'Supprimer le client',
      deleteCustomerTitle: 'Supprimer ce client',
      deleteCustomerCaption1: 'Une fois que vous supprimez ce client, il n\'y a plus de retour en arrière possible.',
      deleteCustomerCaption2: 'Soyez prudent.',
      deleteCustomerModalTitle: 'Confirmer la suppression ?',
      deleteCustomerModalCaption1: 'Souhaitez-vous vraiment supprimer ce client ?\n' +
        'Toutes les données seront définitivement supprimées de nos serveurs. Cette\n' +
        'action ne peut pas être annulée.',
      deleteConfirmButtonModal: 'Supprimer',
      deleteCancelButtonModal: 'Annuler',
      deleteFeedback: 'Le client a bien été supprimé.',
      contactAdded: 'Le nouveau contact est enregistré.',
      contactUpdated: 'Les informations du contact ont été enregistrées.',
      customerAdded: 'Le client a bien été ajouté.',
      customerUpdated: 'Les informations du client ont été mises à jour.'
    },
    customerOverview: {
      backLink: 'Retour à la liste des clients',
      previousPayment: 'Paiement précédent',
      nextPayment: 'Prochain paiement',
      averagePayDay: 'Jour de paie moyen',
      overdue: 'En retard',
      onTime: 'Dans les temps',
      paid_one: 'Payée',
      paid_other: 'Payées',
      untracked_one: 'Non suivie',
      untracked_other: 'Non suivies',
      latestContact: 'Dernier échange',
      upcomingTasks: 'Tâches à venir',
      edit: 'Editer ce client',
      viewInvoicesLink: 'Afficher les factures',
      timelineTitle: 'Timeline',
      contactLabel_one: 'Votre contact',
      contactLabel_other: 'Vos contacts',
      otherContactLabel_one: 'et {{count}} autre contact',
      otherContactLabel_other: 'et {{count}} autres contacts',
      upcomingAction: 'Relance à venir',
      invoiceNumber: 'Facture #',
      dueDate: 'Échéance',
      dunningStep: 'Étape de relance',
      status: 'Statut',
      amount: 'Total TTC',
      selectTab: 'Sélectionnez un onglet',
      addContact: 'Ajouter un contact'
    },
    customerList: {
      title: 'Clients',
      countDescription_one: '{{count}} client',
      countDescription_more: '{{count}} clients',
      empty: 'Commencez par ajouter un nouveau client en cliquant sur le bouton "Nouveau Client"',
      search: 'Recherche d\'entreprises par nom ou contact',
      add: 'Nouveau Client',
      edit: 'Editer le client',
      delete: 'Supprimer le client',
      table: {
        name: 'Nom',
        type: 'Type',
        overdue: 'En retard',
        onTime: 'Dans les temps',
        actions: 'Actions',
        french: 'Société Française',
        inEU: 'Société Européenne',
        noEU: 'Société non Européenne',
        privateIndividual: ' Client Particulier'
      },
      modal: {
        deleteHeading: 'Confirmer la suppression ?',
        deletePrimaryButton: 'Supprimer',
        deleteSecondaryButton: 'Annuler',
        deleteInfo: 'Souhaitez-vous vraiment supprimer ce client ? Toutes les données seront définitivement supprimées de nos serveurs. Cette action ne peut pas être annulée.'
      },
      feedback: {
        delete: 'Le client a été supprimé'
      }
    },
    customerManage: {
      page: {
        newClient: 'Nouveau client',
        editClient: 'Edition Client'
      },
      form: {
        templatesTitle: 'Préférences de relance',
        templatesLanguage: 'Langue de communication',
        templatesEdition: 'Edition des modèles de relance',
        contactsTitle: 'Contacts',
        selectClientType: 'Sélectionnez un type de client :',
        frenchPrivateCompany: 'Société Française',
        frenchPrivateCompanyCopy: 'Client professionnel dont le siège social est situé en France',
        foreignPrivateCompany: 'Société Etrangère',
        foreignPrivateCompanyCopy: 'Client professionnel dont le siège social est situé hors de France',
        individual: 'Particulier',
        individualCopy: 'Personne physique domiciliée en France ou à l\'étranger',
        individualClient: 'Client particulier',
        professionalClient: 'Client professionnel',
        saveClient: 'Enregistrer',
        deleteClient: 'Supprimer ce client',
        deleteContact: 'Supprimer ce contact',
        address: 'Adresse',
        asideNoteFrenchCompany: 'Pour ajouter une entreprise française, <strong>saisissez sa\n' +
                                  '        dénomination légale</strong>. Dundy récupère automatiquement la <strong>fiche de l\'entreprise</strong> depuis\n' +
                                  '        les données officielles de l\'INSEE et l\'INPI.',
        asideNoteOther: 'Saisissez les <strong>informations de votre client</strong> en\n' +
                          '        utilisant le formulaire prévu à cet effet. Vérifiez <strong>les données</strong> que vous saisissez, car\n' +
                          '        celles-ci <strong>seront utilisées pour générer les factures et effectuer les relances clients</strong>.'
      },
      modal: {
        customerDeleteInfo: 'Souhaitez-vous vraiment supprimer ce client ? Toutes les données seront définitivement supprimées de nos serveurs. Cette action ne peut pas être annulée.',
        contactDeleteInfo: 'Souhaitez-vous vraiment supprimer ce contact ? Toutes les données seront définitivement supprimées de nos serveurs. Cette action ne peut pas être annulée.'
      },
      feedback: {
        customerDelete: 'Le client à été supprimé',
        contactDelete: 'Le contact à été supprimé',
        customerDeleteError: 'Le client n\'à pas pu être supprimé',
        contactDeleteError: 'Le contact n\'à pas pu être supprimé'
      }
    },
    listCustomers: {
      error: 'Une erreur est survenue lors de l\'effacement du client',
      columns: {
        client: 'Client',
        numberOfInvoices: '# de factures',
        outstandingAmount: 'Montant Attendu',
        accountStatus: 'Position du Compte'
      },
      title: 'Clients',
      add: 'Nouveau client',
      delete: 'Effacer client',
      search: {
        placeholder: 'Recherche d\'entreprises par nom ou contact'
      },
      pagination: {
        label1: 'Affichage',
        label2: 'à',
        label3: 'de',
        label4: 'clients'
      },
      due: 'émis',
      overdue: 'en retard',
      empty: {
        title: 'Pas encore de clients',
        first_paragraph: 'Commencez par créer un nouveau client.'
      }
    },
    listContacts: {
      deleteConfirmation: 'Êtes-vous certain de vouloir supprimer',
      delete: 'Supprimer',
      cancel: 'Annuler',
      emptyText: 'Commencez par créer un contact en cliquant sur le bouton « Ajouter un contact ».',
      addContactText: 'Créez un contact en cliquant sur le bouton ci-dessous :'
    },
    companyInfo: {
      legalIdPlaceholder: 'Numéro légal d\'identification',
      errorContinue: 'La requête n\'a pas aboutie.',
      title: 'Dites-nous en un peu plus sur la société pour laquelle vous travaillez',
      company: 'Nom de la société',
      address: 'Adresse',
      city: 'Ville',
      zipCode: 'Code Postal',
      country: 'Pays',
      fillLegalId: 'Saisissez le numéro d\'identification de votre société',
      continue: 'Continuez'
    },
    companyLegalIdentification: {
      europeanVAT: 'Numéro de TVA Intracommunautaire',
      duns: 'Numéro DUNS',
      siren: 'Numéro de SIREN/SIRET',
      sirenSiret: 'SIREN/SIRET'
    },
    confirmEmail: {
      title: 'Vérifiez votre boite mail !',
      sentEmail: 'Nous venons de vous envoyer un mail de confirmation à l\'instant.',
      validate: {
        one: 'Merci de valider votre email en cliquant sur le bouton «',
        two: ' Confirmez mon email ',
        three: '» ou en utilisant le lien.'
      },
      bringBack: 'Cette action vous ramenera ici-même pour finir votre onboarding.',
      seeYou: 'A tout de suite !'
    },
    connectBank: {
      title: 'Maintenant, connectons votre compte en banque',
      why: {
        title: 'Pourquoi connecter mon compte ?',
        one: 'Pour faire fonctionner Dundy, nous utilisons ',
        two: 'un accès en lecture seule',
        three: ' pour surveiller les paiements que les clients vous envoient.'
      },
      secure: {
        title: 'Est-ce sécurisé ?',
        one: 'Nous ',
        two: ' ne captons ni n\'enregistrons ',
        three:
          ' vos données de connexion. La connexion à votre compte transite par notre partenaire avec ',
        four: ' un cryptage AES-256.'
      },
      note: 'Notre système a été conçu dès le départ pour garantir la sécurité de vos informations. La sécurité est notre priorité absolue.',
      learnMore: 'EN SAVOIR PLUS',
      continue: 'Continuer'
    },
    registerType: {
      alert: {
        header: 'Votre adresse email est validée.',
        message: 'Dans 1 minute, vous utiliserez Dundy!'
      },
      error: 'La mise à jour a échoué.',
      title:
        'Vous inscrivez-vous pour votre propre entreprise ou celle pour laquelle vous travaillez ?',
      boss: 'Ma société / Moi-même',
      employee: 'La société pour laquelle je travaille',
      continue: 'Continuer'
    },
    selectAccount: {
      error: 'La sélection a échoué.',
      submit: 'Enregistrer ma sélection',
      title: 'Sélectionnez votre compte',
      note: 'Notre système a été conçu dès le départ pour garantir la sécurité de vos informations. La sécurité est notre priorité absolue.',
      learnMore: 'EN SAVOIR PLUS',
      continue: 'Continuer',
      noResults: 'Il n\'y a pas de comptes sélectionnables.',
      bankCaption1: 'Vous êtes connecté(e) à votre banque "{{bankName}}".',
      chooseText: 'Choisissez le compte bancaire sur lequel vous payent les clients :'
    },
    start: {
      title: {
        one: 'On  y va !',
        two: 'Quelques questions rapides et nous allons créer votre compte'
      },
      firstName: {
        placeholder: 'Prénom',
        error: 'Votre prénom n\'est pas conforme à nos règles de validation'
      },
      lastName: {
        placeholder: 'Nom',
        error: 'Votre nom n\'est pas conforme à nos règles de validation'
      },
      continue: 'Continue'
    },
    addBank: {
      info: {
        one: 'Le paiement est de retour !',
        two: 'Vous pouvez désormais vous faire payer en un clic gratuitement.',
        three: 'Configurer maintenant'
      }
    },
    bankConnectionModal: {
      title: 'Ajouter un compte en banque',
      title1: 'Comment ça marche ?',
      title2: 'Est-ce sécurisé ?',
      bullet1: {
        text1: 'Nous allons',
        text2: 'vous rediriger vers notre partenaire',
        text3: 'Open-Banking, Bridge.'
      },
      bullet2: {
        text1: 'Vous',
        text2: 'n’aurez qu’à suivre les étapes',
        text3: 'afin de connecter votre compte en banque.'
      },
      bullet3: {
        text1: 'Lorsque le processus sera complété, vous',
        text2: 'reviendrez automatiquement sur votre tableau de bord.'
      },
      bullet4: {
        text1: 'Nous n\'avons accès <strong>qu\'aux règlements</strong> que vos clients vous addressent. Nous <strong>ne pouvons pas effectuer</strong> d\'opérations sur votre compte !'
      },
      bullet5: {
        text1: '<strong>Aucune modification de permissions</strong> ne peut être effectuée sans votre <strong>consentement explicite</strong>.'
      },
      bullet6: {
        text1: 'Nous n\'avons <strong>jamais connaissance</strong> de vos identifiants bancaires.'
      },
      bullet7: {
        text1: 'La connexion à votre compte est totalement prise en charge par notre partenaire Bridge à travers une <strong>encryption sécurisée</strong>.'
      },
      submit: 'Connecter mon compte en banque'
    },
    editBank: {
      title: 'Compte bancaire',
      subtitle_one: 'Vous avez {{count}} compte bancaire connecté.',
      subtitle_other: 'Vous avez {{count}} comptes bancaires connectés.',
      switchAccount: 'Changer de compte',
      addBankAccount: 'Nouveau compte',
      activeBank: 'Actif',
      deleteBankAccount: 'Supprimer le compte bancaire',
      detailsSaved: 'Coordonnées bancaires enregistrées avec succès',
      clear: 'Effacer',
      cancel: 'Annuler',
      save: 'Enregistrer',
      loadingData: 'Chargement des données bancaires...',
      copyButton: 'Copier',
      incompleteDetails: 'Veuillez fournir tous les détails du compte.',
      connectionRemoved: 'Connexion bancaire supprimée.',
      warning: {
        one: 'Action requise!',
        two: ' Connectez votre compte bancaire pour continuer à profiter de Dundy'
      },
      connected: {
        title: 'Vous êtes connecté à votre compte {{accountNumber}} en banque ',
        header:
          'Si vous souhaitez changer de compte bancaire, suivez les étapes ci-dessous :',
        clickConnect:
          'Cliquez sur « Ajouter un nouveau compte en banque » pour autoriser votre nouveau compte bancaire.',
        connect: 'Ajouter un nouveau compte en banque'
      },
      disconnected: {
        title: 'Vous n\'êtes plus connecté à votre compte en banque',
        header:
          'En raison du fonctionnement de notre partenaire OpenBanking, nous devons parfois vous demander de vous reconnecter à votre compte. Nous sommes conscients de ce désagrément que nous travaillons à résoudre avec un système plus fiable.',
        clickConnect:
          'Cliquez sur « Me connecter à ma banque » pour autoriser la connexion à votre compte bancaire.',
        connect: 'Me connecter à ma banque'
      },
      intro: 'La connexion de votre compte bancaire nous permet de suivre vos paiements. Choisissez l\'une des options ci-dessous.',
      connect: {
        title: 'Ajouter un nouveau compte bancaire',
        description: 'Connectez-vous à votre nouvelle banque et sélectionnez le compte à partir duquel vous recevrez les paiements.'
      },
      select: {
        title: 'Sélectionnez votre compte',
        description: 'Passez d\'un compte à l\'autre pour sélectionner votre compte principal.'
      },
      dontStore1: 'Nous ne visualisons ni ne stockons vos informations d\'identification.',
      dontStore2: 'Toutes les informations transitent par nos partenaires OpenBanking avec un cryptage AES-256 bits.',
      note: 'Notre système a été conçu pour garantir la sécurité de vos informations. La sécurité de vos données est notre priorité absolue.',
      learnMore: 'EN SAVOIR PLUS',
      whyConnectTitle: 'Pourquoi connecter votre compte en banque ?',
      whyConnectBullet1: 'Profitez du remplissage automatique de vos informations bancaires.',
      whyConnectBullet2: 'Disposez d’un lien de paiement pour vous faire payer rapidement et gratuitement !',
      whyConnectBullet3: 'Découvrez le rapprochement bancaire automatisé entre vos factures ouvertes et vos transactions entrantes',
      whyConnectButton: 'Connecter mon compte bancaire',
      privacyNotice: 'Nous ne <strong>voyons pas vos identifiants</strong> et nous ne <strong>revendons pas vos données</strong>. Tout est sécurisé par notre prestataire Bridge for Bankin.',
      privacyLink: 'Plus d\'info',
      orManualOption: 'OU',
      manualOptionTitle: 'Saisissez manuellement les informations de votre compte bancaire.',
      manualOptionSubtitle: 'Ces informations seront utilisées par vos clients qui souhaitent vous régler par virement manuellement.',
      bankAccountName: 'Nom du compte',
      bankName: 'Banque :',
      bankNameLabel: 'Nom de la banque',
      bankNamePlaceholder: 'Nom de la banque',
      bankIbanNumber: 'IBAN :',
      bankIbanNumberLabel: 'Numéro d\'IBAN',
      bankIbanPlaceholder: 'FR760000000000000',
      bankBIC: 'BIC :',
      bankBICLabel: 'Code BIC',
      bankBICPlaceholder: 'XXXXX',
      addBICCode: 'Veuillez saisir le code BIC correspondant à votre banque.',
      addIBAN: 'Veuillez saisir l\'IBAN correspondant à votre compte bancaire.',
      addBankName: 'Veuillez saisir le nom de votre banque.',
      headingBankDetails: 'Coordonnées de votre compte bancaire',
      subHeadingBankDetails: 'Les informations  ci-dessous seront communiquées à vos clients qui souhaitent règler par virement bancaire depuis leur propre interface bancaire (en virement manuel). ',
      bankDetailsNotice: 'Vérifiez bien les informations afin d’éviter les erreurs de saisie. Vos clients utiliseront ces coordonnées bancaires pour vous payer par virement manuel.',
      removeBankConnectionConfirmation: {
        deleteAccountModalTitle: 'Supprimer le compte bancaire ?',
        deleteConfirmButtonModal: 'Supprimer',
        deleteCancelButtonModal: 'Annuler',
        deleteAccountModalCaption1: 'Souhaitez-vous vraiment supprimer la connexion à votre compte bancaire ? Vous devrez l\'ajouter à nouveau pour profiter des fonctionnalités de lettrage.'
      }
    },
    invoicingAppEdit: {
      title: 'Réglages de l\'application',
      titleCopy: 'Retrouvez ici les réglages pour la création de vos documents et les relances clients.',
      automaticNumbering: {
        title: 'Numérotation automatique',
        description: 'Activez la numérotation automatique pour les documents envoyés à vos clients : devis, factures, avoirs, etc.',
        invoiceNumberingTitle: 'Numérotation des Factures',
        rfpNumberingTitle: 'Numérotation des Devis',
        creditNoteNumberingTitle: 'Numérotation des Avoirs',
        modifyButtonLabel: 'Modifier'
      },
      invoiceBackground: {
        title: 'Personnalisation de facture',
        background: 'Image de fond',
        text: `La facture générée en PDF aura ce visuel. Cliquer sur la facture pour ajouter une image personnalisée en trame de fond ou sur le bouton.<br><br>
        Conditions d'acceptation du fichier :<br>
        - Taille : {{size}} Mo maximum<br>
        - Formats : {{ext}}`,
        various: 'Divers',
        logo: 'Afficher le logo de l\'entreprise',
        opacity: 'Opacité du fond : {{opacity}}%',
        margin: 'Marges',
        marginHeader: 'Entête : {{margin}}px',
        marginFooter: 'Pied de page : {{margin}}px',
        colors: 'Couleurs',
        colorHeader: 'Entête : {{color}}',
        colorFooter: 'Footer : {{color}}',
        buttonChoose: 'Choisir une image',
        buttonSave: 'Enregistrer',
        buttonDelete: 'Supprimer l\'image',
        errorLoadPreference: 'Erreur de chargement des préférences de facture'
      }
    },
    companyEdit: {
      title: 'Modifier les informations de l\'entreprise',
      titleCopy: 'Vérifiez et éditez les informations de votre société ci-dessous.',
      error: 'La sauvegarde a échoué.',
      formalName: 'Raison sociale de l\'entreprise',
      commercialName: 'Nom commercial (optionnel)',
      legalStructure: 'Forme juridique',
      shareCapital: 'Capital social',
      address: {
        label: 'Adresse',
        error: 'Au moins un caractère'
      },
      city: {
        label: 'Ville',
        error: 'Au moins un caractère'
      },
      zipCode: {
        label: 'Code Postal',
        error: 'Au moins un caractère'
      },
      creationDate: 'Date de création',
      closingDate: 'Date de clôture comptable',
      state: 'Etat / Départment',
      country: 'Pays',
      timezone: 'Fuseau horaire',
      legalId: 'Numéro d\'identification',
      cancel: 'Annuler',
      save: 'Enregistrer les informations',
      updateSuccess: 'Coordonnées de l\'entreprise mises à jour.',
      generalInfo: 'Informations générales',
      companyAddress: 'Adresse de l\'entreprise',
      logoLabel: 'Logo de la société',
      logoSubtitle: 'Votre logo apparaîtra sur vos factures et dans les emails de relance adressés à vos clients.',
      clickUpload: 'Cliquer pour télécharger',
      uploadLimitations: 'Vérifiez que l\'image soit bien au format PNG, JPG ou GIF et qu\'elle ne dépasse pas 1 Mo.',
      uploadHelp: 'Pour changer de fichier, cliquez le bouton upload, les infos du fichier ou la preview.',
      feedbackAllowedTypes: 'Les types de fichiers autorisés sont {{count}}',
      feedbackSizeLimit: 'La taille maximale autorisé est {{count}} et votre fichier fait {{yourfile}}.',
      feedbackWidthAndHeight: 'La hauteur et la largeur ne doivent pas dépasser 1200 pixels.',
      selectNewLogo: 'Ajouter le logo de la société',
      removeLogo: 'Retirer le logo',
      resetLogo: 'Rétablir le logo',
      reFramePicture: 'Recadrez l\'image',
      resizePicture: 'Redimensionnez votre photo en déplaçant le rectangle de sélection.',
      confirmReFraming: 'Confirmer',
      cancelReFraming: 'Annuler'
    },
    editPassword: {
      error: 'La sauvegarde a échoué.',
      title: 'Sécurité',
      currentPassword: {
        label: 'Mot de passe actuel',
        error: 'Merci de saisir un mot de passe valide'
      },
      newPassword: {
        label: 'Nouveau mot de passe',
        error: 'Merci de saisir un mot de passe valide',
        noMatch: 'Les mots de passe ne correspondent pas.'
      },
      cancel: 'Annuler',
      save: 'Enregistrer les changements'
    },
    profileEdit: {
      error: 'La mise à jour a échoué.',
      account: 'Profil personnel',
      accountCopy: 'Passez en revue les informations du titulaire de ce compte et assurez-vous que tout est à jour. ',
      personalInformation: 'Informations générales',
      firstName: {
        label: 'Prénom',
        error: 'Renseignez au moins 2 caractères'
      },
      lastName: {
        label: 'Nom de famille',
        error: 'Renseignez au moins 2 caractères'
      },
      email: {
        label: 'Adresse email',
        verified: 'Email vérifié',
        notVerified: 'Email non vérifié'
      },
      phoneNumber: {
        label: 'Numéro de téléphone',
        error: 'Renseignez un numéro valide'
      },
      officePhone: {
        label: 'Téléphone de bureau',
        error: 'Renseignez un numéro valide'
      },
      mobilePhone: {
        label: 'Téléphone mobile',
        error: 'Renseignez un numéro valide'
      },
      role: {
        label: 'Rôle au sein de {{company}}'
      },
      customRole: {
        label: 'Saisissez votre titre officiel'
      },
      language: {
        heading: 'Réglages de l\'interface',
        copy: 'Choisissez la langue dans laquelle vous souhaitez utiliser Dundy.',
        label: 'Langue'
      },
      security: 'Securité',
      securityCopy: 'Pour modifier votre mot de passe, il est nécessaire de le réinitialiser.',
      current: 'Mot de passe actuel',
      reset: 'Réinitialiser le mot de passe',
      cancel: 'Annuler',
      save: 'Enregistrer les informations',
      updateSuccess: 'Détails du profil mis à jour.'
    },
    customerForm: {
      title: 'Information sur la société',
      company: {
        label: 'Raison sociale de l\'entreprise',
        usualName: 'Nom usuel',
        isPrivateIndividual: 'Client particulier',
        isProfessional: 'Client professionnel',
        error: 'Renseignez au moins 1 caractère'
      },
      address: {
        label: 'Adresse',
        extraAddressLine: 'Complément d\'adresse',
        error: 'Renseignez au moins 1 caractère'
      },
      city: {
        label: 'Ville',
        error: 'Renseignez au moins 1 caractère'
      },
      zipCode: {
        label: 'Code Postal',
        error: 'Au moins un caractère'
      },
      state: 'Etat / Départment',
      country: 'Pays',
      legalId: {
        label: 'Numéro d\'identification',
        error: 'Renseignez au moins 1 caractère'
      },
      companyLegalIdentificationType: {
        frSIREN: 'SIREN',
        frTVA: 'TVA Intra-Communautaire'
      },
      vat: {
        label: 'TVA Intra-Communautaire',
        error: 'Numéro de TVA vide ou incorrect'
      },
      email: {
        label: 'Email',
        error: 'Merci d\'indiquer un email valide'
      },
      firstName: {
        label: 'Prénom',
        error: 'Renseignez au moins 1 caractère'
      },
      lastName: {
        label: 'Nom',
        error: 'Renseignez au moins 1 caractère'
      },
      phoneNumber: {
        label: 'Téléphone',
        error: 'Renseignez un numéro valide'
      },
      officePhone: {
        label: 'Téléphone Fixe',
        error: 'Renseignez un numéro de fixe valide'
      },
      mobilePhone: {
        label: 'Téléphone Mobile',
        error: 'Renseignez un numéro mobile valide'
      },
      position: {
        label: 'Rôle',
        error: 'Indiquez le rôle de votre contact'
      },
      department: {
        label: 'Département',
        error: 'Indiquez le département/BU'
      }
    },
    customerLabel: {
      edit: 'Editer'
    },
    addInvoice: {
      invoiceDescription: 'Description',
      error: 'Erreur lors de l\'ajout de la facture',
      add: 'Ajouter une facture',
      quickAddInvoiceExcerpt: 'Ajouter un pied de facture'
    },
    invoiceStatus: {
      creditNote: 'Avoir',
      dataComplete: 'Finaliser',
      daysLate_one: ' jour de retard',
      daysLate_other: ' jours de retard',
      deleted: 'Effacée',
      draft: 'Brouillon',
      draftCreditNote: 'Brouillon d\'avoir',
      draftInvoice: 'Brouillon de facture',
      draftPurchaseOrder: 'Brouillon d\'achat',
      draftQuote: 'Brouillon de devis',
      invoice: 'Facture',
      onTime: 'Bientôt',
      paid: 'Payée',
      pendingVoidedCreditNote: 'Avoir en attente d\'annulation',
      pendingVoidedInvoice: 'Facture en attente d\'annulation',
      pendingVoidedPurchaseOrder: 'Achat  en attente d\'annulation',
      pendingVoidedQuote: 'Devis  en attente d\'annulation',
      pendingVoidingCreditNote: 'Avoir d\'annulation à finaliser',
      pendingVoidingInvoice: 'Facture d\'annulation à finaliser',
      pendingVoidingPurchaseOrder: 'Achat d\'annulation à finaliser',
      pendingVoidingQuote: 'Devis d\'annulation à finaliser',
      purchaseOrder: 'Achat',
      quote: 'Devis',
      startTracking: 'Démarrer le suivi',
      tbd: 'Mise à jour...',
      voidedCreditNote: 'Avoir annulé',
      voidedInvoice: 'Facture annulée',
      voidedPendingInvoice: 'En attente d\'annulation',
      voidedPurchaseOrder: 'Achat annulé',
      voidedQuote: 'Devis annulé',
      voidedVoidingCreditNote: 'Avoir d\'annulation annulé',
      voidedVoidingInvoice: 'Facture d\'annulation annulée',
      voidedVoidingPurchaseOrder: 'Achat d\'annulation annulé',
      voidedVoidingQuote: 'Devis d\'annulation annulé',
      voidingCreditNote: 'Avoir d\'annulation',
      voidingInvoice: 'Facture d\'annulation',
      voidingPendingFinalize: 'En attente de finalisation',
      voidingPurchaseOrder: 'Achat d\'annulation',
      voidingQuote: 'Devis d\'annulation',
      wip: 'Incomplète'
    },
    markPaidOrUnpaidInvoice: {
      error: 'Une erreur est survenue lors du changement de statut',
      markPaid_one: 'Marquer comme payée',
      markPaid_other: 'Marquer comme payées',
      markUnpaid_one: 'Marquer comme non payée',
      markUnpaid_other: 'Marquer comme non payées'
    },
    memberForm: {
      titleRole: 'Rôle de l\'utilisateur',
      admin: 'Administrateur',
      contributor: 'Contributeur',
      noteAdmin:
        'Les administrateurs ont un accès complet à votre compte Dundy. Cela inclut les clients, les coordonnées bancaires, les paiements et les paramètres. Les administrateurs peuvent également ajouter d\'autres administrateurs et contributeurs.',
      noteContributor:
        'Les contributeurs peuvent afficher et fermer des tâches, ils peuvent ajouter, supprimer et afficher des factures. Ils ont accès à leurs propres paramètres.',
      titleDetails: 'Informations de l\'utilisateur',
      firstName: {
        label: 'Prénom',
        error: 'Renseignez au moins 2 caractères'
      },
      lastName: {
        label: 'Nom',
        error: 'Renseignez au moins 2 caractères'
      },
      email: {
        label: 'Email address',
        error: 'Merci d\'indiquer un email valide'
      }
    },
    signUpForm: {
      error: 'La création de compte a échoué',
      title: {
        one: 'Ravi de vous rencontrer, ',
        two: 'Commençons par créer votre compte'
      },
      email: {
        placeholder: 'Email',
        error: 'Merci d\'indiquer un email valide'
      },
      password: {
        placeholder: 'Mot de passe',
        error: 'Merci d\'indiquer un mot de passe valide'
      },
      note: {
        title: 'Votre mot de passe doit comporter :',
        one: '12 caractères ou plus',
        two: 'Majuscules et minuscules',
        three: 'Au moins 1 numéro',
        four: 'Au moins 1 caractère spécial'
      },
      continue: 'Continuer',
      footer: {
        one: 'En créant votre compte, vous acceptez les',
        two: 'conditions d\'utilisation',
        three: 'et',
        four: 'notre politique de confidentialité'
      }
    },
    searchBar: {
      placeholder: 'Chercher...'
    },
    smallSelect: {
      placeholder: 'Sélectionner...'
    },
    bigSelect: {
      placeholder: 'Sélectionner...'
    },
    clients: {
      heading: 'Contacts',
      heading_with_count_one: 'Contact ({{count}})',
      heading_with_count_other: 'Contacts ({{count}})',
      headingContacts_one: '{{count}} contact enregistré pour cette société.',
      headingContacts_other: '{{count}} contacts enregistrés pour cette société.',
      addContact: 'Ajouter un contact',
      addOrEditContact: 'Ajouter ou éditer un contact pour cette société.',
      addOrEditContacts: 'Ajoutez et gérez les contacts au sein de vos\n' +
        '                                    entreprises clientes.',
      companyHeading: 'Informations sur la société',
      modalHeading: 'Enregistrez un client',
      saveClient: 'Enregistrer le client'
    },
    agingBalance: {
      title: 'Balance âgée',
      search: {
        placeholder: 'Rechercher un client'
      },
      header: {
        due: 'Echu (€)',
        overdue: 'Non-échu (€)',
        client: 'Client',
        leftToPay: 'Restant dû',
        above90Days: '&gt; 90J',
        From90To61Days: '90-61J',
        From60To31Days: '60-31J',
        From31To1Day: '30-1J',
        From0To30Days: '0-30J',
        From031To60Days: '31-60J',
        From61To90Days: '61-90J',
        MoreThan90DaysAway: '&gt; 90J',
        total: 'Total'
      },
      sideNote: {
        title: 'Quelques explications sur la balance âgée',
        copy_FirstLine: 'La balance âgée vous permet de suivre les sommes d\'argent que vos clients vous doivent, en les classant en fonction de la date à laquelle ils doivent vous payer.',
        copy_SecondLine: 'Les sommes sont calculées en prenant en compte les factures finalisées et suivies.'
      }
    },
    todo: {
      stats: {
        totalOutstanding: 'Montant total attendu',
        totalOutstandingTooltip: 'Le montant total attendu est le montant total de toutes les factures de ventes émises à ce jour, en attente de règlement.',
        dueAmount: 'Montant non-échu',
        dueAmountTooltip: 'Le montant non-échu est l\'argent que vous devez recevoir de vos ventes, mais qui n\'est pas encore à échéance selon les dates indiquées sur les factures.',
        overdueAmount: 'Montant échu',
        overdueAmountTooltip: 'Le montant échu est l\'argent que vous auriez déjà dû recevoir de vos ventes car la date d\'échéance des factures est atteinte ou dépassée.',
        dsoTooltip: 'Le DSO (Days Sales Outstanding) est une mesure financière qui indique combien de\n' +
          '                                    jours il faut en moyenne à une entreprise pour recouvrer ses créances clients après\n' +
          '                                    une vente. Plus le DSO est élevé, plus il faut de temps pour percevoir cet\n' +
          '                                    argent. ',
        dsoUnit_one: 'jour',
        dsoUnit_other: 'jours',
        dsoInvalid: '-'
      },
      agingBalanceVisualisation: {
        dueMoreThan90d: 'Plus de 90 jours',
        due61And90d: 'Dans 61 à 90 jours',
        due31And60d: 'Dans 31 à 60 jours',
        due0And30d: 'Dans 0 à 30 jours',
        overdue30And1d: 'Il y a 1 à 30 jours',
        overdue31And60d: 'Il y a 31 à 60 jours',
        overdue61And90d: 'Il y a 61 à 90 jours',
        overdueMoreThan90d: 'Il y a + de 90 jours'
      },
      firstEmptyState: {
        createInvoiceDrive: 'Commencez par créer une facture',
        createInvoiceSubtext: 'Ajoutez votre première facture pour démarrer son suivi et vous faire payer plus vite.',
        createInvoiceButton: 'Créer une facture',
        createCustomerDrive: 'Commencez par ajouter un client et un contact',
        createCustomerSubtext: 'Ajoutez votre premier client et son contact pour lui envoyer une facture.',
        createCustomerButton: 'Ajouter un client et le premier contact'
      },
      nav_todo: {
        today: 'Aujourd\'hui',
        done: 'terminées',
        upcoming: 'A venir',
        action_one: 'Action de relance',
        action_other: 'Actions de relance',
        clientsOverdueMaj_one: 'Client en retard',
        clientsOverdueMaj_other: 'Clients en retard',
        agingBalance: 'Balance âgée',
        tasksToDoToday: 'Vos tâches en attente',
        partialInvoices_one: 'Facture de solde à établir',
        partialInvoices_other: 'Factures de solde à établir'
      },
      account_position: {
        awaitingPayment: 'En attente de règlement',
        amountOverdue: 'En retard',
        amountOnTime: 'Dans les temps',
        amountDue: 'Montant total à encaisser',
        clientsOverdue_one: 'Client en retard',
        clientsOverdue_other: 'Clients en retard',
        paymentsConfirm_one: 'paiement à valider',
        paymentsConfirm_other: 'paiements à valider',
        outstandingInvoices_one: 'Facture ouverte',
        outstandingInvoices_other: 'Factures ouvertes',
        lateInvoices_one: 'facture',
        lateInvoices_other: 'factures',
        newClients_one: 'Nouveau client',
        newClients_other: 'Nouveaux clients'
      },
      tips_sidebar: {
        title: 'Conseils & Astuces',
        stayAmicable: 'La plupart des clients <strong>ont l’intention de payer leur\n' +
          '                            facture</strong>.<br><br>\n' +
          '                            Même lorsque votre client a du retard, <strong>gardez un ton amical</strong>. Si vous êtes\n' +
          '                            frustré et que cela commence à se voir, cela pourrait se retourner contre vous et amener votre\n' +
          '                            client à retarder encore plus le paiement.<br><br> Une relation client saine et courtoise vous\n' +
          '                            assure de garder une bonne réputation et fidélise votre clientèle.'

      },
      bank_sidebar: {
        title: 'Connectez votre compte en banque pour vous faire payer',
        listFirst: 'Profitez du remplissage automatique de vos informations bancaires.',
        listSecond: 'Disposez d’un lien de paiement pour vous faire payer rapidement et gratuitement !',
        listThird: 'Découvrez le rapprochement bancaire automatisé entre vos factures ouvertes et vos transactions entrantes',
        connectAccount: 'Configurer mon compte',
        privacyNotice: 'Nous ne voyons pas vos identifiants et nous ne revendons pas vos données. Tout est sécurisé par notre prestataire Bridge for Bankin. ',
        privacyLink: 'Plus d\'info'
      },
      item: {
        type: {
          needApproval: 'en attente de validation',
          dunningAction: 'Relance',
          dunningActionVerb: 'Relancer'
        },
        time: {
          due_today: 'Échue aujourd\'hui',
          due_one: 'Échue dans {{count}} jour',
          due_other: 'Échue dans {{count}} jours',
          overdue_one: 'En retard de {{count}} jour',
          overdue_other: 'En retard de {{count}} jours'
        },
        status: {
          newPayments_one: 'Paiement',
          newPayments_other: 'Paiements',
          dispute_one: 'Facture contestée',
          dispute_other: 'Factures contestées',
          firstReminder: '1er rappel',
          secondReminder: '2ème rappel',
          lastCall: 'Dernier appel',
          legalAction: 'En Justice',
          onTime: 'A l\'heure',
          critical: 'Urgent'
        },
        naturalLanguageByMessageKind: {
          InvoiceIssuance: 'Envoyer la facture {{invoiceNumbers}} à {{contactName}} de {{customerName}}',
          Email: 'Relancer {{contactName}} de {{customerName}} pour {{invoiceNumbers}}',
          PhoneCall: 'Appeler {{contactName}} de {{customerName}} pour {{invoiceNumbers}}',
          EmailAndRegularPostMail: 'Envoyer une mise en demeure à {{customerName}} pour {{invoiceNumbers}}',
          LawyerAndOtherLegalServices: 'Passer en contentieux {{customerName}} pour {{invoiceNumbers}}'
        }
      }
    },
    partialInvoices: {
      columns: {
        client: 'client',
        existingBusinessDocument: 'Montant facturé',
        newBalanceBusinessDocument: 'Reste à facturer'

      }
    },
    todoItem: {
      errorEmpty: 'Il n\'y a pas d\'élément Todo avec l\'ID fourni.',
      amountDueWithTaxes: 'Montant TTC',
      amountPaid: 'Total payé',
      invoice_one: '{{count}} facture',
      invoice_other: '{{count}} factures',
      addressedTo: 'Envoyée à {{firstName}} {{lastName}}',
      dueOn: 'Echue le {{date}}'
    },
    history: {
      kind: {
        InvoiceIssuance: {
          timelineState: 'Email d\'émission de facture',
          timelineDoneState: 'Facture envoyée au client',
          timelineDoneCopy: 'Envoyée le {{dateDone}} via Dundy'
        },
        InvoiceIssued: {
          timelineDoneState: 'Facture créée',
          timelineState: 'Facture créée',
          timelineDoneCopy: 'Créée le {{dateDone}} dans Dundy'
        },
        CourtesyReminderShortlyBeforeDueDate: {
          timelineState: 'Rappel d\'échéance à envoyer',
          timelineDoneState: 'E-mail d\'avis d\'échéance envoyé au client',
          timelineDoneCopy: 'Envoyé {{dateDone}}'
        },
        InvoicePastDue: {
          timelineState: 'Facture en retard',
          timelineCopy: 'La date d\'échéance était le {{date}}',
          timelineDoneState: 'E-mail d\'avis de retard envoyé au client',
          timelineDoneCopy: 'Envoyé le {{dateDone}}'
        },
        ReminderJustAfterDueDate: {
          timelineState: 'Premier rappel à envoyer',
          timelineDoneState: 'Premier e-mail de rappel envoyé au client',
          timelineDoneCopy: 'Envoyé {{dateDone}}'
        },
        ReminderShortlyAfterDueDate: {
          timelineState: 'Second rappel à envoyer',
          timelineDoneState: 'Deuxième e-mail de rappel envoyé au client',
          timelineDoneCopy: 'Envoyé le {{dateDone}}'
        },
        DirectReminderSomeTimeAfterDueDate: {
          timelineState: 'Troisième rappel à envoyer',
          timelineDoneState: 'Troisième e-mail de rappel envoyé au client',
          timelineDoneCopy: 'Envoyé le {{dateDone}}'
        },
        StrongLastReminderAfterDueDate: {
          timelineState: 'Lettre de mise en demeure à envoyer',
          timelineDoneState: 'Mise en demeure envoyée au client',
          timelineDoneCopy: 'Envoyé le {{dateDone}}'
        },
        CollectionLitigation: {
          timelineState: 'Nécessite une procédure légale',
          timelineDoneState: 'Passage en contentieux',
          timelineDoneCopy: 'Contentieux initié le {{dateDone}}'
        },
        DisputeResolution: {
          timelineState: 'Nécessite une résolution de conflit',
          timelineDoneState: 'Litige résolu',
          timelineDoneCopy: 'Résolu le {{dateDone}}'
        },
        CashApplicationDeclared: {
          timelineState: 'Paiement reçu',
          timelineDoneState: 'Paiement reçu',
          timelineDoneCopy: 'Reçu le {{dateDone}}'
        },
        NoActionAtThisStage: {
          timelineState: 'Aucune action à ce stade',
          timelineDoneState: 'Aucune action à ce stade',
          timelineDoneCopy: 'Aucune action à ce stade'
        }
      }
    },
    todoAction: {
      titleInvoice: 'Envoi de facture',
      titleReminder: 'Envoi de rappel',
      navigation: {
        timeline: 'Activité',
        tips: 'Conseils'
      },
      dunningEscalationTab: {
        information: 'Information',
        reminder: 'Rappel',
        warning: 'Avertissement',
        legal: 'Recouvrement'
      },
      dunningMeansTab: {
        email: 'Email',
        phoneCall: 'Téléphone',
        postalLetter: 'Lettre Postal',
        legalAction: 'Recouvrement',
        information: 'Information'
      },
      errorLoadingHistory: 'Impossible de charger l\'historique de la facture.',
      errorLoadingFeedback: 'Une erreur est survenue lors du chagement de l\'historique de votre facture.',
      noAction: 'Aucune action prévue',
      markTodoDone: 'Cette tâche est faite',
      markingAsDone: 'En cours',
      markTodoDoneSuccess: 'Tâche marquée comme terminée.',
      sendReminder: 'Envoyer le rappel',
      sendInvoice: 'Envoyer la facture',
      sendingReminder: 'Envoi en cours',
      emailTab: 'Email',
      callTab: 'Téléphone',
      letterTab: 'Lettre Postal',
      escalationTab: 'Recouvrement',
      invoiceLabel: 'Facture',
      backLink: 'Retour au tableau de bord',
      fromLabel: 'De :',
      toLabel: 'À :',
      ccLabel: 'Cc:',
      bccLabel: 'Cci:',
      subjectLabel: 'Objet :',
      timelineTitle: 'Timeline',
      copySuccess: '{{area}} copié dans le clipboard.',
      contentLocked: 'Le contenu est verrouillé. Pour modifier ce contenu, complètez les informations manquantes.',
      includePDF: 'Inclure le PDF en pièce jointe',
      includePaymentLink: 'Inclure le lien de paiement',
      missingBankDetailNotice: {
        header: 'Informations bancaires manquantes !',
        copy: 'Renseignez vos coordonnées bancaires afin que vos clients puissent vous payer.',
        button: 'Je renseigne mon RIB'
      },
      missingContactNotice: {
        header: 'Informations du contact manquantes !',
        copy: 'Vérifiez les coordonnées de contact avant d\'envoyer l\'e-mail.',
        button: 'Je crée le contact'
      },
      resetBodyMessage: 'Revenir au modèle par défaut',
      copyExplainerPhoneCall: 'Appelez votre client et suivez le script ci-dessous. N\'oubliez pas d\'écrire tout le contexte et les réponses qu\'ils vous donnent.',
      emailSubject: 'U-Trade: Vos factures en attente',
      emailFrom: 'de',
      copy: 'Copier',
      workflowPausedText1: 'La relance de ce client est suspendue.',
      workflowPausedText2: 'Pour reprendre la relance et voir l\'action suivante pour ce client, cliquez sur « Reprendre le workflow ».',
      resumeWorkflow: 'Reprendre la relance',
      pauseWorkflow: 'Mettre la relance en pause',
      paymentDescription: 'Facture {{invoiceNumber}}',
      actionValidateToday: 'Validez cette action aujourd\'hui',
      attachedInvoiceText: '<p>Veuillez trouver une copie de la facture en pièce jointe.</p><br/>',
      baseActionLetterItemBody: {
        intendedFor: 'À l\'attention de',
        letterOnTime: ', le '
      },
      baseActionEmailItemBody: {
        yourInvoice: 'Votre facture de',
        goodMorning: 'Bonjour',
        hereIs: 'Veuillez trouver ci-joint votre <span style="font-weight:600; color: #1F2533">facture n°{{invoiceNumber}}</span> émise par la <span style="font-weight:600; color: #1F2533">société {{senderCompanyName}}</span> :',
        invoiceAvailable: 'Votre <span style="font-weight:600; color: #1F2533">facture n°{{invoiceNumber}}</span> émise par la <span style="font-weight:600; color: #1F2533">société {{senderCompanyName}}</span> est disponible :',
        invoiceNumber: 'facture n°',
        issuedFor: 'émise par la',
        company: 'société',
        amount: 'Montant',
        ttc: 'TTC',
        dueDate: 'Date d\'échéance',
        objection: 'Si cette facture fait l’objet d’une contestation, je vous remercie de l’indiquer sans délai.',
        bankDetails: 'Vous pouvez payer votre facture dès maintenant en cliquant sur le bouton ci-dessous :',
        makePayment: 'Payer la facture',
        bankDetailsTitle: 'Vous pouvez effectuer un virement manuellement en utilisant les informations suivantes',
        bank: 'Banque',
        iban: 'IBAN',
        bic: 'BIC',
        reference: 'Référence',
        thanks: 'Merci d\'inclure la référence dans le libellé de votre virement pour que votre paiement soit correctement identifié.',
        regards: 'Vous en souhaitant bonne réception',
        alreadyPaid: '<p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif;">Si vous avez déjà effectué votre paiement entre-temps, veuillez ne pas tenir compte de cet avis.</p>',
        thoughtfully: 'Cordialement'
      },
      previewUILabel: 'Prévisualisation',
      editingUILabel: 'Edition',
      actionKind: {
        InvoiceIssuance: {
          explainerIconName: 'icon-bulb',
          explainerTitle: 'Pourquoi envoyer cet email ?',
          explainerText: `
                    <br/>
                    <p>Envoyer la facture à votre client dès son émission vous permet de vous assurer que celui-ci vous paiera dans les meilleurs délais.</p><br/>
                    <p>C’est aussi nécessaire afin de commencer le suivi de votre facture et la constitution d’un éventuel dossier de contentieux de la bonne manière.</p><br/>
                    <p>Enfin, c’est une bonne habitude d’être rigoureux sur ce point.</p><br/>
                  `,
          messageKind: {
            Email: {
              subject: 'Votre facture n°{{invoiceNumber}} de {{senderCompanyName}} est disponible',
              itemBody: {
                title: 'ÉMISSION DE FACTURE'
              }
            },
            PhoneCall: {
              subject: '',
              itemBody: `
                            <p class='text-2xl'>Bonjour, c’est {{senderFirstName}} {{senderLastName}} de {{senderCompanyName}}.</p><br>
                            <p>Je vous appelle pour vous informer que je viens d'éditer la facture n°{{invoiceNumber}} d'un montant de {{invoiceAmount}}, qui sera échue le {{dueDate}}.</p><br>
                            <p>Vous trouverez un lien de paiement sur la facture et dans le mail que je viens de faire partir à votre attention.</p><br>
                            <p>Vous pouvez également effectuer un virement en utilisant les informations présentes dans ce mail.</p><br>
                            <p>Si vous avez des questions concernant cette facture, n'hésitez pas à me les poser.</p><br>
                            <p>Merci d'avoir choisi {{ senderCompanyName }}</p>
                          `
            },
            EmailAndRegularPostMail: {
              subject: 'Objet: Émission de la facture n°{{invoiceNumber}}',
              itemBody: `
                                <div class='w-full flex-col'>
                                    <p>Madame, Monsieur,</p><br>
                                    <p>Veuillez trouver ci-jointe la facture n°{{invoiceNumber}} d'un montant de {{invoiceAmount}}, qui sera échue le {{dueDate}}.</p><br>

                                    <p>Nous avons pris soin d'en vérifier attentivement les informations.</p><br>
                                    
                                    <p>Toutefois, si vous constatez une erreur ou que cette facture fait l'objet d'une contestation, je vous invite à me l'indiquer sans délai aux coordonnées qui figurent sur ce courrier.</p><br>

                                    <p>Nous espérons que nos services répondent à vos attentes et nous sommes à votre disposition pour tout renseignement complémentaire.</p><br>

                                    <p>Je vous remercie pour la confiance que vous placez en {{senderCompanyName}}.</p><br>

                                    <p>Cordialement,</p><br>
                                    <p>{{senderFirstName}} {{senderLastName}}</p>
                                    <p>{{senderRole}}</p>
                                </div>`
            }
          }
        },
        CourtesyReminderShortlyBeforeDueDate: {
          explainerIconName: 'icon-bulb',
          explainerTitle: 'Pourquoi faire cette relance ?',
          explainerText: `
                    <br/>
                    <p>L'email de rappel avant échéance sert à :</p><br/>
                    <ul class='list-disc pl-4'>
                        <li class='py-1'>confirmer que la facture n’est pas remise en cause par le client ;</li>
                        <li class='py-1'>rappeler que vous attendez le paiement à date ;</li>
                    </ul>
                  `,
          messageKind: {
            Email: {
              subject: 'Votre facture n°{{invoiceNumber}} de {{senderCompanyName}} est bientôt échue',
              itemBody: {
                title: 'RAPPEL AVANT ÉCHÉANCE'
              }
            },
            PhoneCall: {
              subject: '',
              itemBody: `
                            <p class='text-2xl'>Bonjour, c’est {{senderFirstName}} {{senderLastName}} de {{senderCompanyName}}.</p><br>
                            <p>Je vous appelle aujourd'hui pour vous rappeler que votre facture n°{{invoiceNumber}} d'un montant de {{invoiceAmount}} est bientôt échue.</p><br>
                            <p>Je souhaitais vous informer personnellement pour vous garantir que tout est en ordre et vous donner l'opportunité de poser toutes les questions que vous pourriez avoir.</p><br>
                            <p>Je sais que les délais peuvent être serrés et je veux m'assurer que vous avez suffisamment de temps pour gérer les paiements en temps voulu.</p><br>
                            <p>Je me tiens à votre disposition pour tout renseignement supplémentaire.</p><br>
                            <p>Je suis là pour vous aider et je ferais de mon mieux pour répondre à toutes vos préoccupations.</p><br>
                            <p>Merci d'avoir choisi {{senderCompanyName}}</p>
                          `
            },
            EmailAndRegularPostMail: {
              subject: 'Objet: Émission de la facture n°{{invoiceNumber}}',
              intendedFor: 'A l\'attention de',
              letterOnTime: ', le ',
              itemBody: `
                                <div class='w-full flex-col'>
                                    <p>Madame, Monsieur,</p><br>
                                    <p>Veuillez trouver ci-jointe la facture n°{{invoiceNumber}} d'un montant de {{invoiceAmount}}, qui sera échue le {{dueDate}}.</p><br>

                                    <p>Nous avons pris soin d'en vérifier attentivement les informations pour nous assurer que tout est correct.</p><br>
                                    
                                    <p>Toutefois, si vous constatez une erreur ou que vous avez une question, je vous invite à me contacter rapidement aux coordonnées qui figurent sur ce courrier.</p><br>

                                    <p>Nous espérons que nos services répondent à vos attentes et nous sommes à votre disposition pour tout renseignement supplémentaire.</p><br>

                                    <p>Je vous remercie pour la confiance que vous placez en {{senderCompanyName}}.</p><br>

                                    <p>Cordialement,</p><br>
                                    <p>{{senderFirstName}} {{senderLastName}}</p>
                                    <p>{{senderRole}}</p>
                                </div>`
            }
          }
        },
        ReminderJustAfterDueDate: {
          explainerIconName: 'icon-bulb',
          explainerTitle: 'Pourquoi faire cette relance ?',
          explainerText: `
                    <br/>
                    <p>C’est important de relancer votre client rapidement après la date de paiement contractuelle pour lui signaler que la date est passée et qu’il doit s’exécuter. </p><br/>
                    <p>Plus tôt vous relancez votre client, plus tôt vous recevrez le paiement!</p><br/>
                    <p>Quand on veut se faire payer, la rigueur et la vitesse sont des précieux alliés.</p><br/>
                  `,
          messageKind: {
            Email: {
              subject: 'Votre facture n°{{invoiceNumber}} de {{senderCompanyName}} est en attente de règlement',
              itemBody: {
                title: 'EN ATTENTE DE RÈGLEMENT',
                thanksForPaying: '<p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif;">Merci de procéder au règlement dès à présent en utilisant les moyens de règlement ci-après.</p>'
              }
            },
            PhoneCall: {
              subject: '',
              itemBody: `
                            <p class='text-2xl'>Bonjour, c’est {{senderFirstName}} {{senderLastName}} de {{senderCompanyName}}.</p><br>
                            <p>Je vous appelle aujourd'hui pour vous rappeler que votre facture n°{{invoiceNumber}} d'un montant de {{invoiceAmount}} vient d'être échue.</p><br>
                            <p>Je souhaitais vous informer personnellement pour vous garantir que tout est en ordre et vous donner l'opportunité de poser toutes les questions que vous pourriez avoir.</p><br>
                            <pJe voulais également vous rappeler que vous avez plusieurs options pour payer votre facture, notamment en utilisant le lien de paiement en ligne ou en utilisant les coordonnées bancaires qui se trouvent dans votre facture.</p><br>
                            <p>Je me tiens à votre disposition pour tout renseignement supplémentaire.</p><br>
                            <p>Merci d'avoir choisi {{senderCompanyName}}</p>
                          `
            },
            EmailAndRegularPostMail: {
              subject: 'Objet: Facture n°{{invoiceNumber}} en attente de règlement',
              intendedFor: 'A l\'attention de',
              letterOnTime: ', le ',
              itemBody: `
                                <div class='w-full flex-col'>
                                    <p>Madame, Monsieur,</p><br>
                                    <p>Veuillez trouver ci-jointe la facture n°{{invoiceNumber}} d'un montant de {{invoiceAmount}}, dont la date d'échéance était le {{dueDate}}.</p><br>

                                    <p>Nous avons pris soin d'en vérifier attentivement les informations pour nous assurer que tout est correct.</p><br>
                                    
                                    <p>Vous disposez de plusieurs options pour payer votre facture, notamment en utilisant le lien de paiement en ligne ou en utilisant les coordonnées bancaires qui se trouvent dans votre facture.</p><br>
                                    
                                    <p>Si vous constatez une erreur ou que vous avez une question, je vous invite à me contacter rapidement aux coordonnées qui figurent sur ce courrier.</p><br>

                                    <p>Nous espérons que nos services répondent à vos attentes et nous sommes à votre disposition pour tout renseignement supplémentaire.</p><br>

                                    <p>Je vous remercie pour la confiance que vous placez en {{senderCompanyName}}.</p><br>

                                    <p>Cordialement,</p><br>
                                    <p>{{senderFirstName}} {{senderLastName}}</p>
                                    <p>{{senderRole}}</p>
                                </div>`
            }
          }
        },
        ReminderShortlyAfterDueDate: {
          explainerIconName: 'icon-bulb',
          explainerTitle: 'Pourquoi faire cette relance ?',
          explainerText: `
                    <br/>
                    <p>Le premier email passe souvent à la trappe. C’est comme ça. On envoie donc ce rappel sympathique pour remettre de  l’urgence chez votre client.</p><br/>
                    <p>Bien souvent, ce deuxième mail est la piqûre de rappel nécessaire pour que votre client se décide à gérer la question de votre paiement :)</p><br/>
                  `,
          messageKind: {
            Email: {
              subject: 'Votre facture n°{{invoiceNumber}} de {{senderCompanyName}} est en attente de règlement',
              itemBody: {
                title: 'EN ATTENTE DE RÈGLEMENT',
                ifNotMistaken: 'Sauf erreur de notre part, nous n’avons pas reçu le règlement de la <span style="font-weight:600; color: #1F2533">facture n°{{invoiceNumber}}</span> émise par la société <span style="font-weight:600; color: #1F2533">{{senderCompanyName}}</span> :',
                thanksForPaying: 'Merci de procéder au règlement dès à présent en utilisant les moyens de règlement ci-après.',
                ifYouFind: 'Si vous rencontrez des difficultés pour le paiement de cette facture, merci de prendre contact avec {{senderFirstName}} {{senderLastName}}, soit par mail {{senderEmail}}, soit par téléphone au numéro suivant : {{senderPhoneNumber}}.'
              }
            },
            PhoneCall: {
              subject: '',
              itemBody: `
                            <p class='text-2xl'>Bonjour, c’est {{senderFirstName}} {{senderLastName}} de {{senderCompanyName}}.</p><br>
                            <p>Je vous appelle aujourd'hui à propos de la facture n°{{invoiceNumber}} d'un montant de {{invoiceAmount}}</p><br>
                            <p>Selon mes fichiers, elle est due depuis le {{dueDate}}.</p><br>
                            <p>Je voulais m'assurer qu'il n'y a pas de problème avec la facture et, si tout est en ordre, savoir quand le paiement sera effectué.</p><br>
                            <p>Je souhaitais vous rappeler que vous avez plusieurs options pour payer votre facture, notamment en utilisant le lien de paiement en ligne ou en utilisant les coordonnées bancaires qui se trouvent dans votre facture.</p><br>
                            <p>Quand pouvons-nous attendre votre règlement ?</p>
                            
                            <p>Merci d'avoir choisi {{senderCompanyName}}</p>
                          `
            },
            EmailAndRegularPostMail: {
              subject: 'Objet: Facture n°{{invoiceNumber}} en attente de règlement',
              intendedFor: 'A l\'attention de',
              letterOnTime: ', le ',
              itemBody: `
                                <div class='w-full flex-col'>
                                    <p>Madame, Monsieur,</p><br>
                                    <p>Veuillez trouver ci-jointe la facture n°{{invoiceNumber}} d'un montant de {{invoiceAmount}}, dont la date d'échéance était le {{dueDate}}.</p><br>

                                    <p>Nous avons pris soin d'en vérifier attentivement les informations pour nous assurer que tout est correct.</p><br>
                                    
                                    <p>Vous disposez de plusieurs options pour payer votre facture, notamment en utilisant le lien de paiement en ligne ou en utilisant les coordonnées bancaires qui se trouvent dans votre facture.</p><br>
                                    
                                    <p>Si vous constatez une erreur ou que vous avez une question, je vous invite à me contacter rapidement aux coordonnées qui figurent sur ce courrier.</p><br>

                                    <p>Nous espérons que nos services répondent à vos attentes et nous sommes à votre disposition pour tout renseignement supplémentaire.</p><br>

                                    <p>Je vous remercie pour la confiance que vous placez en {{senderCompanyName}}.</p><br>

                                    <p>Cordialement,</p><br>
                                    <p>{{senderFirstName}} {{senderLastName}}</p>
                                    <p>{{senderRole}}</p>
                                </div>`
            }
          }
        },
        DirectReminderSomeTimeAfterDueDate: {
          explainerIconName: 'icon-bulb',
          explainerTitle: 'Pourquoi faire cette relance ?',
          explainerText: `
                    <br/>
                    <p>Pour continuer à faire pression sur votre débiteur afin d'obtenir le paiement de la somme due.</p><br/>
                    <p>De plus, si vous deviez entamer des procédures judiciaires, il est important de prouver votre bonne foi.</p><br/>
                    <p> Comment? En montrant que vous avez d’abord tenté toutes les démarches amiables de recouvrement.</p><br/>
                    <p>Gardez votre calme, soyez précis et ferme. Répétez votre demande d’avoir une date de promesse de paiement.</p><br/>
                  `,
          messageKind: {
            Email: {
              subject: 'Rappel amiable - facture n°{{invoiceNumber}} de {{senderCompanyName}}',
              itemBody: {
                title: 'RAPPEL AMIABLE',
                ifNotMistaken: `Sauf erreur de notre part, nous n\'avons pas encore reçu votre paiement de <strong>{{invoiceAmount}}</strong> pour la <strong>facture n°{{invoiceNumber}}</strong>.
                                                 Cette facture était échue le <strong>{{dueDate}}</strong> et est maintenant en retard de <strong>{{daysLate}} jours</strong>.`,
                howToPay: 'Le paiement peut être effectué en ligne via le bouton <strong>Payer la facture</strong> ci-dessous ou par <strong>virement au moyen des coordonnés bancaires</strong> en bas de cet email.',
                ifYouPaid: 'Si vous avez déjà réglé votre facture, merci de ne pas tenir compte de ce message.'
              }
            },
            PhoneCall: {
              subject: '',
              itemBody: `
                            <p class='text-2xl'>Bonjour, c’est {{senderFirstName}} {{senderLastName}} de {{senderCompanyName}}.</p><br>
                            <p>Je vous appelle aujourd'hui à propos de la facture n°{{invoiceNumber}} d'un montant de {{invoiceAmount}}</p><br>
                            <p>Selon mes fichiers, elle est due depuis le {{dueDate}}.</p><br>
                            <p>Je voulais m'assurer qu'il n'y a pas de problème avec la facture et, si tout est en ordre, savoir quand le paiement sera effectué.</p><br>
                            <p>Je souhaitais vous rappeler que vous avez plusieurs options pour payer votre facture, notamment en utilisant le lien de paiement en ligne ou en utilisant les coordonnées bancaires qui se trouvent dans votre facture.</p><br>
                            <p>Quand pouvons-nous attendre votre règlement ?</p><br>
                            <p>Merci d'avoir choisi {{senderCompanyName}}</p>
                          `
            },
            EmailAndRegularPostMail: {
              subject: 'Objet: Facture n°{{invoiceNumber}} en attente de règlement',
              intendedFor: 'A l\'attention de',
              letterOnTime: ', le ',
              itemBody: `
                                <div class='w-full flex-col'>
                                    <p>Madame, Monsieur,</p><br>
                                    <p>Sauf erreur de notre part, le règlement de la facture n°{{invoiceNumber}} d'un montant de {{invoiceAmount}} ne nous est toujours pas parvenu.</p><br>

                                    <p>Nous comprenons que des erreurs peuvent se produire et nous vous serions très reconnaissants de prendre attache avec notre équipe pour nous indiquer la date à laquelle nous recevrons le paiement.</p><br>
                                    
                                    <p>Vous disposez de plusieurs options pour régler votre facture, notamment en utilisant le lien de paiement en ligne ou en utilisant les coordonnées bancaires qui se trouvent dans votre facture.</p><br>
                                    
                                    <p>Si vous constatez une erreur ou que vous avez la moindre question, nous vous invitons à nous contacter sans délai aux coordonnées qui figurent sur ce courrier.</p><br>

                                    <p>Dans l'attente de votre retour, nous vous prions d'agréer, Madame, Monsieur, l'expression de nos salutations distinguées.</p><br>

                                    <p>Je vous remercie pour la confiance que vous placez en {{senderCompanyName}}.</p><br>

                                    <p>Cordialement,</p><br>
                                    <p>{{senderFirstName}} {{senderLastName}}</p>
                                    <p>{{senderRole}}</p>
                                </div>`
            }
          }
        },
        StrongLastReminderAfterDueDate: {
          downloadLetter: 'Télécharger la lettre en PDF',
          downloadLetterPrefix: 'Lettre-Relance',
          explainerIconName: 'icon-bulb',
          explainerTitle: 'Pourquoi faire cette relance ?',
          explainerText: `
                    <br/>
                    <p>La lettre de mise en demeure est la dernière étape avant d’entrer en contentieux avec le client. </p><br/>
                    <p>Elle est nécessaire pour faire intervenir un huissier ou un avocat au cas où votre client ne paye pas.</p><br/>
                    <p>C’est surtout votre ultime moyen de pression pour faire payer votre client. C’est formel et ça marche!</p><br/>
                  `,
          messageKind: {
            Email: {
              subject: 'Facture {{invoiceNumber}} en retard - Merci de régler ASAP',
              title: 'DERNIER RAPPEL AVANT MISE EN DEMEURE',
              itemBody: `
                                <p>Bonjour {{firstName}},</p><br/>
                                <p>Malgré nos relances répétées, nous constatons avec regret que la facture {{invoiceNumber}} demeure impayée à ce jour.</p><br/>
                                <p>Le total des sommes dues à ce jour s'élève à : {{invoiceAmount}}</p>
                                <p>Les modes de paiement pour vos factures sont indiqués en bas de ce courriel.</p>
                                <p>A défaut de règlement sous 15 jours, nous transmettrons votre dossier à un cabinet de recouvrement, qui se chargera de prendre les mesures nécessaires à votre encontre.</p>
                                <p>Un courrier de mise en demeure vous a également été adressé par voie postale.</p>
                                <p>Selon les termes de notre contrat commercial, nous vous rappelons que nous sommes fondé à vous demander le paiement d’intérêts.</p><br/>
                                <p>Si toutefois vous aviez procédé à un règlement, merci de ne pas tenir compte de ce courriel.</p>
                                <p>Veuillez agréer, Madame, Monsieur, l'expression de nos salutations distinguées.</p><br/>
                                <p>Cordialement,</p>
                                <p>{{senderName}}</p>
                              `
            },
            PhoneCall: {
              subject: '',
              itemBody: `
                            <p class='text-2xl'>Bonjour, c’est {{senderFirstName}} {{senderLastName}} de {{senderCompanyName}}.</p><br>
                            <p>Notre équipe n'a pas toujours pas reçu le paiement de la facture n°{{invoiceNumber}}.</p>
                            <p>Le total des sommes dues à ce jour s'élève à : {{invoiceAmount}}</p>
                            <p>Cette facture est impayée depuis {{dueDays}} jours et devient problématique pour notre entreprise. Il est maintenant urgent que vous nous fassiez savoir dès que possible quand nous pouvons espérer recevoir le paiement.</p><br>
                            <p>A défaut de règlement sous 15 jours, nous transmettrons votre dossier à un cabinet de recouvrement, qui se chargera de prendre les mesures nécessaires à votre encontre.</p>
                            <p>Un courrier de mise en demeure vous a également été adressé par voie postale.</p>
                            <p>Je compte sur vous pour faire le nécessaire.</p>
                          `
            },
            EmailAndRegularPostMail: {
              subject: 'Objet: Lettre de relance pour la facture n°{{invoiceNumber}}',
              intendedFor: 'A l\'attention de',
              letterOnTime: ', le ',
              itemBody: `
                                <div class='w-full flex-col'>
                                    <p>Madame, Monsieur,</p><br>
                                    <p>Sauf erreur ou omission de notre part, nous restons en attente du règlement de la facture n°{{invoiceNumber}} du {{issuedDate}}, échue le {{dueDate}}, d’un montant de {{invoiceAmount}}&nbsp;TTC.</p><br>
                                    <p>Aussi, nous vous demandons de bien vouloir procéder à son paiement dans les meilleurs délais. Vous trouverez, jointe à ce courrier, une copie de la facture concernée.</p><br>
                                    <p>A défaut de règlement sous 15 jours, nous transmettrons votre dossier à un cabinet de recouvrement, qui se chargera de prendre les mesures nécessaires à votre encontre.</p>
                                    <p>Selon les termes de notre contrat commercial, nous vous rappelons que nous sommes fondé à vous demander le paiement d’intérêts.</p><br/>
                                    <p>Dans le cas où votre règlement nous parviendrait avant la réception de ce courrier, veuillez ne pas tenir compte de celui-ci.</p><br>
                                    <p>Nous restons à votre disposition pour tout renseignement complémentaire.</p><br>
                                    <p>Nous vous prions d’agréer, Madame, Monsieur, l’expression de nos salutations distinguées.</p><br>
                                    <p>{{senderFirstName}} {{senderLastName}}</p>
                                    <p>{{senderRole}}</p>
                                </div>`
            }
          }
        },
        CollectionLitigation: {
          explainerIconName: 'icon-bulb',
          explainerTitle: 'Que faire en cas de non-paiement ?',
          explainerText: `
                    <br/>
                    <p>Si vous avez épuisé tous les recours amiables pour récupérer votre créance auprès de votre client, des actions plus radicales s’imposent.</p><br/>
                    <p>Le montant de la créance, la situation juridique et financière du débiteur, les contestations soulevées sont autant d’éléments à prendre en compte avant d’engager la procédure judiciaire la plus efficace.</p><br/>
                  `,
          subject: '',
          itemBody: '',
          messageKind: {
            LawyerAndOtherLegalServices: {
              subject: '',
              itemBody: `
                                    <p>Pour passer en contentieux, plusieurs solutions s’offrent à vous.</p><br/>
                                    <p class='underline'>Recouvrement</p><br/>
                                    <p>Le plus simple est souvent de faire appel à des spécialistes qui vont s'occuper du recouvrement. Il existe des agences et des plateformes de recouvrement en ligne qui se rémunèrent au succès.</p><br/>
                                    <ul>
                                        <li> &nbsp;&nbsp;&bull;&nbsp; Gcollect - https://www.gcollect.fr/</li>
                                        <li> &nbsp;&nbsp;&bull;&nbsp; Recouvrer. fr - https://www.recouvrer.fr/</li>
                                    </ul><br/>
                                    <p class='underline'>Injonction de payer</p><br/>
                                    <p>Vous devez saisir le tribunal de commerce (si vous êtes commerçant) ou le tribunal judiciaire afin d’obtenir un titre exécutoire. C’est une procédure simple et économique.  Il vous faudra rédiger une requête à adresser au juge. Nous vous expliquons comment procéder sur cet article.</p><br/>
                                    <ul>
                                        <li> &nbsp;&nbsp;&bull;&nbsp; https://www.service-public.fr/particuliers/vosdroits/F1746</li>
                                    </ul><br/>
                                    <p class='underline'>Avocat</p><br/>
                                    <p>Une lettre de mise en demeure aura un effet d’autant plus efficace si elle est envoyée par votre avocat. Cela lui donnera un caractère plus officiel et impressionnera d’autant plus le débiteur.</p><br/>
                                    <ul>
                                        <li> &nbsp;&nbsp;&bull;&nbsp; https://callalawyer.fr/</li>
                                    </ul>`
            }
          }
        },
        DisputeResolution: {
          explainerIconName: 'icon-bulb',
          explainerTitle: 'Pourquoi faire cette relance ?',
          explainerText: `
                    <br/>
                    <p>En cas de litige commercial, la première étape consiste à téléphoner à votre client.</p><br/>
                    <ul>
                        <li> &nbsp;&nbsp;&bull;&nbsp; Examinez ce qui a été convenu et à quel point l'accord était clair.</li>
                        <li> &nbsp;&nbsp;&bull;&nbsp; Rassembler les preuves (par exemple : approbation de commande, bon de livraison, contrats écrits, correspondance et déclarations de témoins).</li>
                        <li> &nbsp;&nbsp;&bull;&nbsp; Essayez de négocier une résolution à l'amiable. Conservez des preuves des négociations, y compris des copies de lettres et de notes sur les conversations.</li>
                        <li> &nbsp;&nbsp;&bull;&nbsp; Suivez tous les processus alternatifs de règlement des différends décrits dans votre contrat avant d'engager un recours juridique.</li>
                    </ul>
                  `,
          subject: '',
          itemBody: `
                     TODO
                  `
        }
      },
      email: {
        content: {
          siren: 'SIREN : {{senderSiren}}'
        },
        footer: {
          title: 'Soyez payé plus facilement et plus rapidement.',
          content: 'Vous avez reçu cet e-mail parce que votre fournisseur {{senderName}} utilise Dundy pour suivre et assurer le suivi de ses factures gratuitement. • <a href="https://www.dundy.co/" style="text-decoration:underline;">Qu\'est ce que Dundy ?</a>'
        },
        toast: {
          emailGenerationFail: 'Erreur lors de la génération du mail'
        }
      }
    },
    cashApplication: {
      modal: {
        title: 'Rapprochement sur cette transaction',
        subtitle: 'Associez une ou plusieurs factures à cette transaction. Cette action arrête automatiquement la relance des factures sélectionnées.',
        controls: {
          cancel: 'Annuler',
          save: 'Enregistrer',
          savingInProgress: 'Enregistrement en cours...'
        }
      },
      feedbacks: {
        successfullySavedCashApplication: 'Rapprochement enregistré avec succès'
      },
      missingBankAccount: {
        notOwner: 'Veuillez contacter l\'administrateur du compte afin de connecter le compte bancaire.'
      },
      cashApplicationDeclaredRow: {
        removeLink: 'Retirer le lien'
      },
      cashApplicationSidebarTitle: 'Transaction',
      cashApplicationTitle: 'Rapprochement',
      appliedInvoices: {
        title: 'Facture(s) rapprochée(s)',
        tooltip: 'Ajouter des factures'
      },
      allocatedAmount: 'Montant alloué :',
      currencySymbols: {
        EUR: 'EUR'
      },
      leftToAllocate: 'Restant à allouer :',
      controls: {
        edit: 'Modifier'
      },
      status: {
        toCashApply: 'A rapprocher',
        partiallyCashApply: 'A compléter',
        cashApplied: 'Rapprochée'
      }
    },
    payments: {
      heading: {
        one_one: 'Validez {{count}} nouveau paiement',
        one_other: 'Validez {{count}} nouveaux paiements'
      },
      empty: {
        title: 'Aucune nouvelle transaction',
        first_paragraph: 'Vous n\'avez pas de nouvelles transactions à valider.',
        second_paragraph: 'Nous vous préviendrons lorsque ce sera le cas.'
      },
      table: {
        transactionHeading: 'Transactions',
        transactionSub: 'Les transactions ci-dessous ont été liées à des factures ouvertes',
        invoicesHeading: 'Factures ouvertes',
        invoicesSub: 'Ci-dessous la liste des factures ouvertes que nous avons rapprochées des encaissements',
        time: {
          due_one: 'due dans {{count}} jour',
          due_other: 'due dans {{count}} jours',
          overdue_one: 'en retard de {{count}} jour',
          overdue_other: 'en retard de {{count}} jours'
        }
      },
      buttons: {
        confirm: 'Confirmer le paiement',
        cancel: 'Annuler le lien'
      }
    },
    paymentPortal: {
      paymentCompleted: {
        title: 'Paiement réussi !',
        copy: 'Un paiement de',
        copytwo: 'a été effectué à',
        emailText: 'Un e-mail détaillant la transaction a également été envoyé dans votre boîte de réception.'
      },
      selectBank: {
        bank: 'Tapez le nom de votre banque',
        error: 'La sélection a échoué.',
        title: 'Choisissez votre compte bancaire',
        copy: 'Commencez par taper le nom de la banque',
        copytwo: 'vous souhaitez utiliser pour votre paiement',
        continue: 'PROCÉDER AVEC CETTE BANQUE'
      },
      viewPayment: {
        copy: 'vous a envoyé ce qui suit',
        copytwo: 'demande de paiement.',
        invoiceLabel: 'Facture',
        dueLabel: 'Payable',
        instantTransfer: 'Virement bancaire instantané',
        instantTransferCopy: 'Initiez un virement bancaire en quelques secondes.',
        note: 'Vos informations de paiement resteront sécurisées et',
        notetwo: 'caché de votre client et de tout autre tiers.',
        continue: 'PAYER'
      },
      reviewPayment: {
        title: 'Vérifiez votre paiement',
        titletwo: 'à',
        editLink: 'ÉDITER',
        totalLabel: 'Total',
        totalNote: '**Transaction gratuite. Aucun frais ne sera appliqué.',
        invoiceLabel: 'Facture',
        dueLabel: 'Payable',
        paymentDateLabel: 'Date de paiement',
        note: 'Paiement Sécurisé par',
        continue: 'CONFIRMER LE PAIEMENT'
      }
    },
    exports: {
      pageTitle: 'Exports',
      fec: 'Fichier FEC',
      zip: 'Fichier ZIP',
      errorDate: 'La date de départ doit être supérieur à la date de fin',
      errorCheck: 'Au moins une case doit être cochée',
      toastEmpty: 'Aucun élément disponible pour ces paramètres'
    },
    template: {
      countDescription_one: '{{count}} modèles',
      countDescription_more: '{{count}} modèles',
      languages: 'Langues',
      fallbackLanguage: 'Langue de secours',
      defaultLanguage: 'Langue par défaut',
      title: 'Modèles de mail',
      search: 'Recherche d\'un modèle par nom',
      add: 'Ajouter un nouveau modèle',
      edit: 'Editer ce modèle',
      duplicate: 'Dupliquer ce modèle',
      delete: 'Supprimer ce modèle',
      name: 'Nom',
      actions: 'Actions',
      deleteHeading: 'Confirmer la suppression ?',
      deletePrimaryButton: 'Supprimer',
      deleteSecondaryButton: 'Annuler',
      deleteInfo: 'Souhaitez-vous vraiment supprimer ce modèle ? Toutes les données seront définitivement supprimées de nos serveurs. Cette action ne peut pas être annulée.',
      duplicateHeading: 'Dupliquer le modèle ?',
      duplicatePrimaryButton: 'Dupliquer',
      duplicateSecondaryButton: 'Annuler',
      duplicateInfo: 'Pour dupliquer le modèle, saisir un nom :',
      duplicateError: 'Un modèle existe déjà avec ce nom',
      duplicatePlaceholder: 'Nom du modèle',
      createFeedback: 'Le modèle a bien été créé',
      updateFeedback: 'Le modèle a bien été mis à jour',
      duplicateFeedback: 'Le modèle a bien été dupliqué',
      deleteFeedback: 'Le modèle a bien été supprimé',
      missPropertiesFeedback: 'Des champs sont vides',
      existingTemplateFeedback: 'Un modèle existe déjà avec ce nom',
      mediaKind: 'Type de relance',
      manual: 'Manuel',
      mail: 'Email',
      modal: {
        title: 'Editer le modèle',
        name: 'Nom',
        from: 'De',
        to: 'A',
        cc: 'CC',
        cci: 'CCI',
        attentionOf: 'A l\'attention de',
        mailTo: 'Copie par mail',
        escalation: 'Escalade',
        subject: 'Sujet',
        phone: 'Téléphone',
        save: 'Enregistrer le modèle',
        saved: 'Modèle enregistré avec succès',
        cancel: 'Annuler',
        edit: 'Edition',
        preview: 'Aperçu',
        editSystemDefault: 'Editer le modèle par défaut',
        breadcrumbWorkspace: 'Modèle général',
        breadcrumbCustomer: 'Modèle client - {{name}}'
      },
      variable: {
        show: 'Afficher les variables',
        hide: 'Masquer les variables',
        title: 'Variables',
        filter: 'Chercher une variable',
        keywords: {
          myEmailSignature: 'Ma signature',
          bankTransferBlock: 'Bloc virement bancaire',
          paymentLinkBlock: 'Bloc lien de paiement',
          invoiceNumber: 'Numéro de facture',
          invoiceAmount: 'Montant',
          invoiceRemainingAmount: 'Montant restant',
          invoiceDueDate: 'Date d\'échéance',
          invoiceIssueDate: 'Date d\'émission',
          firstCustomerContact: 'Premier contact client',
          firstCustomerFirstName: 'Prénom du contact client',
          firstCustomerLastName: 'Nom du contact client',
          firstCustomerFullName: 'Nom complet du contact client',
          customerCompanyName: 'Nom du client',
          customerCompanyAddress: 'Adresse du client',
          myFirstName: 'Mon prénom',
          myLastName: 'Mon nom',
          myFullName: 'Mon nom complet',
          myMobileNumber: 'Mon téléphone portable',
          myOfficeNumber: 'Mon téléphone de bureau',
          myPhoneNumbers: 'Mes numéros de téléphone',
          myEmailAddress: 'Mon mail',
          myCompanyName: 'Nom de ma société',
          myCompanyAddress: 'Adresse de ma société'
        }
      },
      errors: {
        missingMail: 'Mail manquant',
        onlyOneMail: 'Un seul mail est autorisé',
        emptyField: 'Champs vide',
        badValues: 'Non accepté : {{error}}'
      }
    },
    bankWall: {
      title: 'Gagnez en efficacité !',
      baseline: 'Débloquez plus de fonctionnalités immédiatement, en connectant gratuitement votre compte bancaire.',
      payTitle: 'Soyez payé 3x plus vite',
      payBaseline: 'Le lien de paiement facilite la vie de vos clients. Et la vôtre.',
      reconcilingTitle: 'Rapprochez vos paiements',
      reconcilingBaseline: 'Consultez tous vos paiements client et reliez-les en un clic.',
      realtimeTitle: 'Visualisez votre situation',
      realtimeBaseline: 'Profitez en temps réel de la position de vos comptes débiteurs.',
      button: 'Je connecte mon compte en banque gratuitement',
      deprecated: 'L\'autorisation de connexion à votre compte bancaire n\'est plus valide. Merci de le reconnecter pour profiter à nouveau pleinement des fonctionnalités',
      security: 'Nous utilisons vos données bancaires exclusivement pour les fonctionnalités décrites ci-dessus.<br>Elles ne sont jamais commercialisées ni communiquées à des tiers. En apprendre plus sur <a href="https://www.dundy.co/manifeste" target="_blank">nos engagements relatifs à vos données bancaires.</a>'
    },    
    grid: {
      edit: 'Editer',
      duplicate: 'Dupliquer',
      delete: 'Supprimer',

      empty: 'Pour commencer cliquer sur le bouton "Ajouter"',
      deleteHeading: 'Confirmer la suppression ?',
      deleteContent: 'Toutes les données seront définitivement supprimées de nos serveurs. Cette action ne peut pas être annulée.',
      duplicateHeading: 'Confirmer la copie ?',
      duplicateContent: 'Pour dupliquer, saisir un autre nom :',
      duplicatePlaceholder: 'Nom de la copie',
      duplicateError: 'Nom déjà existant'
    },
    workflow: {
      title: 'Plan de relance',
      subtitle: 'Gérez les étapes et le contenu de votre relance.',
      issueDate: 'Date d\'émission',
      issueDatePlus7: 'Date d\'émission + 7 jours',
      dueDateLess6: 'Date d\'échéance - 6 jours',
      dueDate: 'Date d\'échéance',
      dueDatePlus2: 'Date d\'échéance + 2 jours',
      dueDatePlus6: 'Date d\'échéance + 6 jours',
      dueDatePlus15: 'Date d\'échéance + 15 jours',
      dueDatePlus20: 'Date d\'échéance + 20 jours',
      dueDatePlus35: 'Date d\'échéance + 35 jours',
      defaultTemplate: 'Modèle par défaut',
      customTemplate: 'Modèle personnalisé',
      manual: 'Manuel',
      auto: 'Automatique',
      editItem: 'Edition de relance',
      step: 'Etape ',
      advancedMode: 'Mode avancé'
    },
    actions: {
      add: 'Ajouter',
      cancel: 'Annuler',
      confirm: 'Confirmer',
      create: 'Créer',
      delete: 'Supprimer',
      disable: 'Désactiver',
      duplicate: 'Dupliquer',
      edit: 'Editer',
      enable: 'Activer',
      new: 'Nouveau',
      off: 'Off',
      on: 'On',
      preview: 'Prévisualiser',
      save: 'Sauvegarder',
      update: 'Mettre à jour',
      validate: 'Valider'
    }
  }
}
