<script lang="ts">
  import { feedbackService } from '$src/core-app/services/feedback.service'
  import { generateMockedPDFBlob } from '../services/pdf-make/business-document-pdf-generator'
  import { getVoxyInvoicePreferences, saveVoxyInvoicePreferences } from '../services/business-document-api/business-document-repository.api'
  import { onMount } from 'svelte'
  import { remoteDebuggingService, Severity } from '$src/core-app/services/remote-debugging.service'
  import { t } from '$src/core-app/lib/i18n/i18nextWrapper'
  import { ToastType } from '$src/core-app/enums/feedback-enums'
  import Checkbox from '$src/core-app/lib/ui-kit/Checkbox.svelte'
  import InputUploader from '$src/shared/components/input/InputUploader.svelte'
  import type { Feedback } from '$src/core-app/models/feedback'
  import ViewerPDF from '$src/shared/components/viewer/ViewerPDF.svelte'

  export let uploadMaxSize: number = 2
  export let uploadExtensions: string[] = ['jpg', 'jpeg', 'bmp', 'png', 'svg']
  export let viewerZoom: boolean = false

  let isSaving: boolean = false
  let file: any = {}
  let uploader: InputUploader
  let uploadPercent: number = 0
  let pdfBlob: Blob | null = null
  let pdfLoading: boolean = true

  let imageBase64: string = ''
  let insertCompanyLogo: boolean = true
  let imageTransparency: number = 40
  let headerMargin: number = 0
  let headerTextColor: string = '#1F2533'
  let footerMargin: number = 0
  let footerTextColor: string = '#1F2533'

  const toast = (msg: string, type: ToastType) =>
    feedbackService.displayFeedback({
      feedbackMessage: msg,
      feedbackLevel: type
    } as Feedback)

  const onFileDialog = (e: Event) => {
    uploader.openFileDialog(e)
  }

  const onPDFRendering = async (e: any) => {
    if (e.base64) {
      file = e
      imageBase64 = e.base64
    } else if (!imageBase64) {
      file = e
    }

    file = {
      ...file,
      transparency: imageTransparency,
      insertCompanyLogo,
      headerMargin,
      headerTextColor,
      footerMargin,
      footerTextColor
    }

    pdfLoading = true
    pdfBlob = await generateMockedPDFBlob(
      imageBase64,
      imageTransparency,
      insertCompanyLogo,
      headerMargin,
      headerTextColor,
      footerMargin,
      footerTextColor,
    )
    pdfLoading = false
  }

  const onRemoveImage = () => {
    file.fileName = ''
    file.fileExtension = ''
    file.fileSize = 0
    file.fileMimeType = ''
    file.imageWidth = 0
    file.imageHeight = 0
    file.base64 = ''
    imageBase64 = ''
    pdfBlob = null
  }

  const onSaveInvoiceBackgroundPreference = async () => {
    isSaving = true

    uploadPercent = 0

    try {
      await saveVoxyInvoicePreferences(file, (percentCompleted: number) => (uploadPercent = percentCompleted))
    } catch (err) {
      remoteDebuggingService.addInfo(err, '', Severity.Error)
    }

    isSaving = false
  }

  onMount(() => {
    getVoxyInvoicePreferences().then(async (value) => {
      if (value.err[0]) {
        toast(t('invoicingAppEdit.invoiceBackground.errorLoadPreference'), ToastType.Error)
        remoteDebuggingService.addInfo(value.err[0], '', Severity.Error)
      } else {
        file.fileName = value.res.fileName
        file.fileMimeType = value.res.fileMimeType
        file.base64 = value?.res?.image
        imageBase64 = value?.res?.image
        imageTransparency = value?.res?.opacity ?? 40
        insertCompanyLogo = value?.res?.insertCompanyLogo ?? false
        headerMargin = value?.res?.headerMargin ?? 0
        headerTextColor = value?.res?.headerTextColor ?? '#1F2533'
        footerMargin = value?.res?.footerMargin ?? 0
        footerTextColor = value?.res?.footerTextColor ?? '#1F2533'

        pdfLoading = true
        pdfBlob = await generateMockedPDFBlob(
          imageBase64,
          imageTransparency,
          insertCompanyLogo,
          headerMargin,
          headerTextColor,
          footerMargin,
          footerTextColor,
        )
        pdfLoading = false
      }
    })
  })
</script>

<div class="relative p-2" class:disabled={isSaving}>
  <div class="flex relative">
    <div class="shrink-0">
      <InputUploader
        {imageTransparency}
        width={345}
        height={500}
        hidden={true}
        maxSizeMo={uploadMaxSize}
        allowedExtensions={uploadExtensions}
        bind:imageBase64
        bind:this={uploader}
        on:loaded={(e) => onPDFRendering(e.detail)}
      />
      <ViewerPDF
        width={345}
        height={500}
        hidden={false}
        isLoading={pdfLoading}
        bind:blob={pdfBlob}
        bind:zoom={viewerZoom}
      />
    </div>

    <div class="px-4 text-sm">
      <div class="relative flex justify-around items-center border pt-4 pb-1 px-2 border-gray-300 rounded">
        <h3 class="absolute -top-3 left-2 font-bold bg-whisper">
          {t('invoicingAppEdit.invoiceBackground.background')}
        </h3>
        <div>
          {@html t('invoicingAppEdit.invoiceBackground.text', {
            size: uploadMaxSize,
            ext: uploadExtensions.join(', ')
          })}

          <label class="mt-6 block" for="sliderImageTransparency"
            >{t('invoicingAppEdit.invoiceBackground.opacity', { opacity: imageTransparency })}</label
          >
          <input
            type="range"
            id="sliderImageTransparency"
            min="0"
            max="100"
            bind:value={imageTransparency}
            on:change={onPDFRendering}
          />
        </div>
      </div>

      <div class="mt-6 relative flex justify-around items-center border pt-4 pb-1 px-2 border-gray-300 rounded">
        <h3 class="absolute -top-3 left-2 font-bold bg-whisper">
          {t('invoicingAppEdit.invoiceBackground.margin')}
        </h3>
        <div>
          <label class="block" for="sliderHeaderMargin"
            >{t('invoicingAppEdit.invoiceBackground.marginHeader', { margin: headerMargin })}</label
          >
          <input
            type="range"
            id="sliderHeaderMargin"
            min="0"
            max="100"
            bind:value={headerMargin}
            on:change={onPDFRendering}
          />
        </div>
        <div>
          <label class="block" for="sliderFooterMargin"
            >{t('invoicingAppEdit.invoiceBackground.marginFooter', { margin: footerMargin })}</label
          >
          <input
            type="range"
            id="sliderFooterMargin"
            min="0"
            max="100"
            bind:value={footerMargin}
            on:change={onPDFRendering}
          />
        </div>
      </div>

      <div class="mt-6 relative flex justify-around items-center border pt-4 pb-1 px-2 border-gray-300 rounded">
        <h3 class="absolute -top-3 left-2 font-bold bg-whisper">
          {t('invoicingAppEdit.invoiceBackground.colors')}
        </h3>
        <div>
          <label class="block" for="sliderHeaderTextColor"
            >{t('invoicingAppEdit.invoiceBackground.colorHeader', { color: headerTextColor })}</label
          >
          <input
            type="color"
            id="sliderHeaderTextColor"
            min="0"
            max="100"
            style="width:100%"
            bind:value={headerTextColor}
            on:change={onPDFRendering}
          />
        </div>
        <div>
          <label class="block" for="sliderFooterTextColor"
            >{t('invoicingAppEdit.invoiceBackground.colorFooter', { color: footerTextColor })}</label
          >
          <input
            type="color"
            id="sliderFooterTextColor"
            min="0"
            max="100"
            style="width:100%"
            bind:value={footerTextColor}
            on:change={onPDFRendering}
          />
        </div>
      </div>

      <div class="mt-6 relative flex justify-around items-center border pt-3 pb-2 px-2 border-gray-300 rounded">
        <h3 class="absolute -top-3 left-2 font-bold bg-whisper">
          {t('invoicingAppEdit.invoiceBackground.various')}
        </h3>
        <Checkbox
          bind:checked={insertCompanyLogo}
          labelText={t('invoicingAppEdit.invoiceBackground.logo')}
          on:check={onPDFRendering}
        />
      </div>
    </div>
  </div>

  <div class="relative mt-4 px-20 pt-8 pb-1 flex justify-between border-t">
    <button class="btn action-primary" on:click={onFileDialog}>
      {t('invoicingAppEdit.invoiceBackground.buttonChoose')}
    </button>
    <button class="btn action-bla primary" on:click={onSaveInvoiceBackgroundPreference}>
      {t('invoicingAppEdit.invoiceBackground.buttonSave')}
    </button>
  </div>
  <div class="text-center">
    {#if imageBase64}
      <button
        class="mt-6 text-sm text-red-500 hover:border-b border-red-500 px-4 cursor-pointer"
        on:click={onRemoveImage}
      >
        {t('invoicingAppEdit.invoiceBackground.buttonDelete')}
      </button>
    {/if}
  </div>
</div>

{#if isSaving}
  <div class="relative mt-3 mb-2 rounded h-6 bg-gray-200">
    <div class="bg-dundyOrange rounded h-full text-center" style="width: {uploadPercent}%" />
    <div class="absolute top-0 w-full text-center">{uploadPercent}%</div>
  </div>
{/if}

<style lang="postcss">
  .disabled {
    transition: all 0.3s ease;
    @apply pointer-events-none select-none;

    &::before {
      content: "";
      @apply absolute top-0 left-0 right-0 bottom-0 rounded bg-gray-200 opacity-60;
    }
  }
</style>
