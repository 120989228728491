

/**
 * Function to detect clicks outside of a node
 * @param node
 */
export function clickOutside(node: HTMLElement): { destroy(): void } {
  const handleClick = (event: MouseEvent) => {
    if (!node || node.contains(event.target as Node) || event.defaultPrevented || event.button !== 0) {
      return
    }
    node.dispatchEvent(new CustomEvent('click_outside', { detail: node }))
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      node.dispatchEvent(new CustomEvent('click_outside', { detail: node }))
    }
  }

  document.addEventListener('click', handleClick, true)
  document.addEventListener('keydown', handleKeyDown, true)

  return {
    destroy() {
      document.removeEventListener('click', handleClick, true)
      document.removeEventListener('keydown', handleKeyDown, true)
    }
  }
}
