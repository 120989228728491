<script lang="ts">
  import { navigate } from 'svelte-routing'
  import { t } from '../../../../core-app/lib/i18n/i18nextWrapper.js'
  import BusinessDocumentContactSelect from './BusinessDocumentContactSelect.svelte'
  import { BusinessDocument } from '../../../models/business-document'
  import { ContactsStore } from '../../../../crm-app/stores/contacts.store'
  import { CustomersStore } from '../../../../crm-app/stores/customers.store.js'
  import { eventsManager } from '../../../../core-app/events/event-manager'
  import type { Customer } from '../../../../crm-app/models/customer'
  import { EventType } from '../../../../core-app/events/event-type'
  import Select from 'svelte-select'
  import { createEventDispatcher, onDestroy, onMount } from 'svelte'

  /** Exported Var **/
  export let businessDocument: BusinessDocument

  const dispatch = createEventDispatcher()

  /** Let/Var Declarations **/
  let customerDone: boolean
  let customerInitial: string
  let unsubscribeToCustomerChangedEvent = null
  let unsubscribeToContactChangedEvent = null
  /** Conf for bare Select */
  let indicator: string = '<i class=\'icon-chevron-down text-2xl text-black\'/>'
  let showIndicator: boolean = true

  /** Reactive Declarations **/
  $: customer = $CustomersStore.find(
    (c: Customer) => c?.company?.companyId === businessDocument?.customerCustomer?.company?.companyId,
  )
  $: customerDone =
    !!businessDocument?.customerCustomer.company && !!businessDocument?.customerCustomer.company.formalName
  $: customerInitial = customerDone ? businessDocument?.customerCustomer.company.formalName.substring(0, 1) : null
  $: customerContacts = customerDone
    ? $ContactsStore.filter((c) => c.companyId === businessDocument.customerCustomer.company.companyId)
    : null

  function unlinkCustomer() {
    businessDocument.customerCustomer.company = null
    businessDocument.customerContact = null
    customerDone = false
  }

  const onCustomerChange = () => {
    businessDocument.customerContact = null
    dispatch('customerChange', businessDocument.customerCustomer)
    dispatch('contactChange', businessDocument.customerContact)
  }

  const onContactChange = () => {
    dispatch('contactChange', businessDocument.customerContact)
  }

  onMount(() => {
    unsubscribeToCustomerChangedEvent = eventsManager.on<Customer>(
      EventType.CUSTOMER_CHANGED,
      (e) => {
        const changedCustomer = e.data
        if (changedCustomer.company.companyId === businessDocument?.customerCustomer.company?.companyId) {
          businessDocument.customerCustomer.company = changedCustomer.company
        }
      },
      'BusinessDocumentFormCustomerAndContact.svelte',
    )

    unsubscribeToContactChangedEvent = eventsManager.on<Customer>(
      EventType.CONTACT_CHANGED,
      (e) => {
        const changedContact = e.data
        if (changedContact.company?.companyId === businessDocument?.customerCustomer.company?.companyId) {
          businessDocument.customerCustomer.company = changedContact.company
        }
      },
      'BusinessDocumentFormCustomerAndContact.svelte',
    )

    businessDocument.customerCustomer.company = customer?.company
    businessDocument.customerContact = $ContactsStore.find(
      (c) => c.contactId === businessDocument?.customerContact?.contactId,
    )
  })

  onDestroy(() => {
    unsubscribeToCustomerChangedEvent()
    unsubscribeToContactChangedEvent()
  })
</script>

<h2 class="block text-2xl font-bold text-gray-900 mb-3">{t('invoices.createInvoice.clientLabel')}</h2>

{#if customerDone}
  <div class="flex justify-between items-center">
    <div class="flex flex-row justify-start items-center align-middle pt-1">
      <div class="h-10 w-10 py-0.2 bg-black flex justify-center items-center rounded-md uppercase text-center text-sm">
        <span class="text-white">{customerInitial}</span>
      </div>
      <div class="flex flex-col ml-3 w-64">
        <span class="text-lg font-medium text-gray-900">{businessDocument.customerCustomer.company.formalName}</span>
        <span
          class="cursor-pointer text-xxs text-dundyOrange"
          on:click={() => {
            navigate('/clients/' + businessDocument.customerCustomer.company.companyId + '/edit')
          }}
        >
          {t('invoices.createInvoice.editClientFile')}
        </span>
      </div>
    </div>
    {#if !businessDocument.relatedBusinessDocuments.length}
      <button
        type="button"
        on:click={() => {
          unlinkCustomer()
        }}
        class="inline-flex h-8 items-center rounded border border-gray-300 bg-white px-2.5 py-1 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-dundyOrange focus:ring-offset-2"
      >
        {t('invoices.createInvoice.changeClient')}
      </button>
    {/if}
  </div>
  {#if !!customerContacts || customerContacts.length > 0 || businessDocument.customerContact}
    <div class="flex items-center my-4">
      <div class="w-full">
        <BusinessDocumentContactSelect bind:businessDocument {customerContacts} on:change={() => onContactChange()} />
      </div>
    </div>
  {:else}
    <div class="mt-2">
      <button
        class="mt-8 flex w-full items-center justify-center rounded-md border border-transparent bg-black py-3 px-8 text-base font-medium text-white hover:bg-dundyOrange focus:outline-none focus:ring-2 focus:ring-dundyOrange focus:ring-offset-2"
        on:click={(e) => {
          e.stopPropagation()
          navigate(`/clients/${businessDocument.customerCustomer.company.companyId}/edit/contacts`)
        }}>{t('invoices.createInvoice.addContact')}</button
      >
    </div>
  {/if}
{:else if !$CustomersStore.length}
  <p class="text-gray-800 text-xs mb-4">{t('invoices.createInvoice.noClientYet')}</p>
  <button class="btn action-default" on:click={() => navigate('/clients/new')}>
    {t('invoices.createInvoice.createClient')}
  </button>
{:else}
  <div class="mt-1.5 small-select h-14">
    <Select
      ChevronIcon={indicator}
      clearable={false}
      searchable={true}
      items={$CustomersStore.map((c) => c.company)}
      showChevron={showIndicator}
      itemId="companyId"
      label="formalName"
      on:change={() => onCustomerChange()}
      placeholder={t('invoices.createInvoice.chooseClientPlaceholder')}
      bind:value={businessDocument.customerCustomer.company}
    />
  </div>

  <button
    class="inline-flex items-center rounded-md border border-transparent bg-black px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
    on:click={() => navigate('/clients/new')}
  >
    {t('invoices.createInvoice.createClient')}
  </button>
{/if}
