import { remoteDebuggingService, Severity } from '$src/core-app/services/remote-debugging.service'
import { dateDiffInMilliseconds } from '$src/shared/utils/date'
import { objectDeepClone } from '$src/shared/utils/object'
import { get } from 'svelte/store'
import { featureToggleStore } from './feature-toggle.store'
import localFeatures from './features-toggle-local.json'

type LocalFeature = {
  name: string,
  desc: string,
  implemented: boolean
}

export enum FeatureDisplayMode {
  HIDE = 'HIDE',
  COMPONENT = 'COMPONENT',
  WALL = 'WALL'
}

export type Feature = {
  id: string,
  name: string,
  desc: string,
  implemented: boolean,
  displayMode: FeatureDisplayMode,
  componentMessage: string
}

export type Features = { [key: string]: Feature }

export type FeatureToggle = {
  dateToken: string;
} & Features

const isLocal = process.env.APP_ENV === 'local'

let featureToggleAutoUpdate: any

/**
 * EXTRACT FEATURES TO MANAGE (local AND api)
 * @param {Features} APIFeatures 
 * @returns {Features}
 */
const extractFeatures = (APIFeatures: Features): Features => {
  const features: Features = {}

  localFeatures.forEach((feature: LocalFeature) => {
    const name = feature.name

    if (!features[name]) {
      if (APIFeatures[name]) {
        features[name] = {
          ...objectDeepClone(APIFeatures[name]),
          desc: feature.desc,
          implemented: feature.implemented
        }
      } else if (isLocal) {
        features[name] = {
          id: '',
          desc: feature.desc,
          implemented: feature.implemented,
          displayMode: FeatureDisplayMode.COMPONENT,
          componentMessage: 'only in dev env'
        }
      }
    }
  })

  return features
}

/**
 * GET LIST OF FEATURES
 * @returns Promise<void>
 */
export const getFeatures = async (): Promise<void> => {
  try {
    // TODO : call api and remove mock
    // const workspaceId = get(WorkspaceStore).workspaceId

    // let obj = { workspaceId } as any
    // if (customerId) obj = { ...obj, customerId }

    // const res = await getPreferenceByKind(workspaceId, 'DunningWorkflow', [obj])
    const res = {
      features: {
        cspdf: {
          id: '',
          name: '',
          displayMode: FeatureDisplayMode.COMPONENT,
          componentMessage: ''
        },
        cstpl: {
          id: '',
          name: '',
          displayMode: isLocal ? FeatureDisplayMode.COMPONENT : FeatureDisplayMode.HIDE,
          componentMessage: isLocal ? 'only in dev env' : ''
        },
        dunv0: {
          id: '',
          name: '',
          displayMode: FeatureDisplayMode.COMPONENT,
          componentMessage: ''
        }
      },
      dateToken: null // new Date(new Date().getTime() + 5 * 60000) // maintenant + 5 min pour test
    }

    let features: Features = {}

    // UPDATE STORE
    features = extractFeatures(res?.features ?? {})
    featureToggleStore.set(features)

    // SET AUTO UPDATE
    if (!res?.dateToken) return
    const nextUpdate = dateDiffInMilliseconds(new Date(), new Date(res.dateToken))

    if (!nextUpdate) return
    if (featureToggleAutoUpdate) clearTimeout(featureToggleAutoUpdate)
    featureToggleAutoUpdate = setTimeout(async () => getFeatures(), nextUpdate)
  } catch (err) {
    remoteDebuggingService.addInfo(err, '', Severity.Error)
  }
}

/**
 * IS THE FEATURE ENABLED
 * @param {string} featureKey 
 * @param {(() => boolean) | null} expirationRule 
 * @returns {boolean}
 */
export const isFeatureEnabled = (featureKey: string, expirationRule: (() => boolean) | null = null): boolean => {
  if (!featureKey) {
    remoteDebuggingService.addInfo(`Feature ${featureKey} not found in isFeatureEnable()`, '', Severity.Error)

    return false
  }

  let isExpired: boolean = false
  if (expirationRule) isExpired = expirationRule()
  if (isExpired) return false
  const store = get(featureToggleStore)
  if (!store) return false

  const feature = store[featureKey]
  if (!feature) return false

  return feature?.displayMode === FeatureDisplayMode.COMPONENT
}